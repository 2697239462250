import React, { Component} from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import indigo from '@material-ui/core/colors/indigo'

import { ConfirmDialog } from 'components/admin/Dialogs';

import { analytics } from 'analytics'
import {
  startEditTaxType,
  startAddTaxType,
  startRemoveTaxType,
} from 'store/actions/taxTypes'
import {
  startAddInvoiceTax,
  startEditInvoiceTax,
  startRemoveInvoiceTax,
} from 'store/actions/invoices'

const styles = theme => ({
  rootContent: {
    backgroundColor: indigo[50],
  },
  card: {
    margin: theme.spacing.unit*2,
    padding: theme.spacing.unit*2,
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteAction: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  rate: {
    width: '75px',
  },
  taxName: {
    width: '110px',
    marginRight: theme.spacing.unit*2,
  },
  ignoreLabel: {
    width: '75px',
  }
})

const validate = (taxType = {}, exclusions = []) => {
  const errors = {};
  const requiredFields = [ 'name', 'rate' ];
  requiredFields.forEach(field => {
    if (!taxType[field] && !exclusions.includes(field)) {
      errors[field] = 'Required';
    }
  });

  const validation = { errors, isInvalid: !!Object.keys(errors).length };
  validation.validateField = function (field) {
      return this.errors[field];
  };

  return validation;
}

class TaxTypeDialog extends Component {
  constructor(props) {
    super(props)
    this.state = { showValidation: false, showDelete: false }
  }

  handleDeleteTaxType() {
    const {
      startRemoveInvoiceTax,
      startRemoveTaxType,
      taxType,
      invoiceId,
      handleClose
    } = this.props

    startRemoveInvoiceTax(taxType.id, invoiceId)
      .then(() => startRemoveTaxType(taxType.taxTypeId))
      .then(() => {
        this.setState({ showDelete: false })
        handleClose()
      })
      .catch(error => {
        console.log("delete error:", error)
        analytics.exception(`TaxTypeDialog::handleDeleteTaxType [message:${error.message}]`)
      })
  }

  renderContent() {
    const { showValidation } = this.state
    const {
      classes,
      taxType,
      handleEdit,
    } = this.props

    const validation = validate(taxType)

    return(
      <Card className={classes.card}>
        <div className={classes.contentRow}>
          <TextField
            error={showValidation && Boolean(validation.validateField("name"))}
            helperText={showValidation && validation.validateField("name")}
            className={classes.taxName}
            label="Tax Name"
            placeholder="Enter tax name"
            margin="normal"
            value={taxType.name}
            onChange={ event => handleEdit("name", event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            error={showValidation && Boolean(validation.validateField("rate"))}
            helperText={showValidation && validation.validateField("rate")}
            className={classes.rate}
            type="number"
            label="Tax Rate"
            placeholder="eg 5%"
            margin="normal"
            value={taxType.rate}
            onChange={ event => handleEdit("rate", event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <div>
            <Switch
              checked={!Boolean(taxType.isApplicable)}
              onChange={event => handleEdit("isApplicable", !Boolean(taxType.isApplicable))}
              value="ignore"
            />
          </div>
          <Typography className={classes.ignoreLabel}>
            Ignore on this invoice
          </Typography>
        </div>
      </Card>
    )
  }

  renderContentMobile() {
    const { showValidation } = this.state
    const {
      classes,
      taxType,
      handleEdit,
    } = this.props

    const validation = validate(taxType)
    return(
      <div>
        <TextField
          error={showValidation && Boolean(validation.validateField("name"))}
          helperText={showValidation && validation.validateField("name")}
          fullWidth
          label="Tax Name"
          placeholder="Enter tax name"
          margin="normal"
          value={taxType.name}
          onChange={ event => handleEdit("name", event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className={classes.contentRow}>
          <TextField
            error={showValidation && Boolean(validation.validateField("rate"))}
            helperText={showValidation && validation.validateField("rate")}
            fullWidth
            type="number"
            label="Tax Rate"
            placeholder="eg 5%"
            margin="normal"
            value={taxType.rate}
            onChange={ event => handleEdit("rate", event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <div>
            <Switch
              checked={!Boolean(taxType.isApplicable)}
              onChange={event => handleEdit("isApplicable", !Boolean(taxType.isApplicable))}
              value="ignore"
            />
          </div>
          <Typography className={classes.ignoreLabel}>
            Ignore on this invoice
          </Typography>
        </div>
      </div>
    )
  }

  render () {
    const { showValidation, showDelete } = this.state
    const {
      classes,
      isMobile,
      taxType,
      invoiceId,
      handleClose,
      handleEdit,
      startEditTaxType,
      startEditInvoiceTax,
      startAddTaxType,
      startAddInvoiceTax,
      startRemoveInvoiceTax,
      startRemoveTaxType,
    } = this.props

    const validation = validate(taxType)

    return (
      <div>
        <ConfirmDialog
          open={showDelete}
          title="Delete Tax Type"
          contentText="This action will delete the tax type. Proceed?"
          handleCancel={() => this.setState({ showDelete: false })}
          handleOk={this.handleDeleteTaxType.bind(this)}
        />
        <Dialog
          maxWidth="md"
          fullScreen={isMobile}
          open={Boolean(taxType)}
          onClose={handleClose}
        >
          <DialogTitle>
            Tax
          </DialogTitle>
          <DialogContent classes={{root: classes.rootContent}}>
            {isMobile ? this.renderContentMobile() : this.renderContent()}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <div className={classes.deleteAction}>
              <div />
              {Boolean(taxType.id) && Boolean(taxType.taxTypeId) &&
                <Button
                  onClick={() => this.setState({ showDelete: true })}
                >
                  Delete
                </Button>
              }
            </div>
            <Button
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (validation.isInvalid) {
                  this.setState({ showValidation: true })
                } else if (Boolean(taxType.id)) {
                  const updates = {
                    name: taxType.name,
                    rate: taxType.rate
                  }
                  const taxUpdates = {
                    ...updates,
                    isApplicable: Boolean(taxType.isApplicable)
                  }

                  startEditTaxType(taxType.taxTypeId, updates)
                    .then(() => startEditInvoiceTax(taxType.id, invoiceId, taxUpdates))
                    .then(() => handleClose())
                } else {
                  const data = {
                    name: taxType.name,
                    rate: taxType.rate
                  }

                  startAddTaxType(data)
                    .then(result => {
                      const invoiceTax = {
                        ...data,
                        isApplicable: Boolean(taxType.isApplicable),
                        taxTypeId: result.id
                      }
                      return startAddInvoiceTax(invoiceId, invoiceTax)
                    })
                    .then(() => handleClose())
                }
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  isMobile: state.adminState.isMobile,
})

const mapDispatchToProps = {
  startEditTaxType,
  startEditInvoiceTax,
  startAddTaxType,
  startAddInvoiceTax,
  startRemoveInvoiceTax,
  startRemoveTaxType,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaxTypeDialog)
