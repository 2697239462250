import uuid from 'uuid';
import { db, storage } from '../../../firebase';
import { analytics } from 'analytics'

export const startEditWebsiteTeam = (updates) => {
  return (dispatch) => db.updateWebsiteTeam(updates);
}

export const startAddWebsiteTeamMember = (teamMember, fileData) => {
  const fileId = uuid.v4();
  teamMember.fileId = fileId;

  return dispatch => db.createWebsiteTeamMember(teamMember)
    .then(result => storage.uploadTeamFile(fileId, fileData))
    .then(() => startSetWebsiteTeam()(dispatch))
}

export const startEditWebsiteTeamMember = (id, updates, fileData) => {
  const fileId = updates.fileId ? updates.fileId : uuid.v4();
  updates.fileId = fileId;

  if (fileData) {
    return dispatch => db.updateWebsiteTeamMember(id, updates)
      .then(() => storage.uploadTeamFile(fileId, fileData))
      .then(() => startSetWebsiteTeam()(dispatch));
  } else {
    return dispatch => db.updateWebsiteTeamMember(id, updates)
      .then(() => startSetWebsiteTeam()(dispatch));
  }
}

export const startRemoveWebsiteTeamMember = (id, fileId) => {
  return (dispatch) => db.deleteWebsiteTeamMember(id)
        .then(() => storage.deleteTeamFile(fileId))
        .then(() => startSetWebsiteTeam()(dispatch))
}

const setWebsiteTeam = (team) => ({
  type: 'SET_WEBSITE_TEAM',
  team
});

export const startSetWebsiteTeam = () => {
  return (dispatch) => {
    return (
      db.getWebsiteTeam().once('value')
        .then(snapshot => {
          const promises = [];
          const team = [];

          snapshot.forEach(childSnapshot => {
            team.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });

          team.forEach(item => {
            if(item.fileId) {
              promises.push(
                storage.getTeamDownloadURL(item.fileId)
                  .then(result => {
                    item.downloadURL = result
                  })
                  .catch(error => {
                    console.log("LOAD TEAM MEMBER URL FAILED:", error)
                    analytics.exception(`team::startSetWebsiteTeam [message:${error.message}]`)
                  })
              );
            }
          });

          Promise.all(promises).then(() => {
            dispatch(setWebsiteTeam(team));
            return dispatch({ type: 'VALIDATE_WEBSITE' });
          })
        })
    )
  }
}
