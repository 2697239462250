import { db, oid } from '../firebase';

export const getProjects = () =>
  db.ref(`appData/${oid}/projects`);

export const getProjectTasks = () =>
  db.ref(`appData/${oid}/projectTasks`)

export const getProjectTimes = () =>
  db.ref(`appData/${oid}/projectTime`);

export const deleteProjectField = (id, field) =>
  db.ref(`appData/${oid}/projects/${id}/${field}`).remove();
  
export const deleteProject= (id) =>
  db.ref(`appData/${oid}/projects/${id}`).remove();

export const createProject = (project) =>
  db.ref(`appData/${oid}/projects`).push(project)
  .then((ref) => ({id: ref.key, ...project}));

export const createProjectTask = (projectTaskData) => {
  const { labor, materials, ...projectTask } = projectTaskData;

  return (
    db.ref(`appData/${oid}/projectTasks`)
      .push(projectTask)
      .then((result) => {
        const projectTaskId = result.key;

        if (labor !== undefined && materials !== undefined) {
          return (
            createProjectTaskLaborArray(projectTaskId, labor)
            .then(() => createProjectTaskMaterialsArray(projectTaskId, materials))
          );
        } else if (labor !== undefined) {
          return createProjectTaskLaborArray(projectTaskId, labor);
        } else if (materials !== undefined) {
          return createProjectTaskMaterialsArray(projectTaskId, materials);
        } else {
          return result;
        }
      })
  );
}

export const createInterval = (projectId, interval) =>
  db.ref(`appData/${oid}/projects/${projectId}/intervals`).push(interval);


export const createProjectTasks = (projectTasks) => {
  const promises = projectTasks.map((projectTask) => {
    return createProjectTask(projectTask);
  });

  return Promise.all(promises)
}


export const createProjectTaskLabor = (projectTaskId, labor) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/labor`)
  .push(labor)

export const createProjectTaskLaborArray = (projectTaskId, laborArray) => {
  const promises = laborArray.map((labor) => {
    return createProjectTaskLabor(projectTaskId, labor);
  });

  return Promise.all(promises)
}

export const createProjectTaskMaterials = (projectTaskId, materials) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/materials`)
  .push(materials)

export const createProjectTaskMaterialsArray = (projectTaskId, materialsArray) => {
  const promises = materialsArray.map((materials) => {
    return createProjectTaskMaterials(projectTaskId, materials);
  });

  return Promise.all(promises)
}


export const updateProject = (projectId, updates) =>
  db.ref(`appData/${oid}/projects/${projectId}`).update(updates);

export const deleteProjectTaskLaborItem = (projectTaskId, id) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/labor/${id}`).remove();

export const deleteProjectTaskMaterialsItem = (projectTaskId, id) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/materials/${id}`).remove();

export const createProjectTaskLaborItem = (projectTaskId, item) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/labor`).push(item);

export const createProjectTaskMaterialsItem = (projectTaskId, item) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/materials`).push(item);

export const updateProjectTaskLaborItem = (projectTaskId, id, updates) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/labor/${id}`).update(updates);

export const updateProjectTaskMaterialsItem = (projectTaskId, id, updates) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/materials/${id}`).update(updates);

export const updateProjectTask = (projectTaskId, updates) => {
  //strip out labor and materials
  const { labor, materials, ...projectTask } = updates;

  return db.ref(`appData/${oid}/projectTasks/${projectTaskId}`)
    .update(projectTask)
    // .then((result) => {
    //   if (labor !== undefined && materials !== undefined) {
    //     return (
    //       updateProjectTaskLaborArray(projectTaskId, labor)
    //       .then(() => updateProjectTaskMaterialsArray(projectTaskId, materials))
    //     );
    //   } else if (labor !== undefined) {
    //     return updateProjectTaskLaborArray(projectTaskId, labor);
    //   } else if (materials !== undefined) {
    //     return updateProjectTaskMaterialsArray(projectTaskId, materials);
    //   } else {
    //     return result;
    //   }
    // });
}

export const updateProjectTaskLaborArray = (projectTaskId, laborArray) => {
  const promises = laborArray.map((labor) => {
    const { id, ...updates } = labor;
    return updateProjectLabor(projectTaskId, id, updates);
  });

  return Promise.all(promises);
}

export const updateProjectTaskMaterialsArray = (projectTaskId, materialsArray) => {
  const promises = materialsArray.map((materials) => {
    const { id, ...updates } = materials;
    return this.updateProjectMaterials(projectTaskId, id, updates);
  });

  return Promise.all(promises);
}

export const updateProjectLabor = (projectTaskId, laborId, updates) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/labor/${laborId}`)
    .update(updates)

export const updateProjectMaterials = (projectTaskId, materialsId, updates) =>
  db.ref(`appData/${oid}/projectTasks/${projectTaskId}/materials/${materialsId}`)
    .update(updates)
