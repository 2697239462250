
export const selectTimecardStatus = (store, timecard) => {
    const { invoicesState } = store;
    let isInvoiced = false;
    invoicesState.forEach(invoice => {
      invoice.lineItems.forEach((lineItem, id) => {
        if (lineItem.timecardId && (lineItem.timecardId === timecard.id)) {
          isInvoiced = true;
        }
      });
    });
    if (!timecard.billToClient) {
      return ({
        status: "",
        displayStatus: "",
        warningLevel: ""
      });
    } else if (isInvoiced) {
      return ({
        status: "invoiced",
        displayStatus: "Invoiced",
        warningLevel: "ok"
      });
    } else if (timecard.exclude) {
      return ({
        status: "excluded",
        displayStatus: "Excluded",
        warningLevel: "ok"
      });
    } else {
      return ({
        status: "not-invoiced",
        displayStatus: "Not Invoiced",
        warningLevel: "warning"
      });
    }
}

export const selectTimecardsList = (store, project) => {
  const filter = store.filters.timecardList;

  const timecards = store.timecardsState.filter((timecard) => {
    if (project !== undefined && timecard.projectId !== project.id) {
      return false;
    } else if(project !== undefined){
      return true;
    }

    const status = selectTimecardStatus(store, timecard);
    switch (filter.type) {
      case "all":
        if (filter.showArchived) {
          return true;
        } else if (status.status === "invoiced") {
          return false;
        } else {
          return true;
        }
      case status.status:
        return true;
      default:
        return false;
    }
  }).sort((a, b) => {
    switch (filter.orderBy) {
      case "date":
        return filter.direction === "asc" ? a.startDate - b.startDate : b.startDate - a.startDate;
      default:
        return filter.direction === "asc" ? a.startDate - b.startDate : b.startDate - a.startDate;
    }
  });

  return timecards;
}
