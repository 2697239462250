import store from 'store';

export const fbLog = (fbEvent) => {
  if(process.env.REACT_APP_FIREBASE === "production") {
      fbEvent()
  }
}

export const convertPrice = (price) =>
  (price*1.33)/Math.pow(10, 6);

export const isFreeDomain = (price) => {
  if(convertPrice(price) <= 20) {
    return true;
  } else {
    return false;
  }
}

export const getBidTotals = (bidTasks) => {
  const totals = {
    subtotal: 0,
    tax: 0,
    total: 0
  }

  bidTasks.forEach((bidTask) => {
      totals.subtotal += bidTask.subtotal;
  });

  totals.tax = totals.subtotal*0.05;
  totals.total = totals.subtotal + totals.tax;

  return totals;
}

export const getIconName = (key) => {
  switch (key) {
    case "fa-leaf":
      return "leaf";
    case "fa-truck":
      return "truck";
    case "fa-truck-loading":
      return "truck-loading";
    case "fa-truck-pickup":
      return "truck-pickup";
    case "fa-tree":
      return "tree";
    case "fa-house-damage":
      return "house-damage";
    case "fa-people-carry":
      return "people-carry";
    case "fa-seedling":
      return "seedling";
    case "fa-spa":
      return "spa";
    case "fa-trash":
      return "trash";
    case "fa-user-friends":
      return "user-friends";
    default:
  }
}


export const getHostingURL = () => {
  switch (process.env.REACT_APP_FIREBASE) {
    case "development":
      return process.env.REACT_APP_HOSTING_DEV;
    case "production":
      return process.env.REACT_APP_HOSTING_PROD;
    case "test":
      return process.env.REACT_APP_HOSTING_TEST;
    case "ut":
      return process.env.REACT_APP_HOSTING_UT;
    default:
      return process.env.REACT_APP_HOSTING_DEV;
  }
}

export const getWebsiteURL = (isPreview) => {
  const state = store.getState();
  const { subDomain, customDomain } = state.websiteState.root;
  const organization = state.accountState.organizations[0];
  const oid = organization ? organization.id : undefined;
  const orgDomains = state.orgDomains;
  let orgDomain = {};
  orgDomains.forEach(item => {
    if (oid && item.oid === oid) {
      orgDomain = item;
    }
  });

  const hostingURL = getHostingURL();

  let URL;
  if (
    orgDomain.customDomain &&
    customDomain === orgDomain.customDomain &&
    orgDomain.customDomainStatus === "approved"
  ) {
    console.log("CUSTOM DOMAIN APPROVED:", orgDomain.customDomain);
    URL = `https://${orgDomain.customDomain}${isPreview ? '/?preview=1' : ''}`;
  } else if(
    orgDomain.subDomain &&
    subDomain === orgDomain.subDomain &&
    orgDomain.subDomainStatus === "approved"
  ) {
    console.log("SUBDOMAIN APPROVED:", orgDomain.subDomain);
    URL = `https://${orgDomain.subDomain}.couraggo.com${isPreview ? '/?preview=1' : ''}`;
  } else {
    URL = `${hostingURL}/${subDomain ? subDomain : customDomain}${isPreview ? '?preview=1' : ''}`;
  }

  //console.log("WEBSITE URL:", URL);
  return URL;
}
