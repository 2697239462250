import { db } from '../../../firebase';

export const editWorkTypeForm = (updates) => ({
  type: "EDIT_WORK_TYPE_FORM",
  updates
});

export const setWorkTypeForm = (workType) => ({
  type: "SET_WORK_TYPE_FORM",
  workType
});

export const resetWorkTypeForm = () => ({
  type: "RESET_WORK_TYPE_FORM"
});

export const startOnDoneWorkType = () => {
  return (dispatch, getState) => {
    const { validation, ...workType } = getState().formData.workType;
    if (workType.id) {
      const { id, ...updates } = workType;
      return db.updateWorkType(id, updates);
    } else {
      return db.createWorkType(workType);
    }
  }
};

export const startRemoveWorkType = () => {
  return (dispatch, getState) => {
    const workType = getState().formData.workType;
    return db.deleteWorkType(workType.id);
  }
};
