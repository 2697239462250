import { db, oid } from '../firebase'

export const deleteTaxType = (id) =>
  db.ref(`appData/${oid}/taxTypes/${id}`).remove()

export const createTaxType = (taxType) =>
  db.ref(`appData/${oid}/taxTypes`).push(taxType)

export const updateTaxType = (id, updates) =>
  db.ref(`appData/${oid}/taxTypes/${id}`).update(updates)

export const getTaxTypes = () => db.ref(`appData/${oid}/taxTypes`)
