import { db } from '../../firebase';
import store from 'store'
//TODO: refactor into Invoices module
import { analytics } from 'analytics'

export const addInvoice = (invoice) => ({
  type: 'ADD_INVOICE',
  invoice
});

export const startRemoveInvoiceTax = (id, invoiceId) => dispatch =>
  db.deleteInvoiceTax(id, invoiceId)

export const startEditInvoiceTax = (id, invoiceId, updates) => dispatch =>
  db.updateInvoiceTax(id, invoiceId, updates)

export const startAddInvoiceTax = (invoiceId, tax) => dispatch =>
  db.createInvoiceTax(invoiceId, tax)

export const startRemoveInvoiceField = (id, field) => {
  return (dispatch) => db.deleteInvoiceField(id, field);
}

export const startAddInvoice = (invoiceData = {}) => {
  let createdInvoice;
  const { taxTypes } = store.getState()
  invoiceData.taxes = taxTypes.map(taxType => {
    return ({
      taxTypeId: taxType.id,
      name: taxType.name,
      rate: taxType.rate,
      isApplicable: true
    })
  })

  console.log("TAX TYPES:", taxTypes)
  return (dispatch) => (
    db.createInvoice(invoiceData)
    .then((invoice) => createdInvoice = invoice)
    .then(() => {
      const promises = [];
      if (invoiceData.projectTasks) {
        invoiceData.projectTasks.forEach(projectTask => {
          const lineItem = {
            description: "",
            quantity: 0,
            unitPrice: 0
          };
          if (projectTask.type === "maintenance") {
            lineItem.description = projectTask.description;
            lineItem.quantity = 1;
            lineItem.unitPrice = projectTask.monthlyAmount;
          } else if (projectTask.type === "estimate") {
            lineItem.description = projectTask.description;
            lineItem.quantity = 1;
            lineItem.unitPrice = projectTask.estimate;
          }

          promises.push(db.createLineItem(createdInvoice.id, lineItem));
        });
      }
      return Promise.all(promises);
    })
    .then(() => createdInvoice)
    .catch((error) => {
      console.log('startAddInvoice ERROR:', error)
      analytics.exception(`invoices::startAddInvoice [message:${error.message}]`)
    })
  );
};

export const editInvoice = (invoice) => ({
  type: 'EDIT_INVOICE',
  invoice
});

export const startEditInvoice = (invoiceID, invoiceData = {}) => {
  return (dispatch) => (
    db.updateInvoice(invoiceID, invoiceData)
    .then((invoice) => console.log("edited:", invoiceData))
      .catch((error) => {
        console.log('startEditInvoice ERROR:', error)
        analytics.exception(`invoices::startEditInvoice [message:${error.message}]`)
      })
  );
};


export const startEditInvoiceStatuses = (invoiceID, statuses) => {
  return (dispatch) => (
    db.updateInvoiceStatuses(invoiceID, statuses)
      .catch((error) => {
        console.log('startEditInvoiceStatuses ERROR:', error)
        analytics.exception(`invoices::startEditInvoiceStatuses [message:${error.message}]`)
      })
  );
}

export const startAddPayment = (invoiceID, payload) => {
    const payment = {
      date: payload.date,
      amount: payload.amount,
      note: payload.note
    };

    return (dispatch) => (
      db.createPayment(invoiceID, payment)
        .catch((error) => {
          console.log('startAddPayment ERROR:', error)
          analytics.exception(`invoices::startAddPayment [message:${error.message}]`)
        })
    );
};

export const startEditPayment = (invoiceID, paymentID, updates) => {
  return (dispatch) => (
    db.updatePayment(invoiceID, paymentID, updates)
    .catch((error) => {
      console.log('startEditLineItem ERROR:', error)
      analytics.exception(`invoices::startEditPayment [message:${error.message}]`)
    })
  );
};

export const startRemovePayment = (invoiceID, paymentID) => {
  return (dispatch) =>
    db.deletePayment(invoiceID, paymentID)
      .catch((error) => {
        console.log('startRemoveLineItem ERROR:', error)
        analytics.exception(`invoices::startRemovePayment [message:${error.message}]`)
      })
};

export const addLineItem = (invoiceID, lineItem) =>({
    type: 'ADD_LINE_ITEM',
    invoiceID
});

export const startAddLineItem = (invoiceID, payload) => {
    const lineItem = {
        approved: true,
        description: payload.description,
        quantity: payload.quantity,
        unitPrice: payload.unitPrice
    };

    Object.defineProperty(lineItem, 'total', {
      get() {
        return (this.quantity*this.unitPrice);
      },
      configurable: true,
    });

    return (dispatch) => (
      db.createLineItem(invoiceID, lineItem)
      .then((lineItem) => dispatch(addLineItem(invoiceID, lineItem)))
      .catch((error) =>{
        console.log('startAddLineItem ERROR:', error)
        analytics.exception(`invoices::startAddLineItem [message:${error.message}]`)
      })
    )
}

export const startRemoveLineItem = (invoiceID, lineItemID) => {
  return (dispatch) =>
    db.deleteLineItem(invoiceID, lineItemID)
      .catch((error) => {
        console.log('startRemoveLineItem ERROR:', error)
        analytics.exception(`invoices::startRemoveLineItem [message:${error.message}]`)
      })
};

export const editLineItem = (invoiceID, lineItemID, updates) => ({
  type: 'EDIT_LINE_ITEM',
  invoiceID,
  lineItemID,
  updates
});

export const startEditLineItem = (invoiceID, lineItemID, updates) => {
  return (dispatch) => (
    db.updateLineItem(invoiceID, lineItemID, updates)
    .then((lineItem) => dispatch(editLineItem))
    .catch((error) => {
      console.log('startEditLineItem ERROR:', error)
      analytics.exception(`invoices::startEditLineItem [message:${error.message}]`)
    })
  );
}

export const startRemoveInvoice = (id) => {
  return (dispatch) => db.deleteInvoice(id);
}

export const setInvoices = (invoices) => ({
  type: 'SET_INVOICES',
  invoices
});

export const startSetInvoices = () => {
  return (dispatch) => {
    return db.getInvoices().on('value', (snapshot) => {
      const invoices = [];

      snapshot.forEach((childSnapshot) => {
        //TODO:
        //1. properly implement destructuring
        //2. fix bug where duplicate invoice gets added on create invoice
        const {
          id,
          createdDate,
          dueDate,
          invoiceDate,
          invoiceNumber,
          projectId,
          scheduleDate,
          intervalId,
          tax,
          statuses,
          clientId,
          projectTaskId,
        } = Object.assign({id: childSnapshot.key}, childSnapshot.val());

        const lineItems = childSnapshot.val().lineItems !== undefined
          ? new Map(Object.entries(childSnapshot.val().lineItems))
          : new Map();

        const payments = childSnapshot.val().payments !== undefined
          ? new Map(Object.entries(childSnapshot.val().payments))
          : new Map();

        const _taxes = childSnapshot.val().taxes !== undefined
          ? Object.entries(childSnapshot.val().taxes).map(i => ({ id:i[0], ...i[1] }))
          : []

        const invoice = {
          id,
          createdDate,
          dueDate,
          invoiceDate,
          invoiceNumber,
          projectId,
          scheduleDate,
          intervalId,
          tax,
          statuses,
          lineItems,
          payments,
          clientId,
          projectTaskId,
          _taxes
        };

        invoice.lineItems.forEach(function(lineItem, key) {
          Object.defineProperty(lineItem, 'total', {
            get() {
              return (this.quantity*this.unitPrice);
            },
            configurable: true,
            enumerable: true
          });
        });

        Object.defineProperty(invoice, 'isApproved', {
          get() {
            let approved = true;

            this.lineItems.forEach(function(lineItem) {
              if(!lineItem.approved) approved = false;
            });

            return approved;
          },
          configurable: true,
          enumerable: true
        });

        Object.defineProperty(invoice, 'isPaid', {
          get() {
            if(this.lineItems.size && this.balance <= 0){
              return true;
            } else {
              return false;
            }
          },
          configurable: true,
          enumerable: true
        });

        Object.defineProperty(invoice, 'paymentTotal', {
          get() {
            let total = 0;
            this.payments.forEach(function(payment) {
              total += payment.amount;
            });

            return total;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(invoice, 'total', {
          get() {
            let total = 0;
            this.lineItems.forEach(function(lineItem) {
              total += lineItem.total;
            });

            return total;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(invoice, 'taxes', {
          get() {
            return this._taxes.map(tax => {
              return({
                ...tax,
                ammount: tax.isApplicable ? this.total*Number(tax.rate/100) : 0
              })
            })
          },
          configurable: true,
          enumerable: true,
        })

        Object.defineProperty(invoice, 'taxTotal', {
          get() {
            return this.taxes.reduce((acc, cur) => {
              return acc + cur.ammount
            }, 0)
          },
          configurable: true,
          enumerable: true,
        })

        Object.defineProperty(invoice, 'gst', {
          get() {
            return this.total*this.tax.gstApplicable*this.tax.gstRate;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(invoice, 'pst', {
          get() {
            return this.total*this.tax.pstApplicable*this.tax.pstRate;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(invoice, 'grandTotal', {
          get() {
            // console.log("GRAND TOTAL:", this.total)
            // console.log("GRAND TAX TOTAL:", this.taxTotal)
            return this.total + this.taxTotal;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(invoice, 'balance', {
          get() {
            return this.grandTotal - this.paymentTotal;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(invoice, 'status', {
          get() {
            let status = "created";
            if (this.isPaid){
              status = "paid";
            } else if (this.isApproved){
              status = "approved";
            } else if (this.statuses.sent){
              status = "sent";
            }

            return status;
          },
          configurable: true,
          enumerable: true,
        });

        invoices.push(invoice);
      });

      dispatch(setInvoices(invoices));
      dispatch({ type: 'VALIDATE_INVOICES' });
    });
  };
};
