import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import TaskItems from './TaskItems';

import { editQuoteForm } from '../../../../store/actions';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit*3,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    marginRight: theme.spacing.unit*2,
    flexGrow: 1,
  },
  estimateAmount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  title: {
    flexGrow: 1
  },
});

const QuoteForm = ({ classes, quote, editQuoteForm, handleChange }) => {
  return(
    <Card className={classes.root}>
      <CardContent className={classes.column}>
        <TextField
          className={classes.title}
          id="title"
          label="Title"
          placeholder="Enter title"
          margin="normal"
          value={quote.title}
          onChange={ event => editQuoteForm({ title: event.target.value })}
        />
        <div className={classes.row}>
          <TextField
            className={classes.description}
            id="description"
            label="Description"
            multiline
            rows="3"
            placeholder="Describe the task"
            margin="normal"
            value={quote.description}
            onChange={ event => editQuoteForm({ description: event.target.value })}
          />
          <div className={classes.estimateAmount}>
            <div></div>
          </div>
        </div>
        <TaskItems />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  quote: state.formData.quote,
});

const mapDispatchToProps = {
  editQuoteForm,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(QuoteForm);
