import { db, oid } from '../firebase';

export const getReceipts = () =>
  db.ref(`appData/${oid}/receipts`);

export const createReceipt = (receiptData) =>
  db.ref(`appData/${oid}/receipts`).push(receiptData)

export const deleteReceipt = (id) =>
  db.ref(`appData/${oid}/receipts/${id}`).remove();

export const updateReceipt = (id, updates) =>
  db.ref(`appData/${oid}/receipts/${id}`).update(updates);
