export const onboard_trial_button_clicked = 'phSDCMDRsssBEPbEsLsC'
export const onboard_choose_website = 'TJRsCJ7CzMsBEPbEsLsC'
export const onboard_choose_invoice = '8-_dCIuC3csBEPbEsLsC'

export const onboard_mission_ws_preview = '3FdLCIfVzMsBEPbEsLsC'
export const onboard_mission_ws_completed = '3h9VCKmU3csBEPbEsLsC'
export const onboard_mission_invoice_client_added = 'Gk60CKyU3csBEPbEsLsC'
export const onboard_mission_invoice_completed = 'pexTCNyd3csBEPbEsLsC'

export const onboard_email_sign_up = 'Kf2VCJPMzMsBEPbEsLsC'
export const onboard_fb_sign_up = 'I8vqCMnw6MsBEPbEsLsC'
