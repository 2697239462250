import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTruck,
  faLeaf,
  faTruckLoading,
  faTruckPickup,
  faTree,
  faHouseDamage,
  faPeopleCarry,
  faSeedling,
  faSpa,
  faTrash,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTruck,
  faLeaf,
  faTruckLoading,
  faTruckPickup,
  faTree,
  faHouseDamage,
  faPeopleCarry,
  faSeedling,
  faSpa,
  faTrash,
  faUserFriends,
);
