
export const selectReceiptStatus = (store, receipt) => {
  const { invoicesState } = store;
  let isInvoiced = false;
  invoicesState.forEach(invoice => {
    invoice.lineItems.forEach((lineItem, id) => {
      if (lineItem.receiptId && (lineItem.receiptId === receipt.id)) {
        isInvoiced = true;
      }
    });
  });

  if (receipt.type !== "rebill") {
    return ({
      status: "",
      displayStatus: "",
      warningLevel: ""
    });
  } else if (isInvoiced) {
    return ({
      status: "invoiced",
      displayStatus: "Invoiced",
      warningLevel: "ok"
    });
  } else if (receipt.exclude) {
    return ({
      status: "excluded",
      displayStatus: "Excluded",
      warningLevel: "ok"
    });
  } else {
    return ({
      status: "not-invoiced",
      displayStatus: "Not Invoiced",
      warningLevel: "warning"
    });
  }
}

export const selectReceiptsList = (store, project) => {
  const filter = store.filters.receiptList;

  const receipts = store.receiptsState.filter((receipt) => {
    if (project !== undefined && receipt.projectId !== project.id) {
      return false;
    } else if (project !== undefined) {
      return true;
    }

    const status = selectReceiptStatus(store, receipt);

    switch (filter.type) {
      case "all":
        if (filter.showArchived) {
          return true;
        } else if (status.status === "invoiced") {
          return false;
        } else {
          return true;
        }
      case status.status:
        return true;
      default:
        return false;
    }
  }).sort((a, b) => {
    switch (filter.orderBy) {
      case "date":
        return filter.direction === "asc" ? a.date - b.date : b.date - a.date;
      case "client":
        //TODO: this doesnt actually sort correctly
        const clientA = a.clientId === undefined
          ? undefined
          : store.clientsState.find((client) => client.id === a.clientId);
        const clientB = b.clientId === undefined
          ? undefined
          : store.clientsState.find((client) => client.id === b.clientId);

        if (clientA === undefined) {
          return filter.direction === "asc" ? -1 : 1;
        } else if (clientB === undefined){
          return filter.direction === "asc" ? 1 : -1;
        } else {
          const namesA = [clientA.firstName, clientA.lastName];
          const namesB = [clientB.firstName, clientB.lastName];
          const fullNameA = namesA.join("");
          const fullNameB = namesB.join("");
          return filter.direction === "asc"
            ? fullNameA.localeCompare(fullNameB)
            : fullNameB.localeCompare(fullNameA);
        }
      default:
        return filter.direction === "asc" ? a.date - b.date : b.date - a.date;
    }
  });

  return receipts;
}
