import _ from 'lodash';
import BidState from './BidState';

export const selectBidTemplates = (store) => {
  const [ result, remainder ] = _.partition(store.bidsState, (bid) => bid.isTemplate);
  const templatesToDisplay = 9;
  const resultDifference = templatesToDisplay - result.length;

  //if number of starred templates exceeds number to be displayed then return
  //subset of starred templates equal to the display number
  //else return the starred templates plus the difference from the remainder
  return resultDifference <= 0
    ? result.sort((a,b) => b.createdDate - a.createdDate).slice(0, templatesToDisplay)
    : _.concat(result, remainder.sort((a, b) => b.createdDate - a.createdDate).slice(0, resultDifference)).sort((a,b) => b.createdDate - a.createdDate)
};

export const selectBidList = (store) => {

  const filter = store.filters.bidList;
  const bids = store.bidsState.filter((bid) => {
    const bidState = new BidState({...bid.state});

    switch (filter.type) {
      case "all":
        if (filter.showArchived) {
          return true;
        } else if (bidState.isArchived){
          return false;
        } else {
          return true;
        }
      case "sent":
        if (bidState.type === "sent" || bidState.type === "overdue"){
          return true;
        } else {
          return false;
        }
      case bidState.type:
        return true;
      default:
        return false;
    }
  }).sort((a, b) => {
    switch (filter.orderBy) {
      case "date":
        return filter.direction === "asc" ? a.date - b.date : b.date - a.date;
      case "client":
        //TODO: this doesnt actually sort correctly
        const clientA = a.clientId === undefined
          ? undefined
          : store.clientsState.find((client) => client.id === a.clientId);
        const clientB = b.clientId === undefined
          ? undefined
          : store.clientsState.find((client) => client.id === b.clientId);

        if (clientA === undefined) {
          return filter.direction === "asc" ? -1 : 1;
        } else if (clientB === undefined){
          return filter.direction === "asc" ? 1 : -1;
        } else {
          const namesA = [clientA.firstName, clientA.lastName];
          const namesB = [clientB.firstName, clientB.lastName];
          const fullNameA = namesA.join("");
          const fullNameB = namesB.join("");
          return filter.direction === "asc"
            ? fullNameA.localeCompare(fullNameB)
            : fullNameB.localeCompare(fullNameA);
        }
      default:
        return filter.direction === "asc" ? a.bidNumber - b.bidNumber : b.bidNumber - a.bidNumber;
    }

  });

  return bids;
}
