import { db, oid } from '../firebase';
import moment from 'moment';

import { analytics } from 'analytics'

export const invoiceFactory = (invoiceData) => {
  let {
    invoiceNumber,
    projectId,
    invoiceDate,
    dueDate,
    createdDate,
    scheduleDate,
    tax = {}
  } = invoiceData;
  invoiceDate = invoiceDate.getTime();
  dueDate = dueDate.getTime();
  createdDate = createdDate.getTime();
  scheduleDate = scheduleDate.getTime();

  return {invoiceNumber, projectId, invoiceDate, dueDate, createdDate, scheduleDate, tax};

};

export const getInvoices = () =>
  db.ref(`appData/${oid}/invoices`);

export const deleteInvoiceField = (id, field) =>
  db.ref(`appData/${oid}/invoices/${id}/${field}`).remove();

export const deleteInvoice = (id) =>
  db.ref(`appData/${oid}/invoices/${id}`).remove();

export const createPayment = (invoiceID, payment) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/payments`).push(payment)

export const updatePayment = (invoiceID, paymentID, updates) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/payments/${paymentID}`).update(updates)

export const deletePayment = (invoiceID, paymentID) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/payments/${paymentID}`).remove()

export const createLineItem = (invoiceID, lineItem) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/lineItems`).push(lineItem)
  .then((ref) => Object.assign({id: ref.key}, lineItem))

export const deleteLineItem = (invoiceID, lineItemID) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/lineItems/${lineItemID}`).remove()

export const updateLineItem = (invoiceID, lineItemID, updates) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/lineItems/${lineItemID}`).update(updates)

export const updateInvoice = (invoiceID, invoiceData) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}`).update(invoiceData)

export const updateInvoiceStatuses = (invoiceID, statuses) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/statuses`).update(statuses);

export const sendInvoice = (payload) =>
  db.ref(`messages/invoices`).push(payload);

export const createInvoiceTax = (invoiceID, tax) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/taxes`).push(tax)

export const updateInvoiceTax = (id, invoiceID, updates) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/taxes/${id}`).update(updates)

export const deleteInvoiceTax = (id, invoiceID) =>
  db.ref(`appData/${oid}/invoices/${invoiceID}/taxes/${id}`).remove()

//TODO:
//1. generate line items and tax
//2. settle on a standard for when to convert dates to timestamp
//3. refactor so not one giant function
//4. handle case where project is not recurring and we want to include all the times
//5. don't hardcode path to bussiness
export const createInvoice = (invoiceData) => {
  const currentMoment = moment();
  let {
    invoiceNumber = '',
    projectId = '',
    clientId = "",
    projectTaskId = "",
    invoiceDate = new Date(),
    dueDate = new Date(currentMoment.add(1, 'months').subtract(1, 'days').valueOf()),
    createdDate = new Date(),
    scheduleDate = new Date(),
    tax = {},
    statuses = {
      approved: false,
      sent: false,
      paid: false
    },
    taxes,
  } = invoiceData;

  invoiceDate = invoiceDate.getTime();
  dueDate = dueDate.getTime();
  createdDate = createdDate.getTime();
  scheduleDate = scheduleDate.getTime();

  const invoice = {
    invoiceNumber,
    projectId,
    clientId,
    projectTaskId,
    invoiceDate,
    dueDate,
    createdDate,
    scheduleDate,
    tax,
    statuses,
    taxes: [],
  };
  if (invoiceData.intervalId !== undefined) {
    invoice.intervalId = invoiceData.intervalId;
  }

  return (
    db.ref(`appData/${oid}/nextInvoiceNumber`).once('value')
      .then((snapshot) => typeof snapshot.val() === 'number' ? snapshot.val() + 1 : 1)
      .then((nextInvoiceNumber) => (
          db.ref(`appData/${oid}`).update({nextInvoiceNumber: nextInvoiceNumber})
            .then(() => nextInvoiceNumber)
      ))
      .then((nextInvoiceNumber) => invoice.invoiceNumber = nextInvoiceNumber)
      .then(() => db.ref(`appData/${oid}/taxRules`).once('value'))
      .then((snapshot) => {
        const taxRule = snapshot.val().default;

        invoice.tax = {
          gstRate: taxRule.gstRate,
          pstRate: taxRule.pstRate,
          gstApplicable: taxRule.gstApplicable,
          pstApplicable: taxRule.pstApplicable,
        }
      })
      .then(() => {
        return (
          db.ref(`appData/${oid}/invoices`).push(invoice)
            .then((ref) => Object.assign(invoice, {id: ref.key}, invoice))
        );
      })
      .then(() => {
        const promises = []

        taxes.forEach(tax => {
          promises.push(
            db.ref(`appData/${oid}/invoices/${invoice.id}/taxes`).push(tax)
              .then(ref => {
                invoice.taxes.push({ taxTypeId: ref.key, ...tax })
                return ref
              })
          )
        })

        return Promise.all(promises)
      })
      .then(() => db.ref(`appData/${oid}/projectTime/${invoice.projectId}`).once('value'))
      .then((snapshot) => {
        // const projectTimes = snapshot.val() !== undefined ? snapshot.val() : [];
        // const convertedProjectTimes = [];
        // Object.keys(snapshot.val() !== undefined ? snapshot.val() : [])
        //   .forEach((key) => {
        //     if (key !== 'id'){
        //       convertedProjectTimes.push(Object.assign({}, {id: key}, projectTimes[key]));
        //     }
        //   });
        //
        // return convertedProjectTimes;
        return [];
      })
      .then((projectTimes) => {
        projectTimes.forEach((projectTime) => {
          const dateWorked = new Date(projectTime.dateWorked);
          const invoiceDate = new Date(invoice.invoiceDate);

          //If the project time matches the invoice date then add
          if(dateWorked.getYear() === invoiceDate.getYear() && dateWorked.getMonth() === invoiceDate.getMonth()){
            let description = `(${projectTime.crewName}) ${projectTime.workType}`;
            description += projectTime.workNotes === '' ? '' : ' - ' + projectTime.workNotes;
            let quantity = projectTime.minutesWorked/60;
            let unitPrice = projectTime.workTypeRate;
            let total = quantity.toFixed(2)*unitPrice.toFixed(2);
            let projectTimeId = projectTime.id;

            if (description !== undefined && !isNaN(quantity) && unitPrice !== undefined) {
              createLineItem(invoice.id, {
                projectTimeId,
                approved: false,
                description,
                quantity,
                unitPrice,
                total
              })
            }
          }
        });

      })
      .then(() => db.ref(`appData/${oid}/projectReceipts/${invoice.projectId}`).once('value'))
      .then((snapshot) => {
        const projectReceipts = snapshot.val() !== undefined ? snapshot.val() : [];
        const convertedProjectReceipts = [];

        Object.keys(!!snapshot.val() ? snapshot.val() : [])
          .forEach((key) => {
            if (key !== 'id'){
              convertedProjectReceipts.push(Object.assign({}, {id: key}, projectReceipts[key]));
            }
          });

        return convertedProjectReceipts;
      })
      .then((projectReceipts) => {
        projectReceipts.forEach((projectReceipt) => {
          const receiptDate = new Date(projectReceipt.dateOfReceipt);
          const invoiceDate = new Date(invoice.invoiceDate);

          if(receiptDate.getYear() === invoiceDate.getYear() && receiptDate.getMonth() === invoiceDate.getMonth()){
            if (projectReceipt.receiptType === "Materials") {
              let description = `${projectReceipt.receiptType} - ${projectReceipt.category}`;
              let quantity = 1;
              let unitPrice = projectReceipt.totalAmount;
              let total = unitPrice;
              let projectReceiptID = projectReceipt.id;

              createLineItem(invoice.id, {
                projectReceiptID,
                approved: false,
                description,
                quantity,
                unitPrice,
                total
              });
            }

          }
        });
      })
      .then(() => invoice)
      .catch((error) =>{
        console.log('createInvoice ERROR:', error)
        analytics.exception(`invoices::createInvoice [message:${error.message}]`)
      })
  );
}
