import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import indigo from '@material-ui/core/colors/indigo'

import illustrations from "./img";
import couraggoIcon from 'img/logo/CouraggoLogoTransparent.png';

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    marginTop: theme.spacing.unit,
  },
  title: {
    alignSelf: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit*2,
  },
  typography: {
    alignSelf: "center",
  },
  img: {
    marginTop: theme.spacing.unit,
    width: "100%",
  },
  container: {
    backgroundColor: indigo[100],
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit*2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  newTitle: {
    color: indigo[500],
    fontWeight: 'bold',
    fontSize: '18px',
  },
  greyText: {
    opacity: .8
  },
  li: {
    opacity: .8,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit*3,
  },
  liText: {
    fontSize: '17px',
    marginBottom: theme.spacing.unit,
  },
  logo: {
    marginTop: theme.spacing.unit,
    opacity: .6,
    alignSelf: 'center',
    width: '40px',
    height: '40px'
  },
  spacer: {
    margin: theme.spacing.unit,
  }
});

const Unsupported = ({ classes }) => {
  return(
    <div className={classes.root}>
      <img
        className={classes.logo}
        alt="Couraggo Icon"
        src={couraggoIcon}
      />
      <Typography className={classes.title}>
        Oops - this device is too small
      </Typography>
      <div className={classes.container}>
        <Typography align="center" className={classes.newTitle}>
          New to Couraggo?
        </Typography>
        <div className={classes.spacer} />
        <Typography style={{ fontWeight: 400, fontSize: '18px'}}>
          For a freebie and a reminder when at your computer, send a blank email:
        </Typography>
        <div className={classes.spacer} />
        <a href="mailto:support@couraggo.com?Subject=Hello%" target="_top">support@couraggo.com</a>
      </div>

      <Typography style={{ marginTop: '20px', paddingLeft: '15px', opacity: .7, fontSize: '18px', fontWeight: 500}}>
        For optimal use:
      </Typography>
      <ol
        type="1"
        style={{ paddingLeft: '33px' }}
      >
        <li className={classes.li}>
          <Typography className={classes.liText}>
            Main application (Web):
          </Typography>
          <Typography className={classes.liText}>
            *Setup account access.
          </Typography>
          <Typography className={classes.liText}>
            Build website / manage paperwork.
          </Typography>
          <Typography className={classes.liText}>
            Works on computers or tablets.
          </Typography>
        </li>
        <li className={classes.li}>
          <Typography className={classes.liText}>
            Mobile Application (Phone):
          </Typography>
          <Typography className={classes.liText}>
            For quick entry of work.
          </Typography>
          <Typography className={classes.liText}>
            Download for Android or iOS.
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default withStyles(styles)(Unsupported);
