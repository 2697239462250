const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case "SET_BID_EDITOR":
      return action.bidEditor;
    case "EDIT_BID_EDITOR_STATE":
      const editorState = state.states.get(action.key);
      return {...state, _currentState: editorState};
    default:
      return state;
  }
}
