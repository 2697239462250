
export const editBidEditorState = (key) => ({
  type: 'EDIT_BID_EDITOR_STATE',
  key
});

export const startEditBidEditorState = (key) => {
  return (dispatch) => dispatch(editBidEditorState(key))
};

export const setBidEditor = (bidEditor) => ({
  type: 'SET_BID_EDITOR',
  bidEditor
})

export const startSetBidEditor = () => {
  return (dispatch) => {
    const bidEditor = {
      _currentState: undefined,
      states: new Map()
    };

    const factory = new Factory();

    const general = factory.createBidEditorState("general");
    const sales = factory.createBidEditorState("sales");
    const tasks = factory.createBidEditorState("tasks");
    const credibility = factory.createBidEditorState("credibility");

    const generalDate = factory.createBidEditorState("generalDate");
    const generalExpires = factory.createBidEditorState("generalExpires");
    const generalClient = factory.createBidEditorState("generalClient");

    const salesNoneButton = factory.createBidEditorState("salesNoneButton");
    const salesBasicButton = factory.createBidEditorState("salesBasicButton");
    const salesAverageButton = factory.createBidEditorState("salesAverageButton");
    const salesSalesyButton = factory.createBidEditorState("salesSalesyButton");
    const salesContact = factory.createBidEditorState("salesContact");
    const salesJobCode = factory.createBidEditorState("salesJobCode");
    const salesIntroduction = factory.createBidEditorState("salesIntroduction");
    const salesSituation = factory.createBidEditorState("salesSituation");
    const salesSolution = factory.createBidEditorState("salesSolution");

    const jobTasksSimpleButton = factory.createBidEditorState("jobTasksSimpleButton");
    const jobTasksEstimateButton = factory.createBidEditorState("jobTasksEstimateButton");
    const jobTasksQuoteButton = factory.createBidEditorState("jobTasksQuoteButton");
    const jobTasksRecurringButton = factory.createBidEditorState("jobTasksRecurringButton");
    const jobTasksTitle = factory.createBidEditorState("jobTasksTitle");
    const jobTasksEstimateAmount = factory.createBidEditorState("jobTasksEstimateAmount");
    const jobTasksDescription = factory.createBidEditorState("jobTasksDescription");
    const jobTasksOptional = factory.createBidEditorState("jobTasksOptional");
    const jobTasksAddItemLabor = factory.createBidEditorState("jobTasksAddItemLabor");
    const jobTasksAddItemMaterials = factory.createBidEditorState("jobTasksAddItemMaterials");
    const jobTasksMonthlyAmount = factory.createBidEditorState("jobTasksMonthlyAmount");
    const jobTasksStartingDate = factory.createBidEditorState("jobTasksStartingDate");
    const jobTasksFrequency = factory.createBidEditorState("jobTasksFrequency");
    const jobTasksOccurences = factory.createBidEditorState("jobTasksOccurences");
    const jobTasksBudget = factory.createBidEditorState("jobTasksBudget");

    const credNoneButton = factory.createBidEditorState("credNoneButton");
    const credBasicButton = factory.createBidEditorState("credBasicButton");
    const credBasicPlusButton = factory.createBidEditorState("credBasicPlusButton");
    const credDetailsButton = factory.createBidEditorState("credDetailsButton");
    const credAllButton = factory.createBidEditorState("credAllButton");
    const credNotes = factory.createBidEditorState("credNotes");
    const credTerms = factory.createBidEditorState("credTerms");
    const credTaxNumber = factory.createBidEditorState("credTaxNumber");
    const credCompanyNumber = factory.createBidEditorState("credCompanyNumber");

    const iGeneralDate = factory.createBidEditorState("iGeneralDate");
    const iGeneralDue = factory.createBidEditorState("iGeneralDue");
    const iGeneralClient = factory.createBidEditorState("iGeneralClient");
    const iGeneralJob = factory.createBidEditorState("iGeneralJob");
    const iLineItemsAdd = factory.createBidEditorState("iLineItemsAdd");
    const iLineItemsAddTAndM = factory.createBidEditorState("iLineItemsAddTAndM");
    const iLineItemsAutoGenerate = factory.createBidEditorState("iLineItemsAutoGenerate");
    const iPaymentsAdd = factory.createBidEditorState("iPaymentsAdd");

    bidEditor.states.set(general.type, general);
    bidEditor.states.set(sales.type, sales);
    bidEditor.states.set(tasks.type, tasks);
    bidEditor.states.set(credibility.type, credibility);
    bidEditor.states.set(generalDate.type, generalDate);
    bidEditor.states.set(generalExpires.type, generalExpires);
    bidEditor.states.set(generalClient.type, generalClient);

    bidEditor.states.set(salesJobCode.type, salesJobCode);
    bidEditor.states.set(salesJobCode.type, salesJobCode);
    bidEditor.states.set(salesBasicButton.type, salesBasicButton);
    bidEditor.states.set(salesAverageButton.type, salesAverageButton);
    bidEditor.states.set(salesContact.type, salesContact);
    bidEditor.states.set(salesIntroduction.type, salesIntroduction);
    bidEditor.states.set(salesSalesyButton.type, salesSalesyButton);
    bidEditor.states.set(salesNoneButton.type, salesNoneButton);
    bidEditor.states.set(salesSituation.type, salesSituation);
    bidEditor.states.set(salesSolution.type, salesSolution);

    bidEditor.states.set(jobTasksEstimateButton.type, jobTasksEstimateButton);
    bidEditor.states.set(jobTasksSimpleButton.type, jobTasksSimpleButton);
    bidEditor.states.set(jobTasksQuoteButton.type, jobTasksQuoteButton);
    bidEditor.states.set(jobTasksRecurringButton.type, jobTasksRecurringButton);
    bidEditor.states.set(jobTasksTitle.type, jobTasksTitle);
    bidEditor.states.set(jobTasksEstimateAmount.type, jobTasksEstimateAmount);
    bidEditor.states.set(jobTasksDescription.type, jobTasksDescription);
    bidEditor.states.set(jobTasksOptional.type, jobTasksOptional);
    bidEditor.states.set(jobTasksAddItemLabor.type, jobTasksAddItemLabor);
    bidEditor.states.set(jobTasksAddItemMaterials.type, jobTasksAddItemMaterials);
    bidEditor.states.set(jobTasksMonthlyAmount.type, jobTasksMonthlyAmount);
    bidEditor.states.set(jobTasksStartingDate.type, jobTasksStartingDate);
    bidEditor.states.set(jobTasksFrequency.type, jobTasksFrequency);
    bidEditor.states.set(jobTasksOccurences.type, jobTasksOccurences);
    bidEditor.states.set(jobTasksBudget.type, jobTasksBudget);

    bidEditor.states.set(credNoneButton.type, credNoneButton);
    bidEditor.states.set(credBasicButton.type, credBasicButton);
    bidEditor.states.set(credBasicPlusButton.type, credBasicPlusButton);
    bidEditor.states.set(credDetailsButton.type, credDetailsButton);
    bidEditor.states.set(credAllButton.type, credAllButton);
    bidEditor.states.set(credNotes.type, credNotes);
    bidEditor.states.set(credTerms.type, credTerms);
    bidEditor.states.set(credTaxNumber.type, credTaxNumber);
    bidEditor.states.set(credCompanyNumber.type, credCompanyNumber);


    bidEditor.states.set(iGeneralDate.type, iGeneralDate);
    bidEditor.states.set(iGeneralDue.type, iGeneralDue);
    bidEditor.states.set(iGeneralClient.type, iGeneralClient);
    bidEditor.states.set(iGeneralJob.type, iGeneralJob);
    bidEditor.states.set(iLineItemsAdd.type, iLineItemsAdd);
    bidEditor.states.set(iLineItemsAddTAndM.type, iLineItemsAddTAndM);
    bidEditor.states.set(iLineItemsAutoGenerate.type, iLineItemsAutoGenerate);
    bidEditor.states.set(iPaymentsAdd.type, iPaymentsAdd);

    bidEditor.setState = (type) => bidEditor._currentState = bidEditor.states.get(type);

    bidEditor.setState("general");
    dispatch(setBidEditor(bidEditor));
  }
}

//TODO: move this factory to a factories module, also make it a singleton
//so it can be accessed without instantiation and assignment
function Factory() {
    this.createBidEditorState = (type) => {
      let state;

      switch (type) {
        case "general":
          state = new GeneralState();
          break;
        case "generalExpires":
          state = new GeneralExpires();
          break;
        case "generalClient":
          state = new GeneralClient();
          break;
        case "sales":
          state = new SalesState();
          break;
        case "tasks":
          state = new TasksState();
          break;
        case "credibility":
          state = new CredibilityState();
          break;

        case "salesJobCode":
          state = new SalesJobCodeState();
          break;
        case "salesBasicButton":
          state = new SalesBasicButton();
          break;
        case "salesAverageButton":
          state = new SalesAverageButton();
          break;
        case "salesContact":
          state = new SalesContact();
          break;
        case "salesIntroduction":
          state = new SalesIntroduction();
          break;
        case "salesSalesyButton":
          state = new SalesSalesyButton();
          break;
        case "salesSituation":
          state = new SalesSituation();
          break;
        case "salesSolution":
          state = new SalesSolution();
          break;
        case "salesNoneButton":
          state = new SalesNoneButton();
          break;
        case "generalDate":
          state = new GeneralDate();
          break;

        case "jobTasksEstimateButton":
          state = new JobTasksEstimateButton();
          break;
        case "jobTasksQuoteButton":
          state = new JobTasksQuoteButton();
          break;
        case "jobTasksRecurringButton":
          state = new JobTasksRecurringButton();
          break;
        case "jobTasksSimpleButton":
          state = new JobTasksSimpleButton();
          break;
        case "jobTasksTitle":
          state = new JobTasksTitle();
          break;
        case "jobTasksEstimateAmount":
          state = new JobTasksEstimateAmount();
          break;
        case "jobTasksDescription":
          state = new JobTasksDescription();
          break;
        case "jobTasksOptional":
          state = new JobTasksOptional();
          break;
        case "jobTasksAddItemLabor":
          state = new JobTasksAddItemLabor();
          break;
        case "jobTasksAddItemMaterials":
          state = new JobTasksAddItemMaterials();
          break;
        case "jobTasksMonthlyAmount":
          state = new JobTasksMonthlyAmount();
          break;
        case "jobTasksStartingDate":
          state = new JobTasksStartingDate();
          break;
        case "jobTasksFrequency":
          state = new JobTasksFrequency();
          break;
        case "jobTasksOccurences":
          state = new JobTasksOccurences();
          break;
        case "jobTasksBudget":
          state = new JobTasksBudget();
          break;

        case "credNoneButton":
          state = new CredNoneButton();
          break;
        case "credBasicButton":
          state = new CredBasicButton();
          break;
        case "credBasicPlusButton":
          state = new CredBasicPlusButton();
          break;
        case "credDetailsButton":
          state = new CredDetailsButton();
          break;
        case "credAllButton":
          state = new CredAllButton();
          break;
        case "credNotes":
          state = new CredNotes();
          break;
        case "credTerms":
          state = new CredTerms();
          break;
        case "credTaxNumber":
          state = new CredTaxNumber();
          break;
        case "credCompanyNumber":
          state = new CredCompanyNumber();
          break;


        case "iGeneralDate":
          state = new IGeneralDate();
          break;
        case "iGeneralDue":
          state = new IGeneralDue();
          break;
        case "iGeneralClient":
          state = new IGeneralClient();
          break;
        case "iGeneralJob":
          state = new IGeneralJob();
          break;
        case "iLineItemsAdd":
          state = new ILineItemsAdd();
          break;
        case "iLineItemsAddTAndM":
          state = new ILineItemsAddTAndM();
          break;
        case "iLineItemsAutoGenerate":
          state = new ILineItemsAutoGenerate();
          break;
        case "iPaymentsAdd":
          state = new IPaymentsAdd();
          break
        default:
      }

      state.type = type;

      return state
    }
}


const CredibilityState = function () {
  this.title = "Credibility";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Credibility"]
    },
    {
      heading: "Benefit",
      paragraphs:[
        "By detailing out each part of your labor, this gives your customer peace of mind; especially if they are sensitive to price",
        "It also looks very professional and allows them to compare against other bids... and if other bids aren't detailed out you'll stand a better chance!",
        "Note: rows can be moved by dragging the dashes at leftmost of the row."
      ]
    },
    {
      heading: "Points",
      paragraphs:["2 per row"]
    },
  ];
};

const CredAllButton = function () {
  this.title = "All";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["The full kit and kaboodle!",
                  "Section 1 - Accreditations, Associations etc. for instant credibility."
                  ,"Section 2 - Testimonials. As seen on your website. Just pick and choose which ones apply best."
                  ,"Section 3 - Documents that provide details, drawings, diagrams, photos etc. of your solution."
                  // ,"This can be a visual way to convey how you're going to meet their needs."
                ]
      },
      {
        heading: "Points",
        paragraphs:["48"]
      },
    ];
  };

const CredBasicButton = function () {
  this.title = "Basic";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["Just the basic parts of a Bid. Here you can add a Note and the Terms for the Bid."
                ,"Terms allow you to set boundaries or rules for the bid like a guarantee or set a payment schedule."
                ,"* Once you add Terms for a Bid, they'll appear on the next Bid you do, which can be changed of course!"
                ]
      },
      {
        heading: "Points",
        paragraphs:["17"]
      },
    ];
  };

const CredBasicPlusButton = function () {
  this.title = "Basic Plus";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["The Basic + has everything from the Basics (Notes and Terms) along with a spot to add logos of your accreditations, associations, 10yr anniversaries etc."
                ,"These logos will make the Bid look professional and inspire confidence in your future client."
                ,"* The Terms and logos added will appear on the next Bid you do, which can be changed of course!"
                ]
      },
      {
        heading: "Points",
        paragraphs:["25"]
      },
    ];
  };

  const CredCompanyNumber = function () {
    this.title = "Company Number";
    this.sections = [
      {
        heading: "Section",
        paragraphs:["Credibility"]
      },
      {
        heading: "Benefit",
        paragraphs:["Providing your company number gives a sense of legitimacy to your company, giving your future client comfort that you are reputable."

        ]
      },
      {
        heading: "Points",
        paragraphs:["4"]
      },
    ];
  };

const CredDetailsButton = function () {
  this.title = "Details";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["Here you enter Notes and Terms along with detailed Tax and Company numbers."
                ,"Some clients like to see details and these makes you appear more credible in their eyes. If you are working with someone that is very detail oriented, this wouldn't be a bad thing to add."
                ,"* Once you add these values, the next Bid you do, they will be automatically filled out."
                ]
      },
      {
        heading: "Points",
        paragraphs:["23"]
      },
    ];
  };

const CredNoneButton = function () {
  this.title = "None";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["For a quick and simple Bid, you can skip adding any notes, terms or your accreditations and testimonials that would help 'sell' your services."
                ]
      },
      {
        heading: "Points",
        paragraphs:["0"]
      },
    ];
  };

const CredNotes = function () {
  this.title = "Notes";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Credibility"]
    },
    {
      heading: "Benefit",
      paragraphs:["For additional notes on the Bid to provide things like a start date or end date or other logistical details not already covered in the Bid."
      ]
    },
    {
      heading: "Points",
      paragraphs:["7"]
    },
  ];
};

const CredTaxNumber = function () {
  this.title = "Tax Number";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Credibility"]
    },
    {
      heading: "Benefit",
      paragraphs:["Providing your tax number gives a sense of legitimacy to your company, giving your future client comfort that you are reputable."

      ]
    },
    {
      heading: "Points",
      paragraphs:["4"]
    },
  ];
};

const CredTerms = function () {
  this.title = "Terms";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Credibility"]
    },
    {
      heading: "Benefit",
      paragraphs:["Terms allow you to set boundaries or rules for the Bid."
              ,"For example you can add language for a guarantee, or limited guarantee. Or set the payment schedule as in 25%, 50% in middle and 25% upon inspection."
      ]
    },
    {
      heading: "Points",
      paragraphs:["10"]
    },
  ];
};

const GeneralClient = function () {
  this.title = "Client";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["Pretty simple, pick your client from the list.",
                  "If the Client doesn't exist, go to Setup on the main menu on far left to add the Client."
                ]
      },
      {
        heading: "Points",
        paragraphs:["2"]
      },
    ];
  };

const GeneralDate = function () {
  this.title = "Date";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["The Date sets the first validity date of the Bids' pricing and details. You'll set an Expiry date in the Expires field below.",
                  "It's important that both sides know when the Bid is valid so there is no confusion and ensure you aren't stuck with pricing of labor and materials for an extended period."
                ]
      },
      {
        heading: "Points",
        paragraphs:["1"]
      },
    ];
  };

const GeneralExpires = function () {
  this.title = "Expires";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["General Information"]
      },
      {
        heading: "Benefit",
        paragraphs:["The Expires Date sets the ending validity date of the Bids' pricing and details.",
                  "It's important that both sides know when the Bid is valid so there is no confusion and ensure you aren't stuck with pricing of labor and materials for an extended period."
                ]
      },
      {
        heading: "Points",
        paragraphs:["5"]
      },
    ];
  };

const GeneralState = function () {
  this.title = "General";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["General Information"]
    },
    {
      heading: "Date",
      paragraphs:[
        "The Date is when the Bid is sent to your customer. It's important so both sides know when the Bid process started and works in conjuntion to set an Expiry Date."
      ]
    },
    {
      heading: "Expires Date",
      paragraphs:[
        "The Expires date is very important as the rates for both Labor and/or Materials are fixed up until the Expiry Date. It puts pressure on your customer to decide sooner as they will worry the rates may rise… thus shortening their decision making time."
      ]
    },
    {
      heading: "Client",
      paragraphs:[
        "Pretty simple, pick your client from the list. If the Client doesn't exist, go to Setup on the main menu on far left to add the Client."
      ]
    },
    {
      heading: "Points",
      paragraphs:["Date = 1, Expires = 5, Client = 2"]
    },
  ];
};

const JobTasksAddItemLabor = function () {
  this.title = "Add Item";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The Labor Add Item popup allows entry of detailed Labor costs."
                ,"More details provide more comfort that you will deliver what they want at a reasonable price."
                ,"It also looks very professional and allows them to compare against other bids... and if other bids aren't detailed out, you'll stand a better chance!",
                ]
      },
      {
        heading: "Points",
        paragraphs:["7"]
      },
    ];
  };

const JobTasksAddItemMaterials = function () {
  this.title = "Add Item";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The Materials Add Item popup allows entry of detailed Material costs."
                ,"More details provide more comfort that you will deliver what they want at a reasonable price."
                ,"It also looks very professional and allows them to compare against other bids... and if other bids aren't detailed out, you'll stand a better chance!",
                ]
      },
      {
        heading: "Points",
        paragraphs:["7"]
      },
    ];
  };

const JobTasksBudget = function () {
  this.title = "Hours Budget";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The number of hours you have committed to perform each period, say 'one month'."
                ,"If you enter time on a recurring project, you'll see how many hours you are above or below the hours budget."
                ,"It tracks this over the whole project so youconsole.log(JSON.stringify(editorState, null, 2)); see if you need to reduce or increase hours spent."
                ]
      },
      {
        heading: "Points",
        paragraphs:["7"]
      },
    ];
  };

const JobTasksDescription = function () {
  this.title = "Description";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["Here you can write out more details about what the task will accomplish… or in the clients' mind, what they are getting for their money."
                ,"A good description provides comfort for your future client to purchase your services and thus increase your chance of a successfull Bid."
                ]
      },
      {
        heading: "Points",
        paragraphs:["7"]
      },
    ];
  };

const JobTasksEstimateAmount = function () {
  this.title = "Estimate Amount";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The amount you 'estimate' this will cost your client."
                ,"Being an estimate, ensure the client knows the final amount may differ."
                ]
      },
      {
        heading: "Points",
        paragraphs:["4"]
      },
    ];
  };

const JobTasksEstimateButton = function () {
  this.title = "Estimate";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The estimate task type provides room for more description of the task along with the option to make it an optional task."
                ,"As well, the estimate type makes it clear to your future client that the amount is just that, an estimate, such that the final amount charged may be different."
                ]
      },
      {
        heading: "Points",
        paragraphs:["16"]
      },
    ];
  };

const JobTasksFrequency = function () {
  this.title = "Frequency";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["For recurring tasks, this is the period the task will re-occur. Common values would be monthly or weekly, for now it's fixed at monthly."
                ]
      },
      {
        heading: "Points",
        paragraphs:["3"]
      },
    ];
  };

const JobTasksMonthlyAmount = function () {
  this.title = "Monthly Amount";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["For a recurring task, this is the total amount you will be paid for each period."
                  ,"It's based on the 'How often it occurs?' value… which is usually a monthly time frame."
                ]
      },
      {
        heading: "Points",
        paragraphs:["4"]
      },
    ];
  };

const JobTasksOccurences = function () {
  this.title = "How many times?";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["For a recurring task, this is the number of times the recurring task repeats."
                ,"For example, a yearly maintenance contract could start on Jan. 1, occur monthly and repeat 12 times."
                ]
      },
      {
        heading: "Points",
        paragraphs:["4"]
      },
    ];
  };

const JobTasksOptional = function () {
  this.title = "Optional";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["This will determine if this task is a wishlist item or a 'must have'."
                  ,"Often clients require some tasks done, but want other stuff quoted to see if the price works for them."
                ,"Required tasks show in the Summary section."
                ,"Optional tasks show in a separate Optional Summary section."
                ]
      },
      {
        heading: "Points",
        paragraphs:["3"]
      },
    ];
  };

const JobTasksQuoteButton = function () {
  this.title = "Quote";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The quote type allows you to provide lots detail through labor and material cost line items."
                ,"A quote implies the amount you quote won't change."
                ,"Providing this much detail gives your future client confidence in you and your solution, improving your chances of winning the bid."
                // ," It is also a good practice, even for small bids, to always put your best foot forward to get repeat business and great referrals."
                ]
      },
      {
        heading: "Points",
        paragraphs:["25"]
      },
    ];
  };

const JobTasksRecurringButton = function () {
  this.title = "Recurring";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["The recurring type handles work that occurs on a regular interval, for example, maintenance work paid monthly."
                ,"Specify the amount paid, when it starts, how many times it will occur and then the budget of hours set for each period."
                ,"The hours budget is tracked to show which months were too high or low for hours, and the total time spent.",
                // "For example, the contract could start January 1, occur each month for 12 months with a budget of 20 hours and the client is charges $1000 per month."
                ]
      },
      {
        heading: "Points",
        paragraphs:["21"]
      },
    ];
  };

  const JobTasksSimpleButton = function () {
    this.title = "Simple";
    this.sections = [
        {
          heading: "Section",
          paragraphs:["Job Tasks"]
        },
        {
          heading: "Benefit",
          paragraphs:["A quick entry of tasks that don’t require much description and a single entry for the cost."
                  ]
        },
        {
          heading: "Points",
          paragraphs:["6"]
        },
      ];
    };

const JobTasksStartingDate = function () {
  this.title = "Starting Date";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Job Tasks"]
      },
      {
        heading: "Benefit",
        paragraphs:["For a recurring task, this is the first day the task starts."
                ]
      },
      {
        heading: "Points",
        paragraphs:["3"]
      },
    ];
  };

  const JobTasksTitle = function () {
    this.title = "Title";
    this.sections = [
        {
          heading: "Section",
          paragraphs:["Job Tasks"]
        },
        {
          heading: "Benefit",
          paragraphs:["The title of the job task, short and sweet as needed."
                    ,"Especially useful when there are multiple tasks in the bid."
                  ]
        },
        {
          heading: "Points",
          paragraphs:["3"]
        },
      ];
    };

const SalesAverageButton = function () {
  this.title = "Average";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["This level of Bid context is the most common.",
                  "It'll be personal with your name and a job code along with an introduction to show you understand their situation along with a written solution that will meet their needs.",
                  "This kind of detail and care impresses clients."
                ]
      },
      {
        heading: "Points",
        paragraphs:["19"]
      },
    ];
  };

const SalesBasicButton = function () {
  this.title = "Basic";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["The basic context for the Bid, a contact name for them to make it more personal and a job code to provide context when discussing the work.",
                  "The job code is especially useful when you have more than one job for that client and want a report on all the historical work you did for them."
                ]
      },
      {
        heading: "Points",
        paragraphs:["7"]
      },
    ];
  };

const SalesContact = function () {
  this.title = "Contact";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["Pretty simple, this is likely you or the person in charge of making the deal.",
                ]
      },
      {
        heading: "Points",
        paragraphs:["3"]
      },
    ];
  };

const SalesJobCodeState = function () {
  this.title = "Job Code";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["This can be helpful to easily identify this Bid in your list of Bids and if it becomes a Job, this value will transfer over to be easily found.",
                "Also, if you do many jobs for one client, it'll be nice to have different names to refer to each job when looking up the client history."
                ]
      },
      {
        heading: "Points",
        paragraphs:["4"]
      },
    ];
  };

const SalesIntroduction = function () {
  this.title = "Introduction";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["Taking the time to document your understanding of their wants or needs gives this bid a personal touch. It shows you were listening and you care about “their” project."
                ,"As well, you can describe your work plan along with any personal anecdotes discussed to further align you with them."
                // ,"The introduction can explain both the Situation and Solution in one area."        ]
                ]
      },
      {
        heading: "Points",
        paragraphs:["12"]
      },
    ];
  };

const SalesNoneButton = function () {
  this.title = "None";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["This removes any context about the Bid itself. Good for really quick and simple bids that aren't competitive.",
                  "We would argue that having at least your contact name and a job code adds a professional touch and the more professional you present yourself, the better you build your companies' brand."
                ]
      },
      {
        heading: "Points",
        paragraphs:["0"]
      },
    ];
  };

const SalesSalesyButton = function () {
  this.title = "Salesy";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["The big kahuna of context!",
                  "This will be important for larger or more competitive bids. You're basically putting some 'wow' in your bid so it stands apart from the rest.",
                  "Yes, it takes more time but once you get used to thinking about and then writing down your future clients' situation and solution, you will find that you uncover some better ideas!"
                  // ,"Lastly, adding more detail will make it easier to remember what is required."
                ]
      },
      {
        heading: "Points",
        paragraphs:["27"]
      },
    ];
  };

const SalesSituation = function () {
  this.title = "Situation";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["By documenting your understanding of their wants or needs you give this bid a personal touch. It shows you were listening and that you care about “their” project."
                ,"As well, you can add any critical points or issues they stressed; this will give them comfort that you have everything handled."
                ]
      },
      {
        heading: "Points",
        paragraphs:["10"]
      },
    ];
  };

const SalesSolution = function () {
  this.title = "Solution";
  this.sections = [
      {
        heading: "Section",
        paragraphs:["Sales"]
      },
      {
        heading: "Benefit",
        paragraphs:["By taking the time to draft a thorough solution for their situation, you'll give your future client confidence that you will meet all their needs."
                ,"Try to add specific anecdotes about things that matter to them; for example, '… and since your dog is getting older, we'll ensure the steps from the back door have a shallow drop'."
                ]
      },
      {
        heading: "Points",
        paragraphs:["10"]
      },
    ];
  };

const SalesState = function () {
  this.title = "Sales";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Sales"]
    },
    {
      heading: "Contact",
      paragraphs:[
        "Pretty simple, this is likely you or the person in charge of making the deal."
      ]
    },
    {
      heading: "Job Code",
      paragraphs:[
        "This can be helpful to easily identify this Bid in your list of Bids and if it becomes a Job, this value will transfer over to be easily found. Also, if you do many jobs for one client, it'll be nice to have different names to refer to each job when looking up the client history."
      ]
    },
    {
      heading: "Introduction",
      paragraphs:[
        "By spending the time to documenting your understanding of their wants or needs you give this bid a personal touch. It shows you were listening and that you care about “their” project. As well, you can describe the work you intend to do along with any specific anecdotes heard during you conversations. The introduction can be a way to explain both the Situation and Solution in one area."
      ]
    },
    {
      heading: "Situation",
      paragraphs:[
        "By documenting your understanding of their wants or needs you give this bid a personal touch. It shows you were listening and that you care about “their” project. As well, you can add any critical points or issues they stressed; this will give them comfort that you have everything handled."
      ]
    },
    {
      heading: "Solution",
      paragraphs:[
        "By taking the time to draft a thorough solution for their situation, you'll give more future client confidence that you will meet all their needs. Try to add specific anecdotes about things that matter to them; for example, … and since your dog is getting older, we will ensure the step from the back door have a shallow drop."
      ]
    },
    {
      heading: "Points",
      paragraphs:["2 per row"]
    },
  ];
};

const TasksState = function () {
  this.title = "Tasks";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Job Tasks"]
    },
    {
      heading: "Benefit",
      paragraphs:[
        "By detailing out each part of your labor, this gives your customer peace of mind; especially if they are sensitive to price",
        "It also looks very professional and allows them to compare against other bids... and if other bids aren't detailed out you'll stand a better chance!",
        "Note: rows can be moved by dragging the dashes at leftmost of the row."
      ]
    },
    {
      heading: "Points",
      paragraphs:["2 per row"]
    },
  ];
};


const IGeneralDate = function () {
  this.title = "Date";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["General Information"]
    },
    {
      heading: "Benefit",
      paragraphs:["Simply the date of the invoice."
      ]
    },
    {
      heading: "Points",
      paragraphs:["1"]
    },
  ];
};

const IGeneralDue = function () {
  this.title = "Due";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["General Information"]
    },
    {
      heading: "Benefit",
      paragraphs:["The date payment is due for this invoice."
              ,"The system will provide warnings and reminders for invoices past due based on this date."
      ]
    },
    {
      heading: "Points",
      paragraphs:["3"]
    },
  ];
};

const IGeneralClient = function () {
  this.title = "Client";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["General Information"]
    },
    {
      heading: "Benefit",
      paragraphs:["A critical selection for an invoice!"
      ,"* Select this first as it:"
      ,"Determines which Jobs can be selected, if any."
      ,"Determines which Time and Materials can be applied to the invoice, if any."
      ,"Determines, along with Job selected, if the 'Auto-generate Line Items' thinger can be used."
      ]
    },
    {
      heading: "Points",
      paragraphs:["5"]
    },
  ];
};

const IGeneralJob = function () {
  this.title = "Job";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["General Information"]
    },
    {
      heading: "Benefit",
      paragraphs:["Another critical selection!",
      "Main benefits of selecting a Job:"
      ,"1. All invoices relating to one job are organized so you can track if all the work in a job has been billed and paid for!"
      ,"2. The 'Auto-generate Line Items' button can automatically produce line items based on the tasks you've setup for the job."
      ]
    },
    {
      heading: "Points",
      paragraphs:["10"]
    },
  ];
};

const ILineItemsAdd = function () {
  this.title = "Add Item";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Line Items"]
    },
    {
      heading: "Benefit",
      paragraphs:["Add a new manual entry to the invoice."
              ,"You'll add a description, quantity and the price."
              ,""
              ,"* To EDIT this value after saving, click on the row it made."
      ]
    },
    {
      heading: "Points",
      paragraphs:["5 per row"]
    },
  ];
};

const ILineItemsAddTAndM = function () {
  this.title = "Add Time & Materials";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Line Items"]
    },
    {
      heading: "Benefit",
      paragraphs:["Import Time and/or Materials (Receipts)"
              ,"You will only see Time and Receipt records based on the Client or Job you chose."
              ,"If they don't appear, check the Client or Job and then the master list of Time or Receipts from the Main Menu."
              ,"* To EDIT this value after saving, click on the row it made."
      ]
    },
    {
      heading: "Points",
      paragraphs:["5 per row"]
    },
  ];
};

const ILineItemsAutoGenerate = function () {
  this.title = "Title Here";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Line Items"]
    },
    {
      heading: "Benefit",
      paragraphs:["If tasks are setup for a Job, this button will automatically import those tasks as line items."
              ,"If no line items appear, ensure the Job selected has Job Tasks in the Jobs page."
              ,"* To EDIT this value after saving, click on the row it made."
      ]
    },
    {
      heading: "Points",
      paragraphs:["5 per row"]
    },
  ];
};

const IPaymentsAdd = function () {
  this.title = "Add Payment";
  this.sections = [
    {
      heading: "Section",
      paragraphs:["Line Items"]
    },
    {
      heading: "Benefit",
      paragraphs:["The most important part, getting paid!"
                ,"Record all your payments tied to this invoice here; they'll show in the Summary below."
                ,"Adding a note is handy as you may forget details around an odd payment amount... with a note you'll never forget!"
                ,"* To EDIT this value after saving, click on the row it made."

      ]
    },
    {
      heading: "Points",
      paragraphs:["10 per row"]
    },
  ];
};
