import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'

/* eslint-disable */
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import 'typeface-roboto'
import './index.css'
import './test.css'
import 'utils/faIcons'

import App from './components/App'

import store from 'store'
import { startEditAdmin } from 'store/actions/admin'
import { startChangeEmail, startEditUserProfile } from 'store/actions/account'
import { startEditWebsiteRoot } from 'store/actions/website'
import { startEditSubscription } from 'store/actions/subscription'
import { analytics, AnalyticsProvider } from 'analytics'
import registerServiceWorker, { unregister } from './registerServiceWorker'
import { firebase } from './firebase'
const theme = createMuiTheme({
  zIndex: {
    overlay: 1250,
    drawer: 1260,
    //drawer: 1360,
    appBar: 1370,
    menu: 1450,
    tooltipAction: 1550
  },
  drawer: {
    openWidth: 220,
    closedWidth: 70
  }
});

window.addEventListener('error', function(event) {
  const { colno, error, filename, lineno, message, path } = event
  let description = ''
  let url = ''

  try {
    url = path[0].location.href
  } catch (error) {
    url = "could not resolve path"
  }

  try {
    description = `[message:${message}] [url:${url}] [filename:${filename}] [lineno: ${lineno}] [colno: ${colno}] [error: ${error}]`
    analytics.exception(description, null, true)
  } catch (error) {
    console.log("could not send analytics", error)
  }
})

//throw "testing params exception"

firebase.auth.getRedirectResult().then(function(result) {
  if (result.credential && result.user) {
    const credential = result.credential
    const user = result.user
    const {
      email,
      name
    } = result.additionalUserInfo.profile
    const updates = { username: name }
    if(email) {
      updates.email = email
    }

    store.dispatch(startEditUserProfile(user.uid, updates))
    .then(() => {
      if (email) {
        return store.dispatch(startEditWebsiteRoot({ email: email }))
      }
    })
    .then(() => store.dispatch(startEditSubscription({ isNotTmpAccount: true })))
    .then(() => {
      const state = store.getState()
      const organization = state.accountState.organizations[0]
      const oid = organization ? organization.id : undefined
      const uid = state.accountState.user.id

      if (oid && uid) {
        return (
          fetch(`${getHostingURL()}/email/sendWelcome`, {
            mode: 'no-cors',
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              oid,
              uid,
              token: "LaST18dswfD37VCwVjSsgiselDIDqwwq18iXTvRv"
            })
          })
        )
      } else {
        console.log("no oid or uid, could not send welcome email")
        analytics.exception(`index::getRedirectResult [message:no oid or uid, could not send welcome email]`)
      }

    })
  }
})
.then(() => {
  store.dispatch({ type: 'HAS_REDIRECTED', value: true })
})
.catch(error => {
  console.log("redirect result error:", error)
  analytics.exception(`index::getRedirectResult [message:${error.message}]`)
  store.dispatch(startEditAdmin({
    trialSignUp: true,
    trialSignUpData: {
      error: error,
      isFBRedirectError: true,
    }
  }))
  store.dispatch({ type: 'HAS_REDIRECTED', value: true })
})


//console.log("THEME:", theme);
//TEST STRIPE KEY
//<StripeProvider apiKey="pk_test_moqegZTXGUun5mrSg5DBdyOl006hwXcvUt">
//live stripe key
//<StripeProvider apiKey="pk_live_ycSPTFQNIOuo6JtcjtCrkbjc001azkbjbW">
ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey="pk_live_ycSPTFQNIOuo6JtcjtCrkbjc001azkbjbW">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <AnalyticsProvider analytics={analytics}>
            <App />
          </AnalyticsProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
)



registerServiceWorker()
//unregister();
