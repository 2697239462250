import { db, storage } from '../../firebase'
import uuid from 'uuid'
import { analytics } from 'analytics'

export const setHeroImages = (heroImages) => ({
  type: 'SET_HERO_IMAGES',
  heroImages,
})

export const startAddHeroImage = (fileData) => dispatch => {
  const storageId = uuid.v4()
  return storage.uploadHeroImage(storageId, fileData)
    .then(() => db.createHeroImage({ storageId: storageId, fileName: fileData.name }))
    .catch(error => {
      console.log("ERROR startAddHeroImage:", error)
      analytics.exception(`heroImages::startAddHeroImage [message:${error.message}]`)
    })
}

export const startDeleteHeroImage = (image) => dispatch => {
  return storage.deleteHeroImage(image.storageId)
    .then(() => db.deleteHeroImage(image.id))
    .catch(error => {
      console.log("ERROR startDeleteHeroImage", error)
      analytics.exception(`heroImages::startDeleteHeroImage [message:${error.message}]`)
    })
}


/*SCHEMA
{
  id: "anchor",
  name: "Anchor",
  themeProperties: [
    {
      themes: ["butterfly", "vlava"],
      opacity: {
        start: 1,
        end: 1,
      }
    },
    {
      themes: ["bethany"],
      opacity: {
        start: 0,
        end: 0,
      }
    }
  ],
  downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fanchor.jpg?alt=media&token=775120e3-cfba-40bb-bb58-1ae91bce9c2d",
},
*/
export const startSetHeroImages = () => {
  return (dispatch) => db.getHeroImages().on('value', (snapshot) => {
    const promises = []
    const _heroImages = []
    snapshot.forEach((childSnapshot) => {
      const heroImage = { id:childSnapshot.key, ...childSnapshot.val()}
      const promise = storage.getHeroImageURL(heroImage.storageId)
        .then(result => {
          heroImage.downloadURL = result
          heroImage.isCustom = true
          _heroImages.push(heroImage)
          return result
        })
        .catch(error => {
          console.log("error getHeroImageURL:", error)
          analytics.exception(`heroImages::startSetHeroImages [message:${error.message}]`)
        })

      promises.push(promise)
    })

    return Promise.all(promises).then(() => dispatch(setHeroImages([...heroImages, ..._heroImages])))
  })
}


const heroImages = [
  {
    id: "anchor",
    name: "Anchor",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fanchor.jpg?alt=media&token=775120e3-cfba-40bb-bb58-1ae91bce9c2d",
  },
  {
    id: "aurora",
    name: "Aurora",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FauroraBorealis.jpg?alt=media&token=8b074424-cdac-4c52-8212-6921518a591b",
  },
  {
    id: "aurora2",
    name: "Aurora2",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FauroraBorealis2.jpg?alt=media&token=b47e987b-b718-452d-9ed2-c508b261297d",
  },
  {
    id: "backgroundelk",
    name: "Background Elk",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbackgroundElk.jpg?alt=media&token=c62b7efe-6501-4b06-a151-a40dd673a505",
  },
  {
    id: "bambooshoots",
    name: "Bamboo Shoots",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbambooShoots.jpg?alt=media&token=c29f48e1-296e-4c7e-ab06-463606614213",
  },
  {
    id: "books",
    name: "Books",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fbooks.jpg?alt=media&token=07ff11c4-e84f-408f-aec4-923bfd841538",
  },
  {
    id: "brickWall",
    name: "Brick Wall",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbrickWall.jpg?alt=media&token=897f0fda-108f-4838-b2dc-82be4e7e9ac3",
  },
  {
    id: "canoe",
    name: "Canoe",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fcanoe.jpg?alt=media&token=a5bdc7fe-647f-4075-9ebb-210558847ab5",
  },
  {
    id: "canyon",
    name: "Canyon",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fcanyon.jpg?alt=media&token=1326926d-cdbd-4339-bbe6-7fc8d96caacb",
  },
  {
    id: "cardinalwaving",
    name: "Cardinal Waving",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcardinalWaving.jpg?alt=media&token=ad712437-b0fc-40d6-83d0-2d2605559b4f",
  },

  {
    id: "drywall",
    name: "Drywall",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fdrywall.jpg?alt=media&token=56f9965a-ea0c-4bf9-9e41-efcfb06e2047",
  },

  {
    id: "anatomyskinbones",
    name: "Anatomy Skin Bones",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FanatomySkinBones.jpg?alt=media&token=9c8c4646-1b01-41b5-9fdd-2da25b07378f",
  },
  {
    id: "antiques",
    name: "Antiques",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fantiques.jpg?alt=media&token=b65bcea9-76f1-440d-b7ec-7fe8c6dc4ba3",
  },
  {
    id: "arboristdark",
    name: "Arborist Dark",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FarboristDark.jpg?alt=media&token=21af1748-d61c-406b-a301-19b4d1084ac8",
  },


  {
    id: "arrangement",
    name: "Arrangement",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Farrangement.jpg?alt=media&token=afa989ae-d3e1-4864-b4a1-5033f907a479",
  },

  {
    id: "babyfeet",
    name: "Baby Feet",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbabyFeet.jpg?alt=media&token=3d9b0efa-bcd8-4ca5-a20b-b75a08396799",
  },

  {
    id: "barbertools",
    name: "Barber Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbarberTools.jpg?alt=media&token=e458a116-f4e1-420c-83f3-2ba7c44d4aaa",
  },
  {
    id: "beekeeper",
    name: "Bee Keeper",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbeeKeeper.jpg?alt=media&token=db7ad18a-ff25-4495-aa27-f0cb61f463fe",
  },

  {
    id: "bikerepair",
    name: "Bike Repair",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbikeRepair.jpg?alt=media&token=f21440b9-c8f1-4515-aa18-71c1ddee07fb",
  },

  {
    id: "blacksmithblade",
    name: "Blacksmith Blade",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FblacksmithBlade.jpg?alt=media&token=e2b2fb67-2685-4282-ba42-0be49dc157b9",
  },
  {
    id: "blacksmithtools",
    name: "Blacksmith Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FblacksmithTools.jpg?alt=media&token=e36bfe7f-54bc-428e-8d44-939b11a09877",
  },

  {
    id: "bookkeeping",
    name: "Book Keeping",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fbookkeeping.jpg?alt=media&token=6cb581f9-7ff9-4edb-9bed-11613643faf6",
  },

  {
    id: "breadbasket",
    name: "Bread Basket",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbreadInBaskets.jpg?alt=media&token=7b99d2b1-17f5-4fb4-956e-557bfd8ff66a",
  },
  {
    id: "buildfurniture",
    name: "Build Furniture",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbuildFurniture.jpg?alt=media&token=00ccc78e-2e58-45dd-a490-73f5af9272ed",
  },
  {
    id: "cameraequipment",
    name: "Camera Equipment",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcameraEquipment.jpg?alt=media&token=ebdb5096-1076-4620-8d4d-05af1ad1f2a0",
  },
  {
    id: "cameratools",
    name: "Camera Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcameraTools.jpg?alt=media&token=48fa97a8-99d8-41f0-8e4e-3a82b064ead9",
  },
  {
    id: "cardetailing",
    name: "Car Detailing",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcarDetailing.jpg?alt=media&token=f993c0c0-eda4-4095-90cb-a07dce64bd16",
  },
  {
    id: "carpetcleaning",
    name: "Carpet Cleaning",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcarpetCleaning.jpg?alt=media&token=60f7776c-8771-42f8-85aa-e44d46ea7fd3",
  },
  {
    id: "caterdesign",
    name: "Cater Design",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcaterDesign.jpg?alt=media&token=89582bae-b873-41dd-aee2-485775673cc3",
  },
  {
    id: "catertablesetting",
    name: "Cater Table Setting",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcaterTableSetting.jpg?alt=media&token=7787303b-7c67-49a3-9918-27d7ad609531",
  },
  {
    id: "cateringtable",
    name: "Catering Table",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcateringTable.jpg?alt=media&token=b082b7f9-3a24-4ed0-b3bf-d2dace4a768e",
  },
  {
    id: "clothingalterations",
    name: "Clothing Alterations",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FclothingAlterations.jpg?alt=media&token=a62f68c6-433d-4eaf-bdea-3c9ecc5a3725",
  },
  {
    id: "computerrepair",
    name: "Computer Repair",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FcomputerRepair.jpg?alt=media&token=0d471971-c3b4-4201-9568-082f286f06ea",
  },
  {
    id: "concretework",
    name: "Concrete Work",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FconcreteWork.jpg?alt=media&token=7027e821-cefe-4977-84df-7bf2d5ec3fe3",
  },
  {
    id: "daycare",
    name: "Daycare",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FdayCare.jpg?alt=media&token=eef46b67-c909-42fe-89c1-8ab5212ccf03",
  },
  {
    id: "daycareplay",
    name: "Daycare Play",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FdayCarePlay.jpg?alt=media&token=7068693b-2c8f-4127-8024-100850b54a5f",
  },
  {
    id: "design",
    name: "Design",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fdesign.jpg?alt=media&token=88adc898-5eca-480e-ac9e-07b91f003661",
  },
  {
    id: "dj",
    name: "DJ",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fdj.jpg?alt=media&token=2b924d0a-b387-4d32-ba8a-4363e862da77",
  },
  {
    id: "dogwalking",
    name: "Dog Walking",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FdogWalking.jpg?alt=media&token=49215a66-4c9d-437c-888d-80acabcadb51",
  },
  {
    id: "editingwriting",
    name: "Editing Writing",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FeditingWriting.jpg?alt=media&token=b2eacef0-19ce-48f6-b4b8-89d78cd7c14c",
  },
  {
    id: "electricaltools",
    name: "Electrical Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FelectricalTools.jpg?alt=media&token=f0c6201f-5498-40e3-9b95-906c9b6b2f68",
  },
  {
    id: "excavatorRim",
    name: "Excavator Rim",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FexcavatorRim.jpg?alt=media&token=9b34c1c3-50b9-42e4-a606-446976bb979b",
  },
  {
    id: "fancyfence",
    name: "Fancy Fence",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FfancyFence.jpg?alt=media&token=e5b14f86-d8e9-4994-9854-4490ede1fced",
  },
  {
    id: "fence",
    name: "Fence",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Ffence.jpg?alt=media&token=5fb18221-1acc-4d55-a8fd-9752d63b1745",
  },
  {
    id: "figurine",
    name: "Figurine",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Ffigurine.jpg?alt=media&token=b8bc4191-0307-4319-be10-1e6af6a5ba3a",
  },
  {
    id: "firesprinklers",
    name: "Fire Sprinklers",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FfireSprinklers.jpg?alt=media&token=352c1fd9-a5a5-4a77-a556-7fefcf73b9b6",
  },
  {
    id: "flooring",
    name: "Flooring",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fflooring.jpg?alt=media&token=7ec117a9-59a1-48cc-9eb3-4df686434ded",
  },
  {
    id: "flowerarrangement",
    name: "Flower Arrangement",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FflowerArrangement.jpg?alt=media&token=db8577b9-334f-4fc1-ac65-1eca7fb9dba8",
  },
  {
    id: "framingservices",
    name: "Framing Services",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FframingServices.jpg?alt=media&token=77b3e840-5b69-4c62-9645-54e970606303",
  },
  {
    id: "furniturerefinishing",
    name: "Furniture Refinishing",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FfurnitureRefinishing.jpg?alt=media&token=0cfe2c84-167f-4dfa-956a-08a3bc240a8c",
  },
  {
    id: "gears",
    name: "Gears",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fgears.jpg?alt=media&token=c9275f18-4635-464c-96e0-1b6e8495e18c",
  },
  {
    id: "giftbasket",
    name: "Gift Basket",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FgiftBasket.jpg?alt=media&token=2087dd18-0bc0-49fc-adfd-dd717de12c04",
  },
  {
    id: "glazier",
    name: "Glazier",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fglazier.jpg?alt=media&token=7e0a717d-ebd8-4091-9e6e-2d65092a35d4",
  },
  {
    id: "glazier2",
    name: "Glazier2",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fglazier2.jpg?alt=media&token=5fcdcf51-bef5-4f26-a981-64214e410f9d",
  },
  {
    id: "hairdresser",
    name: "Hairdresser",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fhairdresser.jpg?alt=media&token=cf0ff4af-a1fc-40d6-93ac-3e7679f3b83e",
  },
  {
    id: "happybirthdaychild",
    name: "Happy Birthday Child",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FhappyBirthdayChild.jpg?alt=media&token=bb0f07dd-a596-43b2-8c0b-6f9099593557",
  },
  {
    id: "health",
    name: "Health",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fhealth.jpg?alt=media&token=3ab15c82-74f0-41e0-97e3-267030988cb9",
  },
  {
    id: "heavyequipment",
    name: "Heavy Equipment",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FheavyEquipment.jpg?alt=media&token=1a180d56-85b1-4992-bdb0-a80358c96d4e",
  },
  {
    id: "henna",
    name: "Henna",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fhenna.jpg?alt=media&token=bd54a01d-5f1a-41c3-b6c5-3bab24cd1bd5",
  },
  {
    id: "homestaging",
    name: "Home Staging",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FhomeStaging.jpg?alt=media&token=5eeb961d-9278-46a3-b11a-b2902d98d268",
  },
  {
    id: "homestaging2",
    name: "Homestaging2",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FhomeStaging2.jpg?alt=media&token=8908c9df-85eb-4856-96c5-936dc0798357",
  },
  {
    id: "hometheaterservice",
    name: "Home Theater Service",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FhomeTheatreServices.jpg?alt=media&token=c5b92483-e51f-4c25-a3f6-2fdeddc2521d",
  },
  {
    id: "honeyjars",
    name: "Honey Jars",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FhoneyJars.jpg?alt=media&token=b18140f9-464f-465f-a3ed-98354fd88792",
  },
  {
    id: "horseshoe",
    name: "Horseshoe",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fhorseshoe.jpg?alt=media&token=dd1e3e40-8d7d-443d-a464-f6b380d5e056",
  },
  {
    id: "inspection",
    name: "Inspection",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Finspection.jpg?alt=media&token=a5777e3c-abb6-44ed-881e-c4daf2cab9e5",
  },
  {
    id: "junkremoval",
    name: "Junk Removal",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FjunkRemoval.jpg?alt=media&token=d80510c3-8430-488e-a10c-87ae67354a42",
  },
  {
    id: "lens",
    name: "Lens",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Flens.jpg?alt=media&token=9587d748-e269-4379-8595-f4ad56c341c0",
  },
  {
    id: "locksmith",
    name: "Locksmith",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Flocksmith.jpg?alt=media&token=2f0e9fda-7c26-4979-815e-60f602baf31c",
  },
  {
    id: "makeupbrushes",
    name: "Makeup Brushes",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmakeupBruhses.jpg?alt=media&token=94d5a2fc-3515-4b0b-a185-8ce759d108fd",
  },
  {
    id: "masoncastle",
    name: "Mason Castle",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmasonCastle.jpg?alt=media&token=1cfbae79-9229-4702-88aa-158cbb7538ff",
  },
  {
    id: "metal",
    name: "Metal",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fmetal.jpg?alt=media&token=af9b2326-f2bf-4542-a7b2-a4c2f23915b9",
  },
  {
    id: "mooncastle",
    name: "Moon Castle",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmoonCastle.jpg?alt=media&token=43ba89a9-92ed-4ade-897c-afbd7220d5c2",
  },
  {
    id: "mopfloor",
    name: "Mop Floor",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmopFloor.jpg?alt=media&token=24fb8c5c-36a0-4655-abe0-3b3eafeef99b",
  },
  {
    id: "musiclessons",
    name: "Music Lessons",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmusicLessons.jpg?alt=media&token=56985abb-f54c-4519-845e-7c60f41ee57e",
  },
  {
    id: "officework",
    name: "Office Work",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FofficeWork.jpg?alt=media&token=ffd04fda-b247-4962-a9fc-f58f9e1fcf97",
  },
  {
    id: "party",
    name: "Party",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fparty.jpg?alt=media&token=bf275042-4b15-4e62-8a39-796cc193dba6",
  },
  {
    id: "personalchef",
    name: "Personal Chef",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FpersonalChef.jpg?alt=media&token=00deed31-bbfc-42be-bb54-b42d0233bed1",
  },
  {
    id: "personaltrainer",
    name: "Personal Trainer",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FpersonalTrainer.jpg?alt=media&token=e40000ca-29c9-43a8-8e73-3e6640cd0d09",
  },
  {
    id: "personaltrainer2",
    name: "Personal Trainer2",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FpersonalTrainer2.jpg?alt=media&token=cb311bfb-baa3-4917-b5f1-11e0d7b20df1",
  },
  {
    id: "pestremoval",
    name: "Pest Removal",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FpestRemoval.jpg?alt=media&token=acb10dce-6f5e-4c0d-8074-beb6327c86f3",
  },
  {
    id: "petgrooming",
    name: "Pet Grooming",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FpetGrooming.jpg?alt=media&token=3858be12-fd21-4665-a324-70038785871e",
  },
  {
    id: "physiology",
    name: "Physiology",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fphysiology.jpg?alt=media&token=71fb97f6-182a-4f99-b337-11f9f2b8a0fb",
  },
  {
    id: "pinktopurplesky",
    name: "Pink To Purple Sky",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FpinkToPurpleSky.jpg?alt=media&token=bf5e2365-3582-48e6-82ba-96586a26225e",
  },
  {
    id: "pipes",
    name: "Pipes",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fpipes.jpg?alt=media&token=11c3c3ab-656e-4d4b-ae89-5e01f0a2d8bd",
  },
  {
    id: "plumbingfacet",
    name: "Plumbing Facet",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FplumbingFaucet.jpg?alt=media&token=ec008bda-faae-4eec-8ebb-9a1e7a279d30",
  },
  {
    id: "repairelectronics",
    name: "Repair Electronics",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FrepairElectronics.jpg?alt=media&token=6acd4f80-9492-425b-a85a-6019b7ff16f3",
  },
  {
    id: "sidinginstall",
    name: "Siding Install",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FsidingInstall.jpg?alt=media&token=551831ab-53a2-46a8-b438-7db3e01c6c9f",
  },
  {
    id: "smallenginefrog",
    name: "Small Engine Frog",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FsmallEngineFrog.jpg?alt=media&token=70d71936-b122-4b07-a7a9-7b981dc2cab8",
  },
  {
    id: "tailor",
    name: "Tailor",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Ftailor.jpg?alt=media&token=5ffd9e6b-5b8a-497a-8578-40b39bd4fd62",
  },
  {
    id: "tatooist",
    name: "Tatooist",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Ftatooist.jpg?alt=media&token=1ba51b2c-f5c5-487c-bd50-521323d4695f",
  },
  {
    id: "taxservices",
    name: "Tax Services",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FtaxServices.jpg?alt=media&token=d8b5363a-e2ed-4e57-a5ca-c4dc7f34d15d",
  },
  {
    id: "tiling",
    name: "Tiling",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Ftiling.jpg?alt=media&token=74c374e2-ec2c-4525-b99c-1738e40a6f2d",
  },
  {
    id: "translation",
    name: "Translation",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Ftranslation.jpg?alt=media&token=63b79315-264c-4073-9b2b-8285d7b1980f",
  },
  {
    id: "walloftools",
    name: "Wall of Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FwallOfTools.jpg?alt=media&token=125b1195-3530-48a5-bda9-f5fe5fc5b09b",
  },
  {
    id: "woodworking",
    name: "Woodworking",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FwoodWorking.jpg?alt=media&token=cb550bff-9f4b-4fe9-9a38-bb34c4ae2bd8",
  },
  {
    id: "xrayok",
    name: "Xray OK",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FxrayOK.jpg?alt=media&token=c9ecc61c-10a5-4dd8-bb34-b959943c9be0",
  },

  {
    id: "artbooklowlight",
    name: "Art Book Low Light",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FartBookLowLight.jpg?alt=media&token=42e11daf-7762-4022-ae46-a71b1d7907fc",
  },
  {
    id: "bathtubFaucet",
    name: "Bathtub Faucet",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FbathtubFaucet.jpg?alt=media&token=92ebf61b-8ff1-4b52-9fac-504298b0c6a0",
  },
  {
    id: "fireworkBig",
    name: "Firework Big",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FfireworkBig.jpg?alt=media&token=81c6291c-fd21-4f5a-a5a0-864007691841",
  },
  {
    id: "fireworkSmall",
    name: "Firework Small",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FfireworkSmall.jpg?alt=media&token=fc044a44-3410-4789-b776-3160fb5aa56d",
  },
  {
    id: "flowerShine",
    name: "Flower Shine",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FflowerShine.jpg?alt=media&token=8a0f4c09-75da-4eaf-ad8a-7b852eb48e10",
  },
  {
    id: "frogSpeckled",
    name: "Frog Speckled",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FfrogFreckled.jpg?alt=media&token=887a79b3-6d90-4426-9292-948eb1c72451",
  },
  {
    id: "greenGlowNightSky",
    name: "Green Glow Night Sky",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FgreenGlowNightSky.jpg?alt=media&token=2bfa1ca3-13de-469e-929d-e9359f21aea5",
  },
  {
    id: "guitarHappy",
    name: "Guitar Happy",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FguitarHappy.jpg?alt=media&token=a4a3fada-5cd3-4f65-a8e5-3e006b4c656f",
  },
  {
    id: "gutter",
    name: "Gutter",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fgutter.jpg?alt=media&token=07fcfa3c-b1d2-463d-843f-905e48f7fa9a",
  },
  {
    id: "icyLakeShore",
    name: "Icy Lake Shore",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FicyLakeShore.jpg?alt=media&token=31fcbcf3-d4b6-49b0-901c-810488274b46",
  },
  {
    id: "ingredients",
    name: "Ingredients",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fingredients.jpg?alt=media&token=4805e788-ebcc-4146-8685-436b0e6e6c14",
  },
  {
    id: "irrigation",
    name: "Irrigation",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Firrigation.jpg?alt=media&token=f85ae47a-5a21-4d0e-b211-32abee35e2da",
  },
  {
    id: "lakeLouise",
    name: "Lake Louise",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FlakeLouise.jpg?alt=media&token=77055812-a424-4b45-9a86-57cea2b945bd",
  },
  {
    id: "largeFlowers",
    name: "Large  Flowers",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FlargeFlowers.jpg?alt=media&token=dbc51e75-9769-4be7-828b-b610c1a35b3a",
  },
  {
    id: "level",
    name: "Level",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Flevel.jpg?alt=media&token=83337d11-2424-4092-b163-f69db97c6cfa",
  },
  {
    id: "liftOff",
    name: "Lift Off",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FliftOff.jpg?alt=media&token=039adcbe-17e3-42ad-ba15-c20193c81638",
  },
  {
    id: "lonePeak",
    name: "Lone Peak",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FlonePeak.jpg?alt=media&token=445443ae-8cb9-4864-8649-fbb2f948841c",
  },
  {
    id: "makeupBrushes2",
    name: "Makeup Brushes2",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmakeupBruhses.jpg?alt=media&token=94d5a2fc-3515-4b0b-a185-8ce759d108fd",
  },
  {
    id: "mapleLeafOverSunRays",
    name: "Maple Leaf Over Sun Rays",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmapleLeafOverSunRays.jpg?alt=media&token=3fdfeeb0-6576-4253-9495-784d41234d99",
  },
  {
    id: "massage",
    name: "Massage",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fmassage.jpg?alt=media&token=c64cb299-fbd0-40b4-8659-e9e1c7197208",
  },
  {
    id: "moonOnBlack",
    name: "Moon On Black",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmoonOnBlack.jpg?alt=media&token=25bcb3af-1797-4949-bf38-346471b6073e",
  },
  {
    id: "mysticMountainTop",
    name: "Mystic Mountain Top",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FmysticMountainTop.jpg?alt=media&token=b1547050-c0dc-468a-a9dd-5762c6b11c81",
  },
  {
    id: "oceanPortal",
    name: "Ocean Portal",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FoceanPortal.jpg?alt=media&token=b911fb9c-04a6-4794-91bb-7d43b638cb1d",
  },
  {
    id: "officeFunky",
    name: "Office Funky",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FofficeFunky.jpg?alt=media&token=d5fb0720-2c69-4227-8105-1eb577037361",
  },
  {
    id: "oldCookingTools",
    name: "Old Cooking Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FoldCookingTools.jpg?alt=media&token=cb3a99f3-f07d-4f68-afbe-a8e85f5841c8",
  },
  {
    id: "oldOfficeEquipment",
    name: "Old Office Equipment",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FoldOfficeEquipment.jpg?alt=media&token=5f79a38c-6301-4f1c-9e1d-f8079adf0f9f",
  },
  {
    id: "oldTools",
    name: "Old Tools",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FoldTools.jpg?alt=media&token=78506646-4c4c-4c5e-83e4-608677d1c028",
  },
  {
    id: "ostrichUnderPinkSky",
    name: "Ostrich Under Pink Sky",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2FostrichUnderPinkSky.jpg?alt=media&token=2869dde2-7739-4b53-a6f4-80bf09ad8e44",
  },
  {
    id: "roofing",
    name: "Roofing",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Froofing.jpg?alt=media&token=e8eb1507-1caf-4b8c-96e7-54ce09ddeff8",
  },
  {
    id: "welding",
    name: "Welding",
    downloadURL: "https://firebasestorage.googleapis.com/v0/b/couraggo.appspot.com/o/website%2Fhero%2Fwelding.jpg?alt=media&token=d1545e11-9579-49ab-adbb-f42b47452363",
  },
]
