import { db, oid } from '../firebase'

export const getWebsiteRoot = () =>
  db.ref(`appData/${oid}/website/root`)

export const getWebsiteServices = () =>
  db.ref(`appData/${oid}/website/services`)

export const getWebsiteTestimonials = () =>
  db.ref(`appData/${oid}/website/testimonials`)

export const getWebsitePortfolio = () =>
  db.ref(`appData/${oid}/website/portfolio`)

export const getWebsiteFAQS = () =>
  db.ref(`appData/${oid}/website/faqs`)

export const createWebsiteFAQ = (faq) =>
  db.ref(`appData/${oid}/website/faqs`)
    .push(faq)

export const updateWebsiteFAQ = (id, updates) =>
  db.ref(`appData/${oid}/website/faqs/${id}`)
    .update(updates)

export const deleteWebsiteFAQ = (id) =>
  db.ref(`appData/${oid}/website/faqs/${id}`)
    .remove()

/*
BEGIN TEAM
*/
export const getWebsiteTeam = () =>
  db.ref(`appData/${oid}/website/team`)

export const updateWebsiteTeam = (updates) =>
  db.ref(`appData/${oid}/website/team`)
    .update(updates)

export const createWebsiteTeamMember= (teamMember) =>
  db.ref(`appData/${oid}/website/team`)
    .push(teamMember)

export const deleteWebsiteTeamMember = (id) =>
  db.ref(`appData/${oid}/website/team/${id}`)
  .remove()

export const updateWebsiteTeamMember = (id, updates) =>
  db.ref(`appData/${oid}/website/team/${id}`)
    .update(updates)

/*
END TEAM
*/
export const updateWebsiteRoot = (updates) =>
  db.ref(`appData/${oid}/website/root`)
    .update(updates)

export const createWebsiteService = (service) =>
  db.ref(`appData/${oid}/website/services`)
    .push(service)

export const deleteWebsiteService = (id) =>
  db.ref(`appData/${oid}/website/services/${id}`)
    .remove()

export const updateWebsiteService = (id, updates) =>
  db.ref(`appData/${oid}/website/services/${id}`)
    .update(updates)

export const createWebsiteTestimonial = (testimonial) =>
  db.ref(`appData/${oid}/website/testimonials`)
    .push(testimonial)

export const deleteWebsiteTestimonial = (id) =>
  db.ref(`appData/${oid}/website/testimonials/${id}`)
  .remove()

export const updateWebsiteTestimonial = (id, updates) =>
  db.ref(`appData/${oid}/website/testimonials/${id}`)
    .update(updates)

export const createWebsitePortfolio = (portfolio) =>
  db.ref(`appData/${oid}/website/portfolio`)
    .push(portfolio)

export const deleteWebsitePortfolio = (id) =>
  db.ref(`appData/${oid}/website/portfolio/${id}`)
  .remove()

export const updateWebsitePortfolio = (id, updates) =>
  db.ref(`appData/${oid}/website/portfolio/${id}`)
    .update(updates)

export const createWebsiteImages = (websiteImages) =>
  db.ref('websiteImages').set(websiteImages)

export const getWebsiteImages = () =>
  db.ref('websiteImages')

export const getHeroImages = () =>
  db.ref(`appData/${oid}/heroImages`)

export const createHeroImage = (heroImage) =>
  db.ref(`appData/${oid}/heroImages`).push(heroImage)

export const deleteHeroImage = (id) =>
  db.ref(`appData/${oid}/heroImages/${id}`).remove()


export const getWebsiteDocuments = () =>
  db.ref(`appData/${oid}/website/documents`)

export const createWebsiteDocument = (doc) =>
  db.ref(`appData/${oid}/website/documents`).push(doc)

export const deleteWebsiteDocument = (id) =>
  db.ref(`appData/${oid}/website/documents/${id}`).remove()

export const updateWebsiteDocument = (id, updates) =>
  db.ref(`appData/${oid}/website/documents/${id}`)
    .update(updates)

export const getContactImages = () =>
  db.ref('websiteImages/contact')
