import { analytics } from 'analytics'
import store from 'store'
import $ from 'jquery'

import { nextMissionStep, saveCompletedMission } from 'store/actions/help'

const helpDispatch = (type) => {
  store.dispatch(nextMissionStep())
  const { help } = store.getState()
  const currentMission = help.currentMission
  const currentStep = currentMission.steps[currentMission.currentStep]
  currentMission.currentStepVal = currentStep

  if (currentMission.currentStep > 0) {
    currentMission.prevStepVal = currentMission.steps[currentMission.currentStep -1]
  }
  if (currentMission && currentMission.id && currentMission.currentStep) {
    analytics.missionStep(`m_${currentMission.id}_step-${currentMission.currentStep}`, currentMission)
  }

  if (currentStep && currentStep.success) {
    saveCompletedMission(currentMission.id)
  }
}

export default helpDispatch
