import uuid from 'uuid';

const ClientJobs = function () {
    this.toId = (clientId, projectId) => {
      const clientJob = this.items.find((clientJob) => (
        clientJob.client.id === clientId && clientJob.project.id === projectId
      ));

      return clientJob === undefined ? "" : clientJob.id;
    }
    this.fromId = (id) => {
      const clientJob = this.items.find((clientJob) => clientJob.id === id);
      return {
        clientId: clientJob.client.id,
        projectId: clientJob.project.id,
      };
    }
};

export const selectClientJobs = (store) => {
  const clientJobs = new ClientJobs();

  const result = store.projectsState.reduce((clientProjects, project) => {
    const client = store.clientsState.find(client => client.id === project.clientId);

    if (client === undefined) {
      return clientProjects;
    } else {
      const clientJob = {
        id: uuid.v4() ,
        client: client,
        project: project,
      };

      Object.defineProperty(clientJob, 'displayValue', {
        get() {
          const jobCode = this.project.jobCode === undefined ? "" : this.project.jobCode;

          if (jobCode === ""){
            return `${client.fullName}`;
          } else {
            return `${client.fullName} - ${jobCode}`;
          }
        }
      });

      clientProjects.push(clientJob);

      return clientProjects;
    }

  }, []);

  clientJobs.items = result;
  return clientJobs;
}
