import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    position: 'relative',
    zIndex: theme.zIndex.overlay + 1,
  },
});

const OverlayElevator = ({ classes, children, help, type }) => {
  const currentMission = help.currentMission;
  const currentStepIndex = currentMission.currentStep;
  const currentStep = currentMission.steps[currentStepIndex];

  let showOverlay;

  if (!currentStep) {
    showOverlay = false;
  } else if((currentStep.primaryType === type) || (currentStep.secondaryType === type)) {
    showOverlay = true;
  } else {
    showOverlay = false;
  }

  return (
    <div className={false ? classes.root: ""}>
      {children}
    </div>
  );
}

const mapStateToProps = (state) => ({
  help: state.help,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(OverlayElevator);
