import * as auth from './auth';
import * as firebase from './firebase';
import * as functions from './functions';
import * as storage from './storage';
import * as db from './database';

export {
  auth,
  firebase,
  functions,
  storage,
  db,
};
