import BidStateFactory from './BidStateFactory';

//TODO: make factory a singleton
export default function BidState(currentState) {
  this.factory = new BidStateFactory();
  this._currentState = this.factory.createBidState(currentState);
  this._bidStates = this.factory.createBidStates(currentState);

  Object.defineProperty(this, 'isArchived', {
    get () {
      const type = this._currentState.type;

      if ( (type === "won") || (type === "lost") || (type === "cancelled") ) {
        return true;
      } else {
        return false;
      }
    }
  });

  Object.defineProperty(this, 'type', {
    get () {
      return this._currentState.type;
    }
  });

  Object.defineProperty(this, 'status', {
    get() {
      return this._currentState.status;
    }
  });

  Object.defineProperty(this, 'statusMessage', {
    get() {
      return this._currentState.statusMessage();
    }
  });

  Object.defineProperty(this, 'actions', {
    get() {
      const actions = {
        secondary: [],
      };

      this._currentState.adjacencyList.map((key, i) => {
        const state = this._bidStates.get(key);
        const action = this._bidStates.get(key).action;
        if (i === 0) {
          actions.primary = {
            displayName: action.displayName,
            state: action.state,
            actionType: state.type,
          }
        } else {
          actions.secondary.push({
            displayName: action.displayName,
            state: action.state,
            actionType: state.type,
          })
        }
      });

      return actions;
    }
  });
}
