import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';


import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';

import { startEditReceiptListFilter } from '../../../store/actions/filters';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    maringTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit*4,
  },
  addButton: {
    backgroundColor: amber[500],
    color: "#fff",
    marginLeft: theme.spacing.unit*4,
  },
  divider: {
    flexGrow: 1,
    maxWidth: '2px',
    margin: '15px 5px',
    background: grey[300],
  },
  optional: {
    marginLeft: theme.spacing.unit*3,
  }
});

const ClientsFilter = (props) => {
  const {
    classes,
    handleAdd,
  } = props;

  return(
    <div className={classes.root}>
      <Button
        className={classes.addButton}
        variant="fab"
        aria-label="add"
        onClick={handleAdd}
      >
        <AddIcon />
      </Button>
    </div>
  );
};

ClientsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bidListFilter: state.filters.bidList,
  receiptListFilter: state.filters.receiptList,
});

const mapDispatchToProps = (dispatch) => ({
  startEditReceiptListFilter: (updates) => dispatch(startEditReceiptListFilter(updates)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ClientsFilter);
