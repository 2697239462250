import React from 'react'
import { connect } from 'react-redux'
//import ReactGA from 'react-ga'

import { analytics } from 'analytics'
import { db, firebase } from '../../firebase'
import { loadStore } from 'store/actions/loader'

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      const { onSetAuthUser, onHasAuthorized, setLoaded } = this.props
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          //ReactGA.set({ userId: authUser.uid })
          db.getUserOrganizations(authUser.uid)
            .then(snapshot => {
              const organizations = []
              snapshot.forEach(childSnapshot => {
                const organization = { id: childSnapshot.key, ...childSnapshot.val() }
                organizations.push(organization)
              });
              //console.log("organizations in auth set:", organizations)
              const organization = organizations[0]
              if(organization) {
                firebase.setOID(organization.id)
                analytics.logIn(authUser, organization)
                onSetAuthUser(authUser, organization)
                loadStore()
              } else {
                console.log("organization is undefined")
                onSetAuthUser(authUser)
              }

            })
        } else {
          analytics.logOut()
          onSetAuthUser(null, null)
          setLoaded()
        }
        onHasAuthorized(true)
      })
    }

    render() {
      return (
        <Component />
      )
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser, organization) => dispatch({ type: 'AUTH_USER_SET', authUser, organization }),
    onHasAuthorized: (value) => dispatch({ type: 'HAS_AUTHORIZED', value }),
    setLoaded: (value) => dispatch({ type: 'IS_LOADING', value: false }),
  })

  return connect(null, mapDispatchToProps)(WithAuthentication)
}

export default withAuthentication
