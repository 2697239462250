import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Fade from '@material-ui/core/Fade'
import { withStyles } from '@material-ui/core/styles'

const width = '15px'
const height = '15px'
const bounce_height = '30px'

const styles = theme => ({
  '@keyframes bounce': {
    '0%': {
      top: bounce_height,
      height: '5px',
      borderRadius: '60px 60px 20px 20px',
      transform: 'scaleX(2)',
    },
    '35%': {
      height: height,
      borderRadius: '50%',
      transform: 'scaleX(1)',
    },
    '100%': {
      top: 0
    }
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    fontFamily: 'Montserrat',
  },
  wrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  text: {
    color: '#fbae17',
    display: 'inline-block',
    marginLeft: '5px',
    fontWeight: 'normal',
    fontHeight: '30px',
  },
  bounceball: {
    position: 'relative',
    display: 'inline-block',
    height: '37px',
    width: width,
    '&:before': {
      position: 'absolute',
      content: '""',
      display: 'block',
      top: 0,
      width: width,
      height: height,
      borderRadius: '50%',
      backgroundColor: '#fbae17',
      transformOrigin: '50%',
      animation: 'bounce 500ms alternate infinite ease',
    }
  }
})

class Preloader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canUnmount: false,
    }
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ canUnmount: true }) }, 2000)
  }

  render() {
    const { canUnmount } = this.state
    const { classes, onFinished } = this.props
    const { isLoading, hasAuthorized, hasRedirected } = this.props.session
    const fadeIn = (!canUnmount || isLoading || !hasAuthorized || !hasRedirected)

    if (onFinished && !fadeIn) {
      setTimeout(() => onFinished(), 1000)
    }
    return(
      <Fade in={fadeIn} timeout={{ enter: 1000, exit: 1000,}}>
        <div className={classes.root}>
          <div className={classes.wrap}>
            <div className={classes.loading}>
              <div className={classes.bounceball}></div>
              <div className={classes.text}>NOW LOADING</div>
            </div>
          </div>
        </div>
      </Fade>
    )
  }
}

const mapStateToProps = state => ({
  session: state.sessionState,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(Preloader)
