import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import backgroundColor from '@material-ui/core/colors/indigo';

import TaskTable from './TaskTable';
//import { TaskTable } from '../Tasks';

const styles = theme => ({
  root: {

  },
  expansionPanel: {
    backgroundColor: backgroundColor[200],
  },
  summaryPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  type: {
    flexGrow: 1,
    color: '#fff',
  },
  length: {
    color: '#fff',
  },
});

class TaskItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
    };
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    //const { classes, task } = this.props;
    const { classes, quote } = this.props;
    const { expanded } = this.state;

    return(
      <div className={classes.root}>
        <ExpansionPanel
          className={classes.expansionPanel}
          expanded={expanded === 'labor'}
          onChange={this.handleChange('labor')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              className={classes.type}
              variant="subheading"
              >
                Labor
            </Typography>
            <Typography
              className={classes.length}
              variant="subheading"
            >
              {quote.labor.length} Items
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <TaskTable type="labor" />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.expansionPanel}
          expanded={expanded === 'materials'}
          onChange={this.handleChange('materials')}>
          <ExpansionPanelSummary
            className={classes.summaryPanel}
            expandIcon={<ExpandMoreIcon />}>
            <Typography
              className={classes.type}
              variant="subheading"
              >
                Materials
            </Typography>
            <Typography
              className={classes.length}
              variant="subheading"
            >
              {quote.materials.length} Items
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <TaskTable type="materials" />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  quote: state.formData.quote,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(TaskItems);
