import types from '../TooltipTypes';

const bidTooltips = [
  {
    id: types.bid.ADD,
    title: "Add Bid",
    subtitle: "Click the yellow plus button or FAB to add a bid",
    type: "primary",
  },
  {
    id: types.bid.BID_INFO_PANEL,
    title: "Info",
    subtitle: "In this Info bar, keep an eye on Unsent Bids, the faster you respond the more likely you'll win.",
    type: "secondary",
  },
  {
    id: types.bid.ADD_CLIENT,
    title: "Add Client",
    subtitle: "Click the blue plus button to create and select a new client",
    type: "primary",
  },
  {
    id: types.bid.ADD_SALES_BASIC,
    title: "Add Salesy Stuff",
    subtitle: "Click the Add button and select the BASIC option.",
    type: "primary",
  },
  {
    id: types.bid.ENTER_INFO,
    title: "Enter Salesy Stuff",
    subtitle: "Enter the info and click NEXT",
    type: "primary",
    nextDispatch: types.bid.ADD_TASK_SIMPLE,
  },
  {
    id: types.bid.SALES_SECTION,
    title: "Sales Section",
    subtitle: "The Sales Section allows you to personalize the Bid for you client. Come back later to see all the options.",
    type: "secondary",
  },
  {
    id: types.bid.ADD_TASK_SIMPLE,
    title: "Add Task",
    subtitle: "Click the add button and select simple",
    type: "primary",
  },
  {
    id: types.bid.ACTIONS_INFO,
    title: "Plus Button",
    subtitle: "Don't forget about the actions possible on our Button Bar; Duplicate is a great time saver.",
    type: "secondary",
  },
  {
    id: types.bid.ENTER_INFO,
    title: "Enter Task",
    subtitle: "Enter the info and click NEXT",
    type: "primary",
    nextDispatch: types.bid.ADD_TASK_SIMPLE,
  },
  {
    id: types.bid.ENTER_INFO_TASK,
    title: "Enter Info",
    subtitle: "Enter info and click next",
    type: "primary",
    nextDispatch: types.bid.ENTER_INFO_TASK,
  },
  {
    id: types.bid.HELP_INFO_PANEL,
    title: "Information Panel",
    subtitle: "Hey, look here after you click on Title or Estimate Amount - this tells you about each item!!",
    type: "secondary",
  },
  {
    id: types.bid.CLOSE,
    title: "Close Bid",
    subtitle: "Close the bid and return to the bid list",
    type: "primary",
  },
  {
    id: types.bid.SECTIONS,
    title: "Sections",
    subtitle: "Later on, please explore each SECTION and see what all this can do!",
    type: "secondary",
  }
];

export default bidTooltips;
