import moment from 'moment';
import { firebase, db } from '../../firebase';

export const startEditSubscription = (updates) => {
  return (dispatch) => db.updateSubscription(updates);
}

const setSubscription = (subscription) => ({
  type: 'SET_SUBSCRIPTION',
  subscription
});

export const startSubscribe = () => {
  return (dispatch) => {
    return db.updateSubscription({ isSubscribed: true })
  }
}


export const startSetSubscription = () => {
  return (dispatch) => {
    db.getSubscription().on('value', snapshot => {
      //console.log("SUBSCRIPTION:", snapshot.val());
      const subscription = snapshot.val()
        ? snapshot.val()
        : {};

      Object.defineProperty(subscription, 'trialExpired', {
        get() {

          //compare trial expiry date to current date
          return moment(subscription.trialStart).add(subscription.trialDuration, 'days') < moment();
        },
        configurable: true,
        enumerable: true
      });

      dispatch(setSubscription(subscription ? subscription : {}));
    });
  }
}
