
function MissionDirector() {
  const builders = new Map();

  return {
    buildMission: (id) => "test"
  };
}

export default { getBuilder: MissionDirector };
