import { db } from '../../../firebase';

export const editCrewForm = (updates) => ({
  type: "EDIT_CREW_FORM",
  updates
});

export const setCrewForm = (crew) => ({
  type: "SET_CREW_FORM",
  crew
});

export const resetCrewForm = () => ({
  type: "RESET_CREW_FORM"
});

export const startOnDoneCrew = () => {
  return (dispatch, getState) => {
    const { validation, ...crew } = getState().formData.crew;
    if (crew.id) {
      const { id, ...updates } = crew;
      return db.updateCrew(id, updates);
    } else {
      return db.createCrew(crew);
    }
  }
};

export const startRemoveCrew = () => {
  return (dispatch, getState) => {
    const crew = getState().formData.crew;
    return db.deleteCrew(crew.id);
  }
};
