import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import indigo from '@material-ui/core/colors/indigo';

import { startAddReceipt, startEditReceipt } from '../../../../store/actions/receipts';
import {
  startAddClient,
  startEditClient,
  startRemoveClient
} from '../../../../store/actions/clients';

const styles = theme => ({
  avatar: {
    margin: theme.spacing.unit,
    color: '#fff',
    backgroundColor: indigo[100],
  },
  card: {
    marginTop: theme.spacing.unit*3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstName: {
    marginRight: theme.spacing.unit*2,
  },
  lastName: {
    marginLeft: theme.spacing.unit*2,
  },
  homePhone: {
    marginRight: theme.spacing.unit*2,
    marginTop: theme.spacing.unit*2,
  },
  mobilePhone: {
    marginLeft: theme.spacing.unit*2,
    marginTop: theme.spacing.unit*2,
  },
  email: {
    flexGrow: 1,
    marginTop: theme.spacing.unit*2,
  },
  rootContent: {
    backgroundColor: indigo[50],
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: '100px',
  },
});

class ClientDialog extends Component {
  constructor(props) {
    super(props);
    const { clientData } = props;

    if (clientData === undefined) {
      this.state = {
        client: {
          firstName: undefined,
          lastName: undefined,
          createdDate: (new Date()).getTime(),
        },
      };
    } else {
      this.state = {
        client: {...clientData}
      };
    }

  }

  handleChange (propertyName, value) {
    this.setState(function(prevState, props){
       return {
         client: {
           ...prevState.client,
           [propertyName]: value,
         }
       }
    });
  }

  render() {
    const {
      classes,
      show,
      type,
      handleClose,
      startAddClient,
      startEditClient,
      startRemoveClient,
    } = this.props;
    const { client } = this.state;
    return(
      <div>
        <Dialog
          maxWidth="md"
          open={show}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle >
            <div className={classes.title}>
              <Avatar aria-label="Client" className={classes.avatar}>
                C
              </Avatar>
              <Typography variant="title">Client</Typography>
            </div>
          </DialogTitle>
          <DialogContent classes={{root: classes.rootContent}}>
            <Card className={classes.card}>
              <CardContent className={classes.content}>
                <div>
                  <TextField
                    className={classes.firstName}
                    label="First Name"
                    margin="none"
                    value={client.firstName}
                    onChange={(event) => this.handleChange("firstName", event.target.value)}
                  />
                  <TextField
                    className={classes.lastName}
                    label="Last Name"
                    margin="none"
                    value={client.lastName}
                    onChange={(event) => this.handleChange("lastName", event.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    className={classes.homePhone}
                    label="Home Phone"
                    margin="none"
                    value={client.homePhone}
                    onChange={(event) => this.handleChange("homePhone", event.target.value)}
                  />
                  <TextField
                    className={classes.mobilePhone}
                    label="Mobile Phone"
                    margin="none"
                    value={client.mobilePhone}
                    onChange={(event) => this.handleChange("mobilePhone", event.target.value)}
                  />
                </div>
                <TextField
                  className={classes.email}
                  label="Email"
                  margin="none"
                  value={client.email}
                  onChange={(event) => this.handleChange("email", event.target.value)}
                />
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() => {
                startRemoveClient(client.id)
                  .then(() => handleClose());
              }}
            >
              Delete
            </Button>
            <div>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (type === "add") {
                    startAddClient(client)
                      .then(() => handleClose())
                  } else {
                    const { id, ...updates } = client;
                    startEditClient(id, updates)
                      .then(() => handleClose())
                  }
                }}
                color="primary">
                {type === "add" ? "Add" : "Done"}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = {
  startAddClient,
  startEditClient,
  startRemoveClient,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(ClientDialog);
