import store from 'store';
const defaultState = {
  clients: [],
};


const validateClient = (client) => {
  const errors = {};
  const requiredFields = [ 'firstName', 'lastName'];
  requiredFields.forEach(field => {
    if (!client[field]) {
      errors[field] = 'Required';
    }
  });

  if (client.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(client.email)) {
    errors.email = 'Invalid email address';
  }

  const clientValidation = { id: client.id, errors, isInvalid: !!Object.keys(errors).length };
  return clientValidation;
}

const validateClients = (clients) =>
  clients.map(client => validateClient(client));

const validateInvoice = (invoice) => {
  const errors = {};
  const requiredFields = [ 'clientId' ];
  requiredFields.forEach(field => {
    if (!invoice[field]) {
      errors[field] = 'Required';
    }
  });

  const invoiceValidation = { id: invoice.id, errors, isInvalid: !!Object.keys(errors).length };
  invoiceValidation.validateField = function (field) {
    return this.errors[field];
  }

  return invoiceValidation;
}

const validateInvoices = () => {
  const { invoicesState: invoices } = store.getState();
  return invoices.map(invoice => validateInvoice(invoice));
}

const setRequiredTaskFields = (requiredFields, errors, task) => {
  requiredFields.forEach(field => {
    if (!task[field]) {
      errors[`${field}-${task.id}`] = "Required";
    }
  })
}

const validateBid = (bid, bidTasks) => {

  const errors = {};
  const requiredFields = [ 'clientId' ];
  requiredFields.forEach(field => {
    if (!bid[field]) {
      errors[field] = 'Required';
    }
  });

  if (!bidTasks.length) {
    errors.missingTasks = 'At least one task required.';
  }

  bidTasks.forEach(task => {
    if (task.type === "maintenance"){
      const requiredFields = [
        'title', 'monthlyAmount', 'scheduleStart',
        'scheduleOccurences', 'scheduleHours'
      ];

      setRequiredTaskFields(requiredFields, errors, task);
    } else if (task.type === "simple") {
      const requiredFields = [ 'title', 'estimate' ];
      setRequiredTaskFields(requiredFields, errors, task);
    } else if (task.type === "estimate") {
      const requiredFields = [ 'title', 'estimate' ];
      setRequiredTaskFields(requiredFields, errors, task);
    } else if (task.type === "quote") {
      const requiredFields = [ 'title' ];
      setRequiredTaskFields(requiredFields, errors, task);
    }
  });

  const bidValidation = { id: bid.id, errors, isInvalid: !!Object.keys(errors).length };
  bidValidation.validateField = function (field) {
    return this.errors[field];
  }

  return bidValidation;
}

const validateBids = () => {
  const { bidsState: bids, bidTasksState: tasks } = store.getState();

  return bids.map(bid => {
    const bidTasks = tasks.filter(task => task.bidId === bid.id);
    return validateBid(bid, bidTasks);
  });
}

const validateProject = (project, projectTasks) => {
  const errors = {};
  const requiredFields = [ 'clientId', 'jobCode' ];
  requiredFields.forEach(field => {
    if (!project[field]) {
      errors[field] = 'Required';
    }
  });

  const validation = { id: project.id, errors, isInvalid: !!Object.keys(errors).length };
  validation.validateField = function (field) {
    return this.errors[field];
  }

  return validation;
}

const validateProjects = () => {
  const { projectsState: projects, projectTasksState: tasks } = store.getState();

  return projects.map(project => {
    const projectTasks = tasks.filter(task => task.projectId === project.id);
    return validateProject(project, projectTasks);
  });
}

//TODO: find a better way to handle race condition on validation,
//right now dispatches are called after accountState and orgDomains are loaded
const validateWebsiteRoot = (website) => {
  const { orgDomains, accountState } = store.getState();
  const organization = accountState.organizations[0];
  const oid = organization && organization.id;
  //console.log("validating website root accountState:", accountState);
  // console.log("VALIDATION orgDomains:", orgDomains);
  // console.log("OID:", oid);
  const { root } = website;
  const errors = {};
  const requiredFields = [
    'theme', 'companyName', 'phone', 'tagline',
    'contactName', 'email',
  ];

  requiredFields.forEach(field => {
    if (!root[field]) {
      errors[field] = 'Required';
    }
  });

  if (!root.subDomain && !root.customDomain){
    errors.subDomain = 'Either Subdomain or Custom Domain is required';
    errors.customDomain = errors.subDomain;
  }

  if (oid) {
    //check to see if the subdomain is available
    //console.log("validate website orgDomains:", orgDomains);
    orgDomains.forEach(orgDomain => {
      if(orgDomain.oid !== oid && orgDomain.subDomain === root.subDomain) {
        errors.subDomain = 'That subdomain is not available';
      }
    })
  }

  if (root.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(root.email)) {
    errors.email = 'Invalid email address';
  }

  const validation = { errors, isInvalid: !!Object.keys(errors).length };
  validation.validateField = function (field) {
    return this.errors[field];
  }

  return validation;
}

const validateWebsiteServices = (website) => {
  const { services } = website;
  const errors = {};

  const validation = { errors, isInvalid: !!Object.keys(errors).length };
  return validation;
}

const validateWebsite = () => {
  const { websiteState: website } = store.getState();
  const root = validateWebsiteRoot(website);
  const services = validateWebsiteServices(website);
  const isInvalid = (root.isInvalid || services.isInvalid);
  const validation = {
    root,
    services,
    isInvalid,
  };

  return validation;
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'VALIDATE_CLIENTS':
      return { ...state, clients: validateClients(action.clients) };
    case 'VALIDATE_INVOICES':
      return { ...state, invoices: validateInvoices() };
    case 'VALIDATE_BIDS':
      return { ...state, bids: validateBids() };
    case 'VALIDATE_PROJECTS':
      return { ...state, projects: validateProjects() };
    case 'VALIDATE_WEBSITE':
      return { ...state, website: validateWebsite() };
    default:
      return state;
  };
};
