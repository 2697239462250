import _ from 'lodash';
import moment from 'moment';

export const selectInvoice =  (invoices, { scheduleDate }) => {
  return invoices.filter((invoice) => {
    return false;
    //return project.clientKey === id;
  });
};

export const selectInvoiceStatus = (store, invoice) => {
  //console.log("invoice:", invoice);
  let name = "";
  let message = "";
  let warningLevel;

  //If there is no invoice id then this is a pending invoice
  if (!invoice.id) {
    let diffDays;
    //If there is a task id then its a maintenance so use the schedule date
    //else use the project end date.
    if (invoice.projectTaskId && invoice.scheduleDate) {
      diffDays = invoice.scheduleDate.diff(moment(), "days");
    } else {
      const project = store.projectsState.find(project => project.id == invoice.projectId);
      diffDays = moment(project.endDate).diff(moment(), "days");
    }

    if (diffDays > -2 && diffDays < 0) {
      name = "Overdue";
      message = `due ${Math.abs(diffDays)} day ago`;
      warningLevel = diffDays < -30 ? "overdue" : "warning";
    } else if (diffDays < -1) {
      name = "Overdue";
      message = `due ${Math.abs(diffDays)} days ago`;
      warningLevel = diffDays < -30 ? "overdue" : "warning";
    } else if (diffDays === 0) {
      name = "Due";
      message = "due today";
    } else if (Math.abs(diffDays) === 1) {
      name = "Pending";
      message = `due in ${Math.abs(diffDays)} day`;
    } else {
      name = "Pending";
      message = diffDays < 30 ? `due in ${Math.abs(diffDays)} days` : "";
    }

  } else if (invoice.id && invoice.dueDate) {
    if (invoice.isPaid) {
      name = "Paid";
      warningLevel = "ok";
    } else if (!invoice.statuses.sent) {
      name = "Draft";
      warningLevel = "warning";
    } else if (!invoice.isPaid) {
      const diffDays = moment(invoice.dueDate).diff(moment(), "days");
      if (diffDays > -2 && diffDays < 0) {
        name = "Overdue";
        message = `payment due ${Math.abs(diffDays)} day ago`;
        warningLevel = diffDays < -30 ? "overdue" : "warning";
      } else if (diffDays < 0) {
        name = "Overdue";
        message = `payment due ${Math.abs(diffDays)} days ago`;
        warningLevel = diffDays < -30 ? "overdue" : "warning";
      } else if (diffDays === 0) {
        name = "Due";
        message = "payment due today";
      } else if (Math.abs(diffDays) === 1) {
        name = "Outstanding";
        message = `payment due in ${Math.abs(diffDays)} day`;
      } else {
        name = "Outstanding";
        message = diffDays < 30 ? `payment due in ${Math.abs(diffDays)} days` : "";
      }
    } else if (invoice.total === 0){
      name = "Incomplete";
      message = "this invoice is missing line items";
      warningLevel = "warning";
    }
  }

  return({
    message,
    name,
    warningLevel,
  });
};

export const createSchedule = (tasks, project) => {
  const scheduledInvoices = [];
  tasks.forEach(task => {
    //If the maintenance task has a start date and number of occurences
    //then create and return an array of scheduled invoices
    if (task.scheduleStart && task.scheduleOccurences) {
      const initialMonth = moment(task.scheduleStart).endOf("month");

      for (var i = 0; i < task.scheduleOccurences; i++) {
        const scheduleDate = initialMonth.clone().add(i, "M").endOf("month");
        scheduledInvoices.push({
          projectId: project.id,
          clientId: project.clientId,
          projectTaskId: task.id,
          scheduleDate,
        });
      }
    }
  });

  return scheduledInvoices;
}

export const selectProjectInvoices = (store, project) => {
  let invoices = [];
  const projectTasks = store.projectTasksState.filter(task => task.projectId === project.id);
  const maintenanceTasks = projectTasks.filter(task => task.type === "maintenance");

  //If the job is recurring then generate schedule for missing invoices.
  //Else, see if at least one invoice exists and if not then create a pending invoice
  if (maintenanceTasks.length > 0) {
    const scheduledInvoices = createSchedule(maintenanceTasks, project);
    const pendingInvoices = [];
    invoices = store.invoicesState.filter(invoice => invoice.projectId === project.id);

    //Iterate over each scheduled invoice and check to see if an invoice already exists.
    scheduledInvoices.forEach(scheduledInvoice => {
      const createdInvoice = _.find(invoices, item => {
        //If the invoice has a project task and that task matches the scheduled task id
        //then check to see if the invoice matches the scheduled date
        const taskMatches = item.projectTaskId && item.projectTaskId === scheduledInvoice.projectTaskId;
        const dateMatches = scheduledInvoice.scheduleDate.isSame(moment(item.invoiceDate), "month");

        return taskMatches && dateMatches;
      });

      //If no invoice exists for the scheduled invoice then create a pending invoice
      if (!createdInvoice) {
        pendingInvoices.push(scheduledInvoice);
      }
    });

    //merge already created invoices with pending invoices
    invoices = _.concat(invoices, pendingInvoices);
  } else {
    //No maintenance tasks so check to see if an invoice exists for this project,
    //if not then create a pending invoice.
    invoices = store.invoicesState.filter(invoice => invoice.projectId === project.id);

    if (invoices.length === 0) {
      invoices.push({
        projectId: project.id,
        clientId: project.clientId,
        scheduleDate: moment(project.endDate),
      });
    }
  }

  return invoices;
}

export const selectInvoiceList = (store, project) => {
  const filter = store.filters.invoiceList;
  let invoices = [];

  //Concatenate all project invoices
  store.projectsState.forEach(project => {
    invoices = _.concat(invoices, selectProjectInvoices(store, project));
  });

  //Get distinct collection of invoices
  invoices = _.unionWith(invoices, store.invoicesState, (a, b) => a.id && b.id ? a.id === b.id : false);
  return (
    invoices.filter(invoice => filterInvoiceList(store, invoice))
      .sort((a,b) => sortInvoiceList(a, b, store))
  );
}

export const filterInvoiceList = (store, invoice) => {
  //first filter out all future maintenance invoices
  if (!invoice.id && invoice.projectTaskId && invoice.scheduleDate && (invoice.scheduleDate.diff(moment(), "months") > 1)) {
    return false;
  }

  const filter = store.filters.invoiceList;
  const invoiceStatusName = selectInvoiceStatus(store, invoice).name.toLowerCase();

  switch (filter.type) {
    case "all":
      if (filter.showArchived) {
        return true;
      } else if (invoiceStatusName === "paid") {
        return false;
      } else {
        return true;
      }
    case invoiceStatusName:
      return true;
    default:
      return false;
  }
}
export const sortInvoiceList = (a, b, store) => {
  const filter = store.filters.invoiceList;
  switch (filter.orderBy) {
    case "invoiceNumber":
      return filter.direction === "asc" ? a.invoiceNumber - b.invoiceNumber : b.invoiceNumber - a.invoiceNumber;
    case "client":
      const clientA = store.clientsState.find(client => client.id === a.clientId);
      const clientB = store.clientsState.find(client => client.id === b.clientId);

      if (!clientA) {
        return filter.direction === "asc" ? -1 : 1;
      } else if (!clientB) {
        return filter.direction === "asc" ? 1 : -1;
      } else {
        return filter.direction === "asc"
          ? clientA.fullName.localeCompare(clientB.fullName)
          : clientB.fullName.localeCompare(clientA.fullName);
      }
    default:
      const aProject = store.projectsState.find(project => project.id === a.projectId);
      const bProject = store.projectsState.find(project => project.id === b.projectId);

      let aDate;
      let bDate;
      if (a.id) {
        aDate = moment(a.invoiceDate);
      } else if (a.scheduleDate) {
        aDate = a.scheduleDate;
      } else if (aProject.endDate) {
        aDate = moment(aProject.endDate);
      }

      if (b.id) {
        bDate = moment(b.invoiceDate);
      } else if (b.scheduleDate) {
        bDate = b.scheduleDate;
      } else if (bProject.endDate) {
        bDate = moment(bProject.endDate);
      }

      return filter.direction === "asc" ? aDate.diff(bDate) : bDate.diff(aDate);
  }
}
