import { db } from '../../../firebase';

export const editClientForm = (updates) => ({
  type: "EDIT_CLIENT_FORM",
  updates
});

export const setClientForm = (client) => ({
  type: "SET_CLIENT_FORM",
  client
});

export const resetClientForm = () => ({
  type: "RESET_CLIENT_FORM"
});

export const startOnDoneClient = () => {
  return (dispatch, getState) => {
    const { validation, ...client } = getState().formData.client;
    if (client.id) {
      const { id, ...updates } = client;
      return db.updateClient(id, updates);
    } else {
      return db.createClient(client);
    }
  }
};

export const startRemoveClient = () => {
  return (dispatch, getState) => {
    const client = getState().formData.client;
    return db.deleteClient(client.id);
  }
};
