import moment from 'moment';
import State from './State';

export default function BidStateFactory() {
    this.createBidState = (state) => {
      //takes the passed in state object and creates a new
      //bid state
      const {
        sentDate,
        wonDate,
        lostDate,
        cancelledDate
      } = state;

      let bidState;

      if (wonDate !== undefined) {
        bidState = new WonState(state);
      } else if (lostDate !== undefined) {
        bidState = new LostState(state);
      } else if (cancelledDate !== undefined) {
        bidState = new CancelledState(state);
      } else if (sentDate !== undefined) {

        const sentDateMoment = moment(sentDate);
        const currentDateMoment = moment((new Date()).getTime());

        //If the sentDate is at least two weeks old then
        //the state is overdue
        if (currentDateMoment.diff(sentDateMoment, 'days') >= 14) {
          bidState = new OverdueState(state);
        } else {
          bidState = new SentState(state);
        }

      } else {
        bidState = new DraftState(state);
      }

      return bidState;

    }

    this.createBidStates = (state) => {
      //creates a map of all bid states
      const bidStates = new Map();
      bidStates.set("draft", new DraftState(state));
      bidStates.set("sent", new SentState(state));
      bidStates.set("overdue", new OverdueState(state));
      bidStates.set("won", new WonState(state));
      bidStates.set("lost", new LostState(state));
      bidStates.set("cancelled", new CancelledState(state));

      return bidStates;
    }
}

//TODO: create a constants file and add a function to calculate overdue, also
//change the caculation to be based off of Year, Month and Day
const DraftState = function (state) {
  State.call(this);
  this.adjacencyList = ["sent", "won", "lost", "cancelled"];
  this.type = "draft";

  this.statusMessage = () => {
    const sentDateMoment = moment(state.draftDate);
    const currentDateMoment = moment((new Date()).getTime());
    const daysOverdue = currentDateMoment.diff(sentDateMoment, 'days');
    let text = "";
    let status = undefined;

    if (daysOverdue > 0 && daysOverdue < 3) {
      text = `unsent for ${daysOverdue} days`;
    } else if (daysOverdue >= 3){
      text = `unsent for ${daysOverdue} days`;
      status = "overdue";
    }

    return {text, status};
  }

  this.action = {
    displayName: "Draft",
    state:{
      draftDate: getDate(state.draftDate),
    }
  }
};

const SentState = function (state) {
  State.call(this);
  this.adjacencyList = ["won", "lost", "cancelled", "draft"];
  this.type = "sent";

  this.statusMessage = () => {
    const sentDateMoment = moment(state.sentDate);
    const currentDateMoment = moment((new Date()).getTime());
    const daysOverdue = currentDateMoment.diff(sentDateMoment, 'days');
    let text = "";
    let status = undefined;

    if (daysOverdue > 0) {
      text = `sent ${daysOverdue} days ago`;
      status = "warning";
    } else {
      text = `sent today`;
      status = "ok";
    }

    return {text, status};
  }

  this.action = {
    displayName: "Send",
    state:{
      draftDate: getDate(state.draftDate),
      sentDate: (new Date()).getTime(),
    }
  }
};

const OverdueState = function (state) {
  State.call(this);
  this.adjacencyList = ["overdue", "won", "lost", "cancelled", "draft"];
  this.type = "overdue";

  this.statusMessage = () => {
    const sentDateMoment = moment(state.sentDate);
    const currentDateMoment = moment((new Date()).getTime());
    const daysOverdue = currentDateMoment.diff(sentDateMoment, 'days');
    let text = `sent ${daysOverdue} days ago`;
    let status = "warning";

    return {text, status};
  }

  this.action = {
    displayName: "Resend",
    state:{
      draftDate: getDate(state.draftDate),
      sentDate: (new Date()).getTime(),
    }
  }
};

const WonState = function (state) {
  State.call(this);
  this.adjacencyList = ["draft"];
  this.type = "won";

  this.statusMessage = () => {
    return undefined;
  }

  this.action = {
    displayName: "Won",
    state:{
      draftDate: getDate(state.draftDate),
      sentDate: getDate(state.sentDate),
      wonDate: (new Date()).getTime(),
    }
  }
};

const LostState = function (state) {
  State.call(this);
  this.adjacencyList = ["draft"];
  this.type = "lost";

  this.statusMessage = () => {
    return undefined;
  }

  this.action = {
    displayName: "Lost",
    state:{
      draftDate: getDate(state.draftDate),
      sentDate: getDate(state.sentDate),
      lostDate: (new Date()).getTime(),
    }
  }
};

const CancelledState = function (state) {
  State.call(this);
  this.adjacencyList = ["draft"];
  this.type = "cancelled";

  this.statusMessage = () => {
    return undefined;
  }

  this.action = {
    displayName: "Cancel",
    state:{
      draftDate: getDate(state.draftDate),
      sentDate: getDate(state.sentDate),
      cancelledDate: (new Date()).getTime(),
    }
  }
};

const getDate = function(date) {
  return date === undefined ? (new Date()).getTime() : date;
}
