import { db } from '../../../firebase';


export const startAddWebsiteTestimonial = (testimonial) => {
  return (dispatch) => db.createWebsiteTestimonial(testimonial);
}

export const startEditWebsiteTestimonial = (id, updates) => {
  return (dispatch) => db.updateWebsiteTestimonial(id, updates);
}

export const startRemoveWebsiteTestimonial = (id) => {
  return (dispatch) => db.deleteWebsiteTestimonial(id);
};

export const setWebsiteTestimonials = (testimonials) => ({
  type: 'SET_WEBSITE_TESTIMONIALS',
  testimonials
});

export const startSetWebsiteTestimonials= () => {
  return (dispatch) => {
    return db.getWebsiteTestimonials().on('value', snapshot => {
      const testimonials = [];

      snapshot.forEach(childSnapshot => {
        testimonials.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      dispatch(setWebsiteTestimonials(testimonials));
      dispatch({ type: 'VALIDATE_WEBSITE' });
    });
  };
};
