import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Button from '@material-ui/core/Button'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/core/styles'

import indigo from '@material-ui/core/colors/indigo'

import { TooltipBar } from 'components/admin/Help'
import { ConfirmDialog } from 'components/admin/Dialogs'
import {
  helpDispatch,
  Tooltip,
  TooltipTypes
} from 'components/admin/Help'

const styles = theme => ({
  rootContent: {
    backgroundColor: indigo[50],
  },
  title: {
    //backgroundColor: indigo[500],
  },
  titleText: {
    //margin: theme.spacing.unit,
    //color: "#fff"
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
})

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = { showDelete: false }
  }

  handleCancel = () => {
    this.setState({ showDelete: false })
  }

  handleDelete = () => {
    const { onClose, onDelete } = this.props

    if (onDelete) {
      onDelete()
        .then(() => onClose())
    } else {
      onClose()
    }
  }

  render() {
    const {
      classes,
      children,
      isMobile,
      confirmDelete,
      deleteTitle,
      deleteText,
      handleCreated,
      show,
      title,
      type,
      onClose,
      onDelete,
      onDone,
      validate,
      hideTitle,
      hideActions
    } = this.props
    const { showDelete } = this.state

    return (
      <div>
        <ConfirmDialog
          open={showDelete}
          title={deleteTitle}
          contentText={deleteText}
          handleCancel={this.handleCancel}
          handleOk={this.handleDelete}
        />
        <MaterialDialog
          maxWidth="md"
          fullScreen={isMobile}
          open={show}
          onClose={onClose}
        >
          {!hideTitle &&
            <DialogTitle>
              {title}
            </DialogTitle>
          }
          <DialogContent classes={{root: classes.rootContent}}>
            {children}
          </DialogContent>
          {!hideActions &&
            <DialogActions className={classes.dialogActions}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip type={TooltipTypes.setup.ACTIONS} placement="left">
                  <div />
                </Tooltip>
                {type !== "add" &&
                  <Button
                    onClick={() => {
                      if (confirmDelete) {
                        this.setState({ showDelete: true })
                      } else if (onDelete) {
                        onDelete()
                          .then(() => onClose())
                      } else {
                        onClose()
                      }
                    }}
                  >
                    Delete
                  </Button>
                }
              </div>

              <div>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (onDone) {
                      if(validate) {
                        if (validate()) {
                          onDone()
                            .then((result) => {
                              if (handleCreated) {
                                handleCreated(result.key)
                              }
                              onClose()
                            });
                        }
                      } else {
                        onDone()
                          .then(() => onClose())
                      }

                    } else {
                      onClose()
                    }
                  }}
                  color="primary">
                  {type === "add" ? "Add" : "Done"}
                </Button>
              </div>
            </DialogActions>
          }
          <TooltipBar />
        </MaterialDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.adminState.isMobile,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(Dialog);
