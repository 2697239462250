import { db } from '../../firebase';
import { validateWebsite } from 'store/actions/website';

export const setOrgDomains = (orgDomains) => ({
  type: 'SET_ORG_DOMAINS',
  orgDomains
});

export const startSetOrgDomains = () => {
  return (dispatch) => {
    return db.getOrganizationDomains().on('value', snapshot => {
      const orgDomains = [];
      snapshot.forEach(childSnapshot => {
        const orgDomain = childSnapshot.val();
        orgDomains.push({ id: childSnapshot.key, ...orgDomain });
      });

      dispatch(setOrgDomains(orgDomains));
      dispatch(validateWebsite());
    });
  }
}

export const startEditOrgDomain = (oid, orgDomain) => {
  return (dispatch) => {
    // console.log("OID:", oid);
    // console.log("orgDomain:", orgDomain);
    return db.getOrgDomains().then(snapshot => {
      snapshot.forEach(childSnapshot => {
        const key = childSnapshot.key;
        const val = childSnapshot.val();

        if(val.oid === oid) {
          // console.log("OID MATCH check if domain updated");
          // console.log("stored domains:", val);
          // console.log("passed in domains:", orgDomain);
          const updates = {};

          if(orgDomain.subDomain && val.subDomain !== orgDomain.subDomain) {
            updates.subDomain = orgDomain.subDomain;
            updates.subDomainStatus = "pending";
          }

          if(orgDomain.customDomain && val.customDomain !== orgDomain.customDomain) {
            updates.customDomain = orgDomain.customDomain;
            updates.customDomainStatus = "pending";
          }

          db.updateOrgDomain(key, updates);
        }
      })
    });
  }
}
