import { db } from '../../firebase';

export const setWorkTypes = (workTypes) => ({
  type: 'SET_WORK_TYPES',
  workTypes
});

export const startSetWorkTypes = () => {
  return (dispatch) => {
    return db.getWorkTypes().on('value', (snapshot) => {
      const workTypes = [];

      snapshot.forEach((childSnapshot) => {
        workTypes.push({id: childSnapshot.key, ...childSnapshot.val()})
      });

      dispatch(setWorkTypes(workTypes));
    });
  };
};
