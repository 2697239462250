import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import { editCrewForm } from '../../../store/actions';

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit*3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const CrewForm = ({ classes, crew, editCrewForm }) => {
  const { validation } = crew;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <div>
          <TextField
            error={!!validation.validateField("crewFirstName")}
            helperText={validation.validateField("crewFirstName")}
            fullWidth
            label="Name"
            margin="normal"
            value={crew && crew.crewFirstName}
            onChange={(event) => editCrewForm({crewFirstName: event.target.value})}
          />
          <TextField
            error={!!validation.validateField("email")}
            helperText={validation.validateField("email")}
            fullWidth
            label="Email"
            margin="normal"
            value={crew && crew.email}
            onChange={(event) => editCrewForm({email: event.target.value})}
          />
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  crew: state.formData.crew,
});

const mapDispatchToProps = {
  editCrewForm,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CrewForm);
