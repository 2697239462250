import { db, oid } from '../firebase';

export const getFiles = () =>
  db.ref(`appData/${oid}/files`);

export const createFile = (file) =>
  db.ref(`appData/${oid}/files`).push(file)
  .then((ref) => ({id: ref.key, ...file}));

export const deleteFile = (fileId) =>
  db.ref(`appData/${oid}/files/${fileId}`).remove()
