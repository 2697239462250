import moment from 'moment';
import _ from 'lodash';

//TODO: form validation is terrible, needs to be redone.
const defaultState = {
  importTimeMaterials: {
    timecards: [],
    receipts: [],
    lineItems: [],
  },
  client: {
    validation: {
      validateField: function() { return "" }
    }
  },
  crew: {
    validation: {
      validateField: function() { return "" }
    }
  },
  vendor: {
    validation: {
      validateField: function() { return "" }
    }
  },
  workType: {
    validation: {
      validateField: function() { return "" }
    }
  },
  taxCategory: {
    validation: {
      validateField: function() { return "" }
    }
  },
  quote: {
    showAddItemDialog: false,
    labor: [],
    materials: [],
  },
}

const generateLineItems = (timecards, receipts, state) => {
  const lineItems = [];

  timecards.forEach(timecard => {
    if (timecard.include) {
      const quantity = timecard.minutesWorked
        ? (timecard.minutesWorked/60).toFixed(1)
        : 0;
      const unitPrice = timecard.workType && timecard.workType.rate
        ? Number(timecard.workType.rate).toFixed(2)
        : 0;
      const total = (quantity*unitPrice).toFixed(2);
      const workTypeName = timecard.workType && timecard.workType.name
        ? `${timecard.workType.name} work on`
        : "Labor on";
      const workDate = moment(timecard.startDate).format("MMM Do, YYYY");
      const description = `${workTypeName} ${workDate}`;

      lineItems.push({
        description,
        quantity,
        unitPrice,
        total,
        timecardId: timecard.id,
      });
    }
  })

  receipts.forEach(receipt => {
    if (receipt.include) {
      const quantity = 1;
      const unitPrice = receipt.charged ? receipt.charged : 0;
      const total = (quantity*unitPrice).toFixed(2);

      lineItems.push({
        description: "Materials charge",
        quantity,
        unitPrice,
        total,
        receiptId: receipt.id
      });
    }
  });

  return lineItems;
}

const editImportMaterial = (state, action) => {
  const timecards = [ ...state.importTimeMaterials.timecards ];
  const receipts = state.importTimeMaterials.receipts.map(receipt => {
    let newReceipt;
    if(receipt.id === action.id) {
      newReceipt = { ...receipt, ...action.updates };
    } else {
      newReceipt = { ...receipt };
    }

    return newReceipt;
  });
  const lineItems = generateLineItems(timecards, receipts, state);

  return ({
    ...state,
    importTimeMaterials: {
      receipts,
      timecards,
      lineItems
    }
  });
};

const editImportTime = (state, action) => {
  const receipts = [ ...state.importTimeMaterials.receipts ];
  const timecards = state.importTimeMaterials.timecards.map(timecard => {
    let newTimecard;
    if (timecard.id === action.id) {
      newTimecard = { ...timecard, ...action.updates };
    } else {
      newTimecard = { ...timecard };
    }

    return newTimecard;
  });
  const lineItems = generateLineItems(timecards, receipts, state);

  return ({
    ...state,
    importTimeMaterials: {
      receipts,
      timecards,
      lineItems
    }
  });
};

const addQuoteItem = (state) => {
  const quote = { ...state.quote };
  const description = quote.dialogDescription ? quote.dialogDescription : "";
  const quantity = quote.dialogQuantity ? Number(quote.dialogQuantity) : 0;
  const rate = quote.dialogRate ? Number(quote.dialogRate) : 0;
  const item = {
    description,
    quantity,
    rate,
    total: Number((quantity*rate).toFixed(2))
  };

  if (quote.dialogType === "labor") {
    quote.labor.push(item);
  } else if (quote.dialogType = "materials") {
    quote.materials.push(item);
  }

  quote.showAddItemDialog = false;
  quote.dialogDescription = "";
  quote.dialogQuantity = undefined;
  quote.dialogRate = undefined;
  quote.dialogType = undefined;
  quote.dialogId = undefined;

  return quote;
};

//TODO: refactor, move code for add and edit into functions and modify dialog
//properties into a dialog option so that reset is easier and less prone to errors.
const editQuoteItem = (state) => {
  const quote = { ...state.quote };
  const description = quote.dialogDescription ? quote.dialogDescription : "";
  const quantity = quote.dialogQuantity ? Number(quote.dialogQuantity) : 0;
  const rate = quote.dialogRate ? Number(quote.dialogRate) : 0;
  const id = quote.dialogId;

  if (quote.dialogType === "labor") {
    const item = quote.labor.find(item => item.id === id);
    item.description = description;
    item.quantity = quantity;
    item.rate = rate;
    item.total = Number((quantity*rate).toFixed(2));
  } else if (quote.dialogType = "materials") {
    const item = quote.materials.find(item => item.id === id);
    item.description = description;
    item.quantity = quantity;
    item.rate = rate;
    item.total = Number((quantity*rate).toFixed(2));
  }

  quote.showAddItemDialog = false;
  quote.dialogDescription = "";
  quote.dialogQuantity = undefined;
  quote.dialogRate = undefined;
  quote.dialogType = undefined;
  quote.dialogId = undefined;

  return quote;
}

const deleteQuoteTaskItem = (state, id, type) => {
  const quote = { ...state.quote };
  if (type === "labor") {
    quote.labor.forEach(item => {
      if (item.id === id) {
        item.isDelete = true;
      }
    });
  } else if (type === "materials"){
    quote.materials.forEach(item => {
      if (item.id === id) {
        item.isDelete = true;
      }
    });
  }

  return quote;
}

const validateClient = (client) => {
  const errors = {};
  const requiredFields = [ 'firstName', 'lastName'];
  requiredFields.forEach(field => {
    if (!client[field]) {
      errors[field] = 'Required';
    }
  });

  if (client.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(client.email)) {
    errors.email = 'Invalid email address';
  }

  const validation = { errors, isInvalid: !!Object.keys(errors).length };
  validation.validateField = function (field) {
    if (this.showValidation) {
      return this.errors[field];
    } else {
      return "";
    }
  };

  return validation;
}

const validateCrew = (crew) => {
  const errors = {};
  const requiredFields = [ 'crewFirstName' ];
  requiredFields.forEach(field => {
    if (!crew[field]) {
      errors[field] = 'Required';
    }
  });

  if (crew.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(crew.email)) {
    errors.email = 'Invalid email address';
  }

  const validation = { errors, isInvalid: !!Object.keys(errors).length };

  validation.validateField = function (field) {
    if (this.showValidation) {
      return this.errors[field];
    } else {
      return "";
    }
  };

  return validation;
}

const editClient = (state, updates) => {
  const newClient = { ...state.client, ...updates };
  const showValidation = newClient.validation && newClient.validation.showValidation;
  newClient.validation = validateClient(newClient);
  if (showValidation) {
    newClient.validation.showValidation = showValidation;
  }

  return newClient;
}

const setClient = (client) => {
  client.validation = validateClient(client);
  return client;
}


const editCrew = (state, updates) => {
  const newCrew = { ...state.crew, ...updates };
  const showValidation = newCrew.validation && newCrew.validation.showValidation;
  newCrew.validation = validateCrew(newCrew);
  if (showValidation) {
    newCrew.validation.showValidation = showValidation;
  }

  return newCrew;
}

const setCrew = (crew) => {
  crew.validation = validateCrew(crew);
  return crew;
}

const validateVendor = (vendor) => {
  const errors = {};
  const requiredFields = [ 'name' ];
  requiredFields.forEach(field => {
    if (!vendor[field]) {
      errors[field] = 'Required';
    }
  });

  const validation = { errors, isInvalid: !!Object.keys(errors).length };

  validation.validateField = function (field) {
    if (this.showValidation) {
      return this.errors[field];
    } else {
      return "";
    }
  };

  return validation;
}

const editVendor = (state, updates) => {
  const newVendor = { ...state.vendor, ...updates };
  const showValidation = newVendor.validation && newVendor.validation.showValidation;
  newVendor.validation = validateVendor(newVendor);
  if (showValidation) {
    newVendor.validation.showValidation = showValidation;
  }

  return newVendor;
}

const setVendor = (vendor) => {
  vendor.validation = validateVendor(vendor);
  return vendor;
}

const validateWorkType = (workType) => {
  const errors = {};
  const requiredFields = [ 'name', 'rate' ];
  requiredFields.forEach(field => {
    if (!workType[field]) {
      errors[field] = 'Required';
    }
  });

  const validation = { errors, isInvalid: !!Object.keys(errors).length };

  validation.validateField = function (field) {
    if (this.showValidation) {
      return this.errors[field];
    } else {
      return "";
    }
  };

  return validation;
}

const editWorkType = (state, updates) => {
  const newWorkType = { ...state.workType, ...updates };
  const showValidation = newWorkType.validation && newWorkType.validation.showValidation;
  newWorkType.validation = validateWorkType(newWorkType);
  if (showValidation) {
    newWorkType.validation.showValidation = showValidation;
  }

  return newWorkType;
}

const setWorkType = (workType) => {
  workType.validation = validateWorkType(workType);
  return workType;
}


const validateTaxCategory = (taxCategory) => {
  const errors = {};
  const requiredFields = [ 'name' ];
  requiredFields.forEach(field => {
    if (!taxCategory[field]) {
      errors[field] = 'Required';
    }
  });

  const validation = { errors, isInvalid: !!Object.keys(errors).length };

  validation.validateField = function (field) {
    if (this.showValidation) {
      return this.errors[field];
    } else {
      return "";
    }
  };

  return validation;
}

const editTaxCategory = (state, updates) => {
  const newTaxCategory = { ...state.taxCategory, ...updates };
  const showValidation = newTaxCategory.validation && newTaxCategory.validation.showValidation;
  newTaxCategory.validation = validateTaxCategory(newTaxCategory);
  if (showValidation) {
    newTaxCategory.validation.showValidation = showValidation;
  }

  return newTaxCategory;
}

const setTaxCategory = (taxCategory) => {
  taxCategory.validation = validateTaxCategory(taxCategory);
  return taxCategory;
}



const showValidation = (obj) => {
  const newObj = { ...obj };
  newObj.validation.showValidation = true;
  return newObj;
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case "REMOVE_QUOTE_TASK_ITEM":
      return { ...state, quote: deleteQuoteTaskItem(state, action.id, action.itemType)};
    case "ADD_QUOTE_ITEM":
      return { ...state, quote: addQuoteItem(state) };
    case "EDIT_QUOTE_ITEM":
      return { ...state, quote: editQuoteItem(state) };
    case "EDIT_QUOTE_FORM":
      return { ...state, quote: { ...state.quote, ...action.updates }}
    case "SET_QUOTE_FORM":
      return { ...state, quote: { ...defaultState.quote, ...action.quote }}
    case "RESET_QUOTE_FORM":
      return { ...state, quote: defaultState.quote }
    case "EDIT_CREW_FORM":
      return { ...state, crew: editCrew(state, action.updates)}
    case "SET_CREW_FORM":
      return { ...state, crew: setCrew(action.crew) }
    case "RESET_CREW_FORM":
      return { ...state, crew: defaultState.crew }
    case "EDIT_TAX_CATEGORY_FORM":
      return { ...state, taxCategory: editTaxCategory(state, action.updates) }
    case "SET_TAX_CATEGORY_FORM":
      return { ...state, taxCategory: setTaxCategory(action.taxCategory) }
    case "RESET_TAX_CATEGORY_FORM":
      return { ...state, taxCategory: defaultState.taxCategory }
    case "EDIT_WORK_TYPE_FORM":
      return { ...state, workType: editWorkType(state, action.updates) }
    case "SET_WORK_TYPE_FORM":
      return { ...state, workType: setWorkType(action.workType) }
    case "RESET_WORK_TYPE_FORM":
      return { ...state, workType: defaultState.workType }
    case "EDIT_VENDOR_FORM":
      return { ...state, vendor: editVendor(state, action.updates)};
    case "SET_VENDOR_FORM":
      return { ...state, vendor: setVendor(action.vendor)};
    case "RESET_VENDOR_FORM":
      return { ...state, vendor: defaultState.vendor }
    case "EDIT_CLIENT_FORM":
      return { ...state, client: editClient(state, action.updates) }
    case "SET_CLIENT_FORM":
      return { ...state, client: setClient(action.client) }
    case "SHOW_CLIENT_FORM_VALIDATION":
      return { ...state, client: showValidation(state.client) };
    case "SHOW_CREW_FORM_VALIDATION":
      return { ...state, crew: showValidation(state.crew) };
    case "SHOW_VENDOR_FORM_VALIDATION":
      return { ...state, vendor: showValidation(state.vendor) };
    case "SHOW_WORK_TYPE_FORM_VALIDATION":
      return { ...state, workType: showValidation(state.workType) };
    case "SHOW_TAX_CATEGORY_FORM_VALIDATION":
      return { ...state, taxCategory: showValidation(state.taxCategory) };
    case "RESET_CLIENT_FORM":
      return { ...state, client: defaultState.client }
    case "EDIT_IMPORT_MATERIAL":
      return editImportMaterial(state, action);
    case "EDIT_IMPORT_TIME":
      return editImportTime(state, action);
    case "SET_IMPORT_TIME_MATERIALS":
      return { ...state, importTimeMaterials: action.importTimeMaterials };
    case "RESET_IMPORT_TIME_MATERIALS":
      return { ...state, importTimeMaterials: defaultState.importTimeMaterials };
    default:
      return state;
  }
};
