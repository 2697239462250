import store from 'store';
import { missions, MissionBuilder, TooltipTypes } from 'components/admin/Help';

const defaultState = {
  showHelp: false,
  showTour: false,
  showMissions: false,
  acceptMission: undefined,
  currentStepType: function() {
    const currentStep = this.currentMission.steps[this.currentMission.currentStep];
    return currentStep && currentStep.primaryType;
  },
  isCurrentStep: function(type) {
    //console.log("isCurrentStep TYPE:", type);
    //console.log("isCurrentStep CURRENT TYPE:", this.currentStepType());

    return type === this.currentStepType();
  },
  currentMission: {
    inProgress: false,
    currentStep: 0,
    steps: [],
  },
  missions: [],
  showOverlay: function() {
    return (this.showTour || this.showHelp || this.currentMission.inProgress);
  }
};

export default (state = defaultState, action) => {
  const _missions = state.missions;

  switch (action.type) {
    case 'RESET':
      return defaultState
    case "SET_SHOW_TOUR":
      return { ...state, showTour: action.showTour };
    case "SET_MISSIONS":
      return { ...state, missions: action.missions };
    case "SHOW_MISSIONS":
      return { ...defaultState, showMissions: true, missions: _missions };
    case "CLOSE_MISSIONS":
      return { ...defaultState, missions: _missions };
    case "SHOW_HELP":
      return { ...state, showHelp: true };
    case "CLOSE_HELP":
      return { ...defaultState, missions: _missions };
    case "NEXT_STEP_MISSION":
      state.currentMission.currentStep = state.currentMission.currentStep + 1;
      return { ...state };
    case "CLOSE_ACCEPT_MISSION":
      return { ...state, acceptMission: undefined };
    case "DISPLAY_ACCEPT_MISSION":
      return { ...state, acceptMission: action.missionId };
    case "START_INVOICE_MISSION":
      //console.log("TEST:", MissionBuilder.getMission("invoice-1"));
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.invoiceMission(action.id)
      };
    case "START_WEBSITE_MISSION":
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.websiteMission(action.id)
      };
    case "START_SETUP_MISSION":
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.setupMission(action.id)
      };
    case "START_BID_MISSION":
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.bidMission(action.id)
      };
    case "START_TIMECARD_MISSION":
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.timecardMission(action.id)
      };
    case "START_RECEIPT_MISSION":
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.receiptMission(action.id)
      };
    case "START_JOB_MISSION":
      return {
        ...state,
        showMissions: false,
        acceptMission: undefined,
        currentMission: missions.jobMission(action.id)
      };
    default:
      return state;
  };
};
