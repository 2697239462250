
let debug = false
const setDebug = (isDebug) => debug = isDebug

const dispatchConversion = (id, eventName) => {
  if(!debug && window.gtag) {
    window.gtag('event', 'conversion', { 'send_to': `AW-661398134/${id}` })
  } else {
    console.log("Google ads conversion: {id, eventName}", id, eventName)
  }
}

export {
  dispatchConversion,
  setDebug
}
