const defaultState = {
  website: {
    show: false,
    isPreview: false,
    loading: false,
  },
  PDF: {
    show: false,
    isPreview: false,
    loading: false,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'CLOSE_WEBSITE_DIALOG':
      return { ...state, website: defaultState.website };
    case 'EDIT_WEBSITE_DIALOG':
      return { ...state, website: { ...state.website, ...action.updates } };
    case 'CLOSE_PDF_DIALOG':
      return { ...state, PDF: defaultState.PDF };
    case 'EDIT_PDF_DIALOG':
      return { ...state, PDF: { ...state.PDF, ...action.updates } };
    default:
      return state;
  }
};
