import uuid from 'uuid';
import { db, storage } from '../../../firebase';
import { analytics } from 'analytics'

export const startAddWebsitePortfolio = (portfolio, fileData) => {
  const fileId = uuid.v4();
  portfolio.fileId = fileId;
  let portfolioId;

  //console.log("UPLOAD FILE DATA:", fileData);
  return (dispatch) => {
    return db.createWebsitePortfolio(portfolio)
    .then(result => {
      portfolioId = result.key;
      return storage.uploadPortfolioFile(fileId, fileData);
    })
    .then(() => startSetWebsitePortfolio()(dispatch))
  }
}

export const startEditWebsitePortfolio = (id, updates, fileData) => {
  const fileId = updates.fileId ? updates.fileId : uuid.v4();
  updates.fileId = fileId;

  if (fileData) {
    return (dispatch) => {
      return db.updateWebsitePortfolio(id, updates)
      .then(() => storage.uploadPortfolioFile(fileId, fileData))
      .then(() => startSetWebsitePortfolio()(dispatch));
    };
  } else {
    return (dispatch) => {
      return (
        db.updateWebsitePortfolio(id, updates)
          .then(() => startSetWebsitePortfolio()(dispatch))
      )
    };
  }
}

export const startRemoveWebsitePortfolio = (id, fileId) => {
  return (dispatch) => db.deleteWebsitePortfolio(id)
        .then(() => storage.deletePortfolioFile(fileId))
        .then(() => startSetWebsitePortfolio()(dispatch))
};

export const setWebsitePortfolio = (portfolio) => ({
  type: 'SET_WEBSITE_PORTFOLIO',
  portfolio
});

export const startSetWebsitePortfolio = () => {
  return (dispatch) => {
    return (
      db.getWebsitePortfolio().once('value')
        .then(snapshot => {
          const promises = [];
          const portfolio = [];

          snapshot.forEach(childSnapshot => {
            portfolio.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });

          portfolio.forEach(item => {
            if(item.fileId) {
              promises.push(
                storage.getPortfolioDownloadURL(item.fileId)
                  .then(result => {
                    item.downloadURL = result
                  })
                  .catch(error => {
                    console.log("LOAD PORTFOLIO URL FAILED:", error)
                    analytics.exception(`portfolio::startSetWebsitePortfolio [message:${error.message}]`)
                  })
              );
            }
          });

          Promise.all(promises).then(() => {
            dispatch(setWebsitePortfolio(portfolio));
            return dispatch({ type: 'VALIDATE_WEBSITE' });
          });
        })
    );
  }
};

// export const startSetWebsitePortfolio = () => {
//   return (dispatch) => {
//     console.log("ZZZ - 1 getting portfolio")
//     return db.getWebsitePortfolio().on('value', snapshot => {
//       const promises = [];
//       const portfolio = [];
//       console.log("ZZZ - 2 got portfolio")
//
//       snapshot.forEach(childSnapshot => {
//         portfolio.push({ id: childSnapshot.key, ...childSnapshot.val() });
//       });
//
//       portfolio.forEach(item => {
//         console.log("ZZZ - 3 getting storage image")
//         if(item.fileId) {
//           promises.push(
//             storage.getPortfolioDownloadURL(item.fileId)
//               .then(result => {
//                 console.log("ZZZ - 4 got storage image")
//                 item.downloadURL = result
//               })
//               .catch(error => console.log("LOAD PORTFOLIO URL FAILED:", error))
//           );
//         }
//       });
//
//       Promise.all(promises).then(() => {
//         dispatch(setWebsitePortfolio(portfolio));
//         return dispatch({ type: 'VALIDATE_WEBSITE' });
//       });
//     });
//   };
// };
