import React from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import indigo from '@material-ui/core/colors/indigo';



const AddButton = ({ elevate, onClick, width, height }) => {

  const rootStyle = {
    backgroundColor: "#fff",
    width: width ? width : '40px',
    height: height ? height : '40px',
    boxShadow: "none",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: indigo[500],
  }
  const elevateStyle = {
    position: 'relative',
    zIndex: 3000,
  }

  const combinedStyle = elevate ? {...rootStyle, ...elevateStyle } : rootStyle;

  return(
    <Button
      style={combinedStyle}
      variant="fab"
      onClick={onClick}
    >
      <AddIcon color="primary" />
    </Button>
  );
}


export default AddButton;
