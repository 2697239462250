import {
  websiteTypes
} from './Types/website';

//TODO: refactor out all the types to their respective Types File

const tourTypes = {
  TOUR_MENU: "tour-menu",
  TOUR_MISSION: "tour-mission",
  TOUR_INFO_PANEL: "tour-info-panel",
}

const setupTypes = {
  ADD_CLIENT: "setup-add-client",
  TABS: "setup-tabs",
  ACTIONS: "setup-actions",
}

const receiptTypes = {
  ADD: "receipt-add",
  SAVE_DIALOG: "receipt-save-dialog"
}

const bidTypes = {
  ADD: "bid-add",
  ADD_CLIENT: "bid-add-client",
  ADD_SALES_BASIC: "bid-add-sales-basic",
  ENTER_INFO: "bid-enter-info",
  ADD_TASK_SIMPLE: "bid-add-task-simple",
  ENTER_INFO_TASK: "bid-enter-info-task",
  CLOSE: "bid-close",
  BID_INFO_PANEL: 'bid-info-panel',
  SECTIONS: "bid-sections",
  SALES_SECTION: "bid-sales-section",
  ACTIONS_INFO: "bid-actions-info",
  HELP_INFO_PANEL: "bid-help-info-panel",
}

const jobTypes = {
  ADD: "job-add",
  FILTERS: "job-filters",
  BASIC_INFO: "job-basic-info",
  TABS: "job-tabs",
  SELECT_TASKS: "job-select-tasks",
}

const timecardTypes = {
  INFO_PANEL: "time-info-panel",
  POPUP_FORM: "time-popup-form",
  CLIENT_POPUP_FORM: "time-client-popup-form",
  WORKTYPES: "time-worktypes",
}

const clientDialogTypes = {
  OPTIONAL_EMAIL: "client-dialog-optional-email",
}

const TooltipTypes = {
  ADD_BID: "add-bid",
  ADD_INVOICE: "add-invoice",
  IMPORT_TIME_MATERIALS_PROGRESS: "import-time-materials-progress",
  IMPORT_TIME_MATERIALS_FINISH:" import-time-materials-finish",
  IMPORT_TIME_INCLUDE: "import-time-include",
  IMPORT_TIME_NEXT: "import-time-next",
  IMPORT_MATERIALS_SKIP: "import-materials-skip",
  INVOICE_MENU_ITEM: "invoice-menu-item",
  INVOICE_CARDS_SIDE: "invoice-cards-side",
  INVOICE_ADD_CLIENT: "invoice-add-client",
  INVOICE_SELECT_CLIENT: "invoice-select-client",
  INVOICE_SELECT_JOB: "invoice-select-job",
  INVOICE_ADD_ITEM: "invoice-add-item",
  INVOICE_SAVE_ITEM: "invoice-save-item",
  INVOICE_PREVIEW_SEND: "invoice-preview-sent",
  INVOICE_SEND: "invoice-send",
  INVOICE_SEND_EMAIL: "invoice-send-email",
  INVOICE_CLIENT_SMART_TEXT: "invoice-client-smart-text",
  INVOICE_ADD_TIME_MATERIALS: "invoice-add-time-materials",
  INVOICE_JOB_OPTIONAL: "invoice-job-optional",
  INVOICE_IMPORTED_TIME: "invoice-imported-time",
  CREATE_CLIENT_DIALOG: "create-client-dialog",
  ADD_TIMECARD: "add-timecard",
  TIMECARD_MENU_ITEM: "timecard-menu-item",
  TIMECARD_CARDS_SIDE: "timecard-cards-side",
  TIMECARD_CHANGE_TIME: "timecards-change-time",
  TIMECARD_NOTES: "timecards-notes",
  TIMECARD_CREATE_CREW: "timecard-create-crew",
  CREATE_CREW_DIALOG: "create-crew-dialog",
  TIMECARD_CREATE_CLIENT: "timecard-create-client",
  TIMECARD_SAVE_CLIENT: "timecard-save-client",
  TIMECARD_SELECT_WORKTYPE: "timecard-select-worktype",
  TIMECARD_CHARGES: "timecard-charges",
  TIMECARD_BILL_TO: "timecard-bill-to",
  TIMECARD_REVIEW: "timecard-review",
  TIMECARD_SAVE: "timecard-save",
  TIMECARD_CREATED_ITEM: "timecard-created-item",
  TIMECARD_FILTER_SHOW_ARCHIVED: "timecard-filter-show-archived",
  TIMECARD_INVOICED_ITEM: "timecard-invoiced-item",
  RECEIPT_SAVE: "receipt-save",
  website: websiteTypes,
  setup: setupTypes,
  receipt: receiptTypes,
  bid: bidTypes,
  job: jobTypes,
  clientDialog: clientDialogTypes,
  timecard: timecardTypes,
  tour: tourTypes
};

export default TooltipTypes;
