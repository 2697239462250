import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

import { Factory as DialogFactory } from './AcceptDialogs';
import * as routes from 'constants/routes';
import { closeAcceptMission, startMission, saveCompletedMission } from 'store/actions/help';
import { startEditAdmin } from 'store/actions/admin';
import { illustrations } from 'components/Illustration';

import HelpTooltip from 'img/infoTooltipExample.png';
import ActionTooltip from 'img/helpTooltipExample.png';

const styles = theme => ({
  goContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  goButton: {
    marginBottom: theme.spacing.unit*2,
    // color: theme.palette.getContrastText(amber[500]),
    // backgroundColor: amber[500],
    // '&:hover': {
    //     backgroundColor: amber[700],
    // },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing.unit*4,
    marginBottom: theme.spacing.unit*4,
  },
  img: {
    flexShrink: 0,
    width: '120px',
    height: '120px',
    marginLeft: theme.spacing.unit*4,
  },
  checkboxRow: {
     display: 'flex',
     flexDirection: 'row',
     alignItems: 'center',
     marginLeft: theme.spacing.unit,
     marginBottom: theme.spacing.unit,
  },
  rootCheckbox: {
    height: '30px',
    color: grey[600],
    '&$checked': {
      color: grey[500],
    },
  },
  checked: {},
});

const MissionDialog = (props) => {
  const {
    classes,
    history,
    isMobile,
    acceptMission,
    closeAcceptMission,
    startMission,
    startEditAdmin
  } = props;
  const content = DialogFactory.create(acceptMission);
  //console.log("FACTORY:", DialogFactory.create(acceptMission));
  return (
    <Dialog
      fullScreen={isMobile}
      open={Boolean(acceptMission)}
      maxWidth="sm"
    >
      <DialogTitle variant="headline">{content.title}</DialogTitle>
      <DialogContent>

        <Typography style={{ marginBottom: '20px' }}>
          {content.subtitle}
        </Typography>

        <Typography style={{ marginBottom: '10px' }}>{content.stepsInstruction}</Typography>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div>
            {content.steps.map((step, i) => {
              return(
                  <div key={i} className={classes.checkboxRow}>
                    <div
                      style={{
                        marginRight: '10px',
                        width: '8px', height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#484848'
                      }}
                    />
                    <Typography>
                      {step}
                    </Typography>
                  </div>
              )
            })}
          </div>
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
          }}>
            <img
              className={classes.img}
              alt=""
              src={illustrations.get("mission_impossible_amber")}
            />
            <Typography style={{
                textDecoration: 'underline',
                marginTop: '10px',
                marginLeft: '30px',
            }}>
              {`Mission: ${content.number}`}
            </Typography>
          </div>

        </div>
      </DialogContent>
      <div className={classes.goContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.goButton}
          onClick={() => {
            switch (acceptMission) {
              case "invoice-1":
                saveCompletedMission("intro-1");
                startEditAdmin({ invoice: null });
                history.push(routes.INVOICES);
                startMission("invoice-1");
                break;
              case "website-1":
                saveCompletedMission("intro-1");
                history.push(routes.WEBSITE);
                startMission("website-1");
                break;
              case "setup-1":
                history.push(routes.SETUP);
                startMission("setup-1");
                break;
              case "bid-1":
                history.push(routes.BIDS);
                startMission("bid-1");
                break;
              case "timecard-1":
                history.push(routes.TIMECARDS);
                startMission("timecard-1");
                break;
              case "receipt-1":
                history.push(routes.RECEIPTS);
                startMission("receipt-1");
                break;
              case "job-1":
                history.push(routes.JOBS);
                startMission("job-1");
                break;
              default:
            }
          }}
        >
          Go!
        </Button>
        <Typography variant="caption">
          {`Estimated Time ${content.estimate}`}
        </Typography>
      </div>

      <DialogActions>
        <Button
          onClick={() => closeAcceptMission()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  isMobile: state.adminState.isMobile,
})

const mapDispatchToProps = {
  closeAcceptMission,
  startMission,
  startEditAdmin
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MissionDialog);
