import types from '../TooltipTypes';

const dialogTooltips = [
  {
    id: types.CREATE_CLIENT_DIALOG,
    title: "Add Client",
    subtitle: "Enter first and last name then select Done to add a new client",
    type: "primary",
  },
  {
    id: types.CREATE_CREW_DIALOG,
    title: "Add Crew",
    subtitle: "Just enter name for now.",
    type: "primary",
  },
  {
    id: types.clientDialog.OPTIONAL_EMAIL,
    title: "Email text box",
    subtitle: "Optionally, add an email so later on you can easily email the Bid to your client.",
    type: "secondary",
  },
];

export default dialogTooltips;
