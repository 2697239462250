import { db } from '../../firebase'
import store from 'store'

export const startAddReminder = reminder => dispatch =>
  db.createReminder(reminder)

export const startEditReminder = (id, updates) => dispatch =>
  db.updateReminder(id, updates)

export const startRemoveReminder = id => dispatch =>
  db.deleteReminder(id)

export const startPauseRemindersAll = () => dispatch => {
  const promises = []
  const state = store.getState()
  const { reminders } = state

  reminders.filter(reminder => !reminder.isPaused)
    .forEach(reminder => promises.push(db.updateReminder(reminder.id, { isPaused: true })))

  return Promise.all(promises)
}

export const setReminders = (reminders) => ({
  type: 'SET_REMINDERS',
  reminders
})

export const startSetReminders = () => dispatch =>
  db.getReminders().on('value', snapshot => {
    const reminders = []
    snapshot.forEach(child => {
      const reminder = { id: child.key, ...child.val() }
      if (!reminder.weeklySchedule) {
        reminder.weeklySchedule = []
      }
      if (!reminder.monthlySchedule) {
        reminder.monthlySchedule = []
      }

      reminders.push(reminder)

    })

    return dispatch(setReminders(reminders))
  })
