import { db, oid } from '../firebase';
import { analytics } from 'analytics'

export const getBusiness = () => {
  const business = {
    address: null,
    companyName: null
  }
  return db.ref(`appData/${oid}/address`).once('value')
    .then((snapshot) => business.address = snapshot.val())
    .then(() => db.ref(`appData/${oid}/companyName`).once('value'))
    .then((snapshot) => {
      business.companyName = snapshot.val();
      return business;
    })
    .catch((error) =>{
      analytics.exception(`business::getBusiness [message:${error.message}]`)
      console.log('getBusiness ERROR:', error);
    })
};
