import { db } from '../../../firebase';

export const editVendorForm = (updates) => ({
  type: "EDIT_VENDOR_FORM",
  updates
});

export const setVendorForm = (vendor) => ({
  type: "SET_VENDOR_FORM",
  vendor
});

export const resetVendorForm = () => ({
  type: "RESET_VENDOR_FORM"
});

export const startOnDoneVendor = () => {
  return (dispatch, getState) => {
    const { validation, ...vendor } = getState().formData.vendor;
    
    if (vendor.id) {
      const { id, ...updates } = vendor;
      return db.updateVendor(id, updates);
    } else {
      return db.createVendor(vendor);
    }
  }
};

export const startRemoveVendor = () => {
  return (dispatch, getState) => {
    const vendor = getState().formData.vendor;
    return db.deleteVendor(vendor.id);
  }
};
