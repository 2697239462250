
export const editTimecardListFilter = (updates) => ({
  type: 'EDIT_TIMECARD_LIST_FILTER',
  updates
});

export const startEditTimecardListFilter = (updates) => {
  return (dispatch) => dispatch(editTimecardListFilter(updates));
};

export const editBidListFilter = (updates) => ({
  type: 'EDIT_BID_LIST_FILTER',
  updates
});

export const startEditBidListFilter = (updates) => {
  return (dispatch) => dispatch(editBidListFilter(updates));
};

export const editProjectListFilter = (updates) => ({
  type: "EDIT_PROJECT_LIST_FILTER",
  updates
});

export const startEditProjectListFilter = (updates) =>
  (dispatch) => dispatch(editProjectListFilter(updates));

export const editInvoiceListFilter = (updates) => ({
  type: "EDIT_INVOICE_LIST_FILTER",
  updates
});

export const startEditInvoiceListFilter = (updates) => {
  return (dispatch) => dispatch(editInvoiceListFilter(updates));
};

export const editReceiptListFilter = (updates) => ({
  type: 'EDIT_RECEIPT_LIST_FILTER',
  updates
});

export const startEditReceiptListFilter = (updates) => {
  return (dispatch) => dispatch(editReceiptListFilter(updates));
};
