import { db } from '../../../firebase';

export const editTaxCategoryForm = (updates) => ({
  type: "EDIT_TAX_CATEGORY_FORM",
  updates
});

export const setTaxCategoryForm = (taxCategory) => ({
  type: "SET_TAX_CATEGORY_FORM",
  taxCategory
});

export const resetTaxCategoryForm = () => ({
  type: "RESET_TAX_CATEGORY_FORM"
});

export const startOnDoneTaxCategory = () => {
  return (dispatch, getState) => {
    const { validation, ...taxCategory } = getState().formData.taxCategory;

    if (taxCategory.id) {
      const { id, ...updates } = taxCategory;
      return db.updateTaxCategory(id, updates);
    } else {
      return db.createTaxCategory(taxCategory);
    }
  }
};

export const startRemoveTaxCategory = () => {
  return (dispatch, getState) => {
    const taxCategory = getState().formData.taxCategory;
    return db.deleteTaxCategory(taxCategory.id);
  }
};
