import { db, oid } from '../firebase';

export const getTaxCategories = () =>
  db.ref(`appData/${oid}/taxCategories`);

export const getTaxRules = () =>
  db.ref(`appData/${oid}/taxRules`);

export const createTaxCategory = (taxCategory) =>
  db.ref(`appData/${oid}/taxCategories`).push(taxCategory)

export const updateTaxCategory = (id, updates) =>
  db.ref(`appData/${oid}/taxCategories/${id}`).update(updates);

export const deleteTaxCategory = (id) =>
  db.ref(`appData/${oid}/taxCategories/${id}`).remove();
