import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }
});

const ColumnItem = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default withStyles(styles)(ColumnItem);
