import { db, oid } from '../firebase';

export const getTimecards = () => {
  return db.ref(`appData/${oid}/timecards`);
}

export const createTimecard = (timecardData) =>
  db.ref(`appData/${oid}/timecards`).push(timecardData)

export const deleteTimecardField = (id, field) =>
  db.ref(`appData/${oid}/timecards/${id}/${field}`).remove();

export const deleteTimecard = (id) =>
  db.ref(`appData/${oid}/timecards/${id}`).remove();

export const updateTimecard= (id, updates) =>
  db.ref(`appData/${oid}/timecards/${id}`).update(updates);
