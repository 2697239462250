//import ReactGA from 'react-ga'
import $ from 'jquery'

import { ads, conversions, ga } from './google'
import store from 'store'

const gaTrackingId = 'UA-159813261-2'
const startTime = Date.now()
const urlParams = new URLSearchParams(window.location.search)
const campaign = urlParams.has('campaign') ? urlParams.get('campaign') : 'undefined'
const plan = urlParams.has('plan') ? urlParams.get('plan') : 'basic'

let debug = urlParams.has('da') ? Boolean(urlParams.get('da')) : false
let forceAnalytics = urlParams.has('fa') ? Boolean(urlParams.get('fa')) : false

if (process.env.REACT_APP_FIREBASE !== "production") { debug = true }
ads.setDebug(debug && !forceAnalytics)
ga.setDebug(debug && !forceAnalytics)

let fbQueue = []
let authUser
let organization


// ['click', 'name-of-event'].forEach(type => {
//   document.addEventListener(type, e => {
//       console.log('event:', e)
//       console.log("event type:", e.type)
//       console.log("event target", e.target)
//       console.log("current target:", e.currentTarget)
//   })
// })


// console.log("URL campaign", campaign)
// console.log("disable_analytics:", debug)


// let trackingID;
// const config = {};
// if(process.env.REACT_APP_FIREBASE === "production") {
//   //console.log("init prod track")
//   trackingID = 'UA-142874795-1';
//   config.gaOptions = {
//     siteSpeedSampleRate: 100,
//   }
// } else {
//   //console.log("init dev track")
//   trackingID = 'UA-142909954-1';
//   config.debug = false;
//   config.gaOptions = {
//     siteSpeedSampleRate: 100,
//   }
// }

// ReactGA.initialize(trackingID, config);
// ReactGA.pageview(window.location.pathname + window.location.search);

//
// console.log("testing _initialized:", window.FB ? window.FB._initialized : 'undefined FB')
// console.log("testing FB defined:", Boolean(window.FB))
// console.log("testing init exists:", Boolean(window.fbAsyncInit))
// console.log("init analytics", window.fbAsyncInit)
// console.log("FB:", window.FB)
// console.log("window._fbInit:", window._fbInit ? true : false)

const initInterval = setInterval(initTimer, 100)

function initTimer() {
  // console.log("timer called")
  // console.log("window._fbInit:", window._fbInit ? true : false)
  if(window._fbInit){
    //console.log("clearing timer")
    clearInterval(initInterval)
    appView()
    fbQueue.forEach(e => dispatchLogEventFB(e.eventName, e.valueToSum, e.params))
    fbQueue = []
  }
}


//TODO: update jquery then change to $(window).unload
// $(window).on("unload", function(e) {
//   if(window.location.pathname === "/trial") {
//     var params = {};
//     params['duration'] = Math.round(Date.now() - startTime)
//     window.FB.AppEvents.logEvent('trial_session_event', null, params)
//   }
// });

//FOR TESTING, dialog permits console inspection
// window.addEventListener('beforeunload', function (e) {
//   e.preventDefault();
//   e.returnValue = '';
//
//   const params = {}
//   const duration = Math.round((Date.now() - startTime)/1000)
//   params['duration'] = duration
//   params['campaign'] = campaign
//   params['isTrial'] = Boolean(window.location.pathname === "/trial")
//   //window.FB.AppEvents.logEvent('test_session_event', duration, params)
//   console.log("sent session event")
// });

/**
 * @param {string} eventName
 * @param {float} valueToSum
 * @param {object} params
 */
const dispatchLogEventFB = (eventName, valueToSum, params = {}) => {
  if (!window._fbInit) {
    fbQueue.push({ eventName, valueToSum, params })
  } else {
    try {
      params['campaign'] = campaign
      params['path'] = window.location.pathname
      if(authUser) { params['uid'] = authUser.uid }
      if(organization) { params['oid'] = organization.id }
      if(!debug) {
        window.FB.AppEvents.logEvent(eventName, valueToSum, params)
      } else {
        console.log(`FB event ${eventName}`, valueToSum, params)
      }
    } catch (e) {
      console.log(`${eventName} exception`, e)
    }
  }

}

const duration = () => Math.round((Date.now() - startTime)/1000)

export const accountCreated = ({ uid, oid }) => {
  const params = {}
  if(uid) {params['uid'] = uid}
  if(oid) {params['oid'] = oid}
  dispatchLogEventFB('account_created', null, params)
}

export const appView = () => {
  dispatchLogEventFB('app_view')
  dispatchLogEventFB(`app_view_${campaign}`)
}

export const onboardLoaded = () => {
  ga.dispatch("Onboard Loaded", {
    'send_to': gaTrackingId,
    'event_category': "Trial",
  })
  dispatchLogEventFB(`onboard_loaded_${campaign}`)
  dispatchLogEventFB(`onboard_loaded`)
}

export const onboardFreeTrial = () => {
  ga.dispatch("Free Trial Clicked", {
    'send_to': gaTrackingId,
    'event_category': "Trial"
  })
  ads.dispatchConversion(conversions.onboard_trial_button_clicked)
  dispatchLogEventFB(`onboard_free_trial_${campaign}`)
  dispatchLogEventFB(`onboard_free_trial`)
}

export const onboardChooseWebsite = () => {
  ga.dispatch("Chose Website", {
    'send_to': gaTrackingId,
    'event_category': "Trial",
  })
  ads.dispatchConversion(conversions.onboard_choose_website)
  dispatchLogEventFB(`onboard_choose_website_${campaign}`)
  dispatchLogEventFB(`onboard_choose_website`)
}

export const onboardChooseInvoice = () => {
  ga.dispatch("Chose Invoice", {
    'send_to': gaTrackingId,
    'event_category': "Trial",
  })
  ads.dispatchConversion(conversions.onboard_choose_invoice)
  dispatchLogEventFB(`onboard_choose_invoice_${campaign}`)
  dispatchLogEventFB(`onboard_choose_invoice`)
}

export const onboardFBSignUp = () => {
  ga.dispatch("Facebook Sign Up", {
    'send_to': gaTrackingId,
    'event_category': "Trial",
  })
  ads.dispatchConversion(conversions.onboard_fb_sign_up)
  dispatchLogEventFB(`onboard_fb_sign_up_${campaign}`)
  dispatchLogEventFB(`onboard_fb_sign_up`)
}

export const onboardEmailSignUp = () => {
  ga.dispatch("Email Sign Up", {
    'send_to': gaTrackingId,
    'event_category': "Trial",
  })
  ads.dispatchConversion(conversions.onboard_email_sign_up)
  dispatchLogEventFB(`onboard_email_sign_up_${campaign}`)
  dispatchLogEventFB(`onboard_email_sign_up`)
}

export const actionsClick = (category, label) => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': category,
    'event_label': label
  }

  ga.dispatch("Action Click", params)
}

export const addButtonClick = (category, label) => {
  let params
  if (label) {
    params = {
      'send_to': gaTrackingId,
      'event_category': category,
      'event_label': label
    }
  } else {
    params = {
      'send_to': gaTrackingId,
      'event_category': category
    }
  }

  ga.dispatch("Add Click", params)
}

export const appBarClick = (eventLabel) => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': "App Bar",
    'event_label': eventLabel
  }

  ga.dispatch("Navigation", params)
}

export const drawerClick = (eventLabel) => {
  if(eventLabel) {
    const params = {
      'send_to': gaTrackingId,
      'event_category': "Drawer",
      'event_label': eventLabel
    }

    ga.dispatch("Navigation", params)
  }
}

export const filterClick = (category, label) => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': category,
    'event_label': label
  }

  ga.dispatch("Filter", params)
}

export const itemClick = (category, label) => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': category,
    'event_label': label
  }

  ga.dispatch("Item Click", params)
}

export const appLoaded = () => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': 'Loading'
  }

  ga.dispatch("Loading Finished", params)
}

export const tabClick = (category, label) => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': category,
    'event_label': label
  }

  ga.dispatch("Navigation", params)
}

export const menuItemClick = (itemName) => {
  if(itemName) {
    const params = {
      'send_to': gaTrackingId,
      'event_category': "Menu Item",
      'event_label': itemName
    }

    ga.dispatch("Navigation", params)
  }
}

export const missionAbort = (category, label) => {
  ga.dispatch("Mission Aborted", {
    'send_to': gaTrackingId,
    'event_category': category,
    'event_label': label
  })
}

export const missionStart = (eventName, missionId) => {
  const params = {
    'send_to': gaTrackingId,
    'event_category': missionId,
    'event_label': `Mission Start`
  }
  ga.dispatch("Mission Step", params)
  dispatchLogEventFB(eventName)
}

//TODO use some form of functional composition to handle constructing
//event names and params. Perhaps currying
export const missionStep = (eventName, mission) => {
  switch (eventName) {
    case "m_website-1_step-4":
      ads.dispatchConversion(conversions.onboard_mission_ws_preview, eventName)
      break;
    case "m_website-1_step-5":
      ads.dispatchConversion(conversions.onboard_mission_ws_completed, eventName)
      break;
    case "m_invoice-1_step-3":
      ads.dispatchConversion(conversions.onboard_mission_invoice_client_added, eventName)
      break;
    case "m_invoice-1_step-7":
      ads.dispatchConversion(conversions.onboard_mission_invoice_completed, eventName)
      break;
    default:
  }

  const { id, prevStepVal } = mission
  if(id && prevStepVal) {
    const params = {
      'send_to': gaTrackingId,
      'event_category': id,
      'event_label': prevStepVal.primaryType
    }
    ga.dispatch("Mission Step", params)
  }

  dispatchLogEventFB(eventName)
}

export const logIn = (_authUser, _organization) => {
  authUser = _authUser
  organization = _organization

  ga.dispatch("Log In", {
    'send_to': gaTrackingId,
    'event_category': "Auth"
  })

  const params = {}
  params['duration'] = duration()
  dispatchLogEventFB('log_in', null, params)
}

export const logOut = () => {
  ga.dispatch("Log Out", {
    'send_to': gaTrackingId,
    'event_category': "Auth"
  })

  const params = {}
  params['duration'] = duration()
  dispatchLogEventFB('log_out', null, params)

  authUser = undefined
  organization = undefined
}

export const exception = (description, dimensions, isFatal = false) => {
  ga.dispatch('exception', {
    'send_to': gaTrackingId,
    'description': description,
    'fatal': isFatal
  })
}
