import { db, oid } from '../firebase';
import { createBidTask, updateBid } from './bids';

export const removeBidTasks = (bidId) => {
  const ref = db.ref(`appData/${oid}/bidTasks`);
  const promises = [];
  ref.orderByChild("bidId").equalTo(bidId).once('value')
    .then((snapshot) => {
      snapshot.forEach((childSnapshot) => {
        promises.push(ref.child(childSnapshot.key).remove());
      });
    });

    return Promise.all(promises)
};

//TODO: refactor, this code is beyond horrible.
export const replaceBidTasks = (bidId, bidTasks) => {

    //first archive all existing tasks for the bid id
    //second for each bidTask push to db
    return removeBidTasks(bidId)
      .then(() => {
        const promises = bidTasks.map((bidTask) => {
          return createBidTask(bidTask);
        });

        return Promise.all(promises)
      });
};

export const createBidTemplateMaintenance = (bidId) => {
  const bidData = {
    type: "recurring",
  };

  const templateData = {
    showHeader: true,
    showSales: true,
    showTasks: true,
    showSummary: true,
    showCredibility: true,
    sales: {
      showBasic: true,
      showSituation: false,
      showIntroduction: true,
    },
    credibility: {
      showBasic: true,
      showLogos: true,
      showNumbers: false,
    },
  };

  const bidTasks = [
    {
      bidId: bidId,
      type: "maintenance"
    }
  ];

  return db.ref(`appData/${oid}/bids/${bidId}/template`).update(templateData)
          .then(() => replaceBidTasks(bidId, bidTasks))
          .then(() => updateBid(bidId, bidData))
}

export const createBidTemplateEstimate = (bidId) => {
  const bidData = {
    type: "estimate",
  };

  const templateData = {
    showHeader: true,
    showSales: true,
    showTasks: true,
    showSummary: true,
    showCredibility: true,
    sales: {
      showBasic: true,
      showSituation: false,
      showIntroduction: false,
    },
    credibility: {
      showBasic: true,
      showLogos: false,
      showNumbers: false,
    },
  };

  const bidTasks = [
    {
      bidId: bidId,
      type: "simple"
    }
  ];

  return db.ref(`appData/${oid}/bids/${bidId}/template`).update(templateData)
          .then(() => replaceBidTasks(bidId, bidTasks))
          .then(() => updateBid(bidId, bidData))
}

export const createBidTemplateQuote = (bidId) => {
  const bidData = {
    type: "proposal",
  };

  const templateData = {
    showHeader: true,
    showSales: true,
    showTasks: true,
    showSummary: true,
    showCredibility: true,
    sales: {
      showBasic: true,
      showSituation: true,
      showIntroduction: false,
    },
    credibility: {
      showBasic: true,
      showLogos: true,
      showNumbers: true,
    },
  };

  const bidTasks = [
    {
      bidId: bidId,
      type: "quote"
    }
  ];

  return db.ref(`appData/${oid}/bids/${bidId}/template`).update(templateData)
          .then(() => replaceBidTasks(bidId, bidTasks))
          .then(() => updateBid(bidId, bidData))
}
