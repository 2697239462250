import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import AddIcon from '@material-ui/icons/Add';

import * as routes from 'constants/routes';
import {
  displayAcceptMission,
  startMission,
  saveCompletedMission,
  startSetShowTour,
} from 'store/actions/help';
import { startEditAdmin } from 'store/actions/admin';
import { illustrations } from 'components/Illustration';
import AppStructure from 'img/AppStructure.png';
import MissionIcon from 'img/icons8-rocket-96.png';

const styles = theme => ({
  button: {
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
  fab: {
    color: '#fff',
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
});


class Intro extends Component {
  state = {
    currentStep: 0,
  };


  renderMission() {
    const {
      classes,
      history,
      help,
      trialExpired,
      name,
      campaign,
      plan,
      showDialog,
      startMission,
      startEditAdmin,
      startSetShowTour,
      displayAcceptMission,
    } = this.props;

    if(plan === "ws" || campaign === "wschallenge") {
      return(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            style={{ marginBottom: '10px' }}
            onClick={() => {
              displayAcceptMission("website-1");
              //saveCompletedMission("intro-1");
              // history.push(routes.WEBSITE);
              // startMission("website-1");
            }}
            color="primary"
            variant="contained"
          >
            Challenge: Build your website!
          </Button>
        </div>
      );
    } else {
      return(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            style={{ marginBottom: '10px' }}
            onClick={() => {
              displayAcceptMission("website-1");
              //saveCompletedMission("intro-1");
              // history.push(routes.WEBSITE);
              // startMission("website-1");
            }}
            color="primary"
            variant="contained"
          >
            Mission 1: Build your website!
          </Button>
          <Typography
            style={{ alignSelf: 'center', marginBottom: '10px' }}
          >
          OR
          </Typography>
          <Button
            onClick={() => {
              displayAcceptMission("invoice-1");
              // saveCompletedMission("intro-1");
              // startEditAdmin({ invoice: null });
              // history.push(routes.INVOICES);
              // startMission("invoice-1");
            }}
            color="primary"
            variant="contained"
          >
            Mission 2: Do a quickie invoice
          </Button>
        </div>
      );
    }
  }

  render() {
    const {
      classes,
      history,
      help,
      trialExpired,
      name,
      campaign,
      showDialog,
      startMission,
      startEditAdmin,
      startSetShowTour,
      displayAcceptMission,
    } = this.props;
    const { currentStep } = this.state;

    if(trialExpired) {
      return <div />
    }

    //TODO: remove second step, show last step if not first step and not tour mode
    return (
      <Dialog
        open={help.showTour ? false : showDialog}
        className={classes.root}
      >
        <DialogContent>
          <div style={{ width: '530px', height: '500px' }}>
            {currentStep === 0 &&
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{ marginTop: '30px', marginBottom: '30px', alignSelf: 'center' }}
                  variant="title"
                >
                  {`Welcome ${name}!`}
                </Typography>
                <Typography
                  align='center'
                  style={{ marginBottom: '20px' }}
                >
                  Let's see how Couraggo manages your paperwork and easily builds a professional website.
                </Typography>
                <img
                  style={{
                    width: '250px',
                    height: '250px',
                    alignSelf: 'center',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  alt=""
                  src={illustrations.get("empty_amber")} />
                <div style={{ alignSelf: 'center', marginBottom: '30px' }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      // startEditAdmin({ invoice: null });
                      // history.push(routes.INVOICES);
                      // startMission("invoice-1");
                      //startSetShowTour(true);
                      this.setState({ currentStep: (currentStep + 1) });
                    }}
                    variant="contained"
                  >
                    Let's open the box!
                  </Button>
                </div>

              </div>

            }
            {currentStep === 1 &&
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{ marginTop: '30px', marginBottom: '30px', alignSelf: 'center' }}
                  variant="title"
                >
                  The Basics...
                </Typography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}>
                  <div>
                    <Typography align='center'>
                      Look for it on each screen to Add stuff!
                    </Typography>
                  </div>
                  <Button
                    style={{ marginTop: '20px' }}
                    className={classes.fab}
                    variant="fab"
                  >
                    <AddIcon />
                  </Button>
                </div>
                <div style={{
                    marginBottom: '30px', marginTop: '30px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                  <div>
                    <Typography style={{ marginBottom: '30px', }}>
                      Most areas have three sections:
                    </Typography>
                    <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold', color: red[500], marginRight: '5px' }}>
                        {`1 -`}
                      </Typography>
                      <Typography >
                        Main Menu
                      </Typography>
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold', color: red[500], marginRight: '5px' }}>
                        {`2 -`}
                      </Typography>
                      <Typography >
                        Cards of Invoices, Receipts, etc...
                      </Typography>
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold', color: red[500], marginRight: '5px' }}>
                        {`3 -`}
                      </Typography>
                      <Typography >
                        Our innovative Help Panel
                      </Typography>
                    </div>

                  </div>
                  <img
                    style={{ width: '190px', height: '190px' }}
                    src={AppStructure}
                    alt="App Structure"
                  />
                </div>
                <div style={{ alignSelf: 'center', marginBottom: '20px' }}>
                  <Button
                    style={{ width: '250px' }}
                    color="primary"
                    onClick={() => this.setState({ currentStep: (currentStep + 1) })}
                    variant="contained"
                  >
                    Next
                  </Button>
                </div>

              </div>
            }

            {currentStep === 2 &&
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{ marginTop: '30px', marginBottom: '30px', alignSelf: 'center' }}
                  variant="title"
                >
                  Your mission, if you chose to accept...
                </Typography>
                <div style={{
                    marginLeft: '15px',
                    marginTop: '30px',
                    marginBottom: '30px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                  <Typography style={{ maxWidth: '300px' }}>
                    Couraggo has several missions where you'll learn stuff AND setup your business!
                  </Typography>
                  <img
                    style={{ width: '150px', height: '150px' }}
                    alt=""
                    src={illustrations.get("mission_impossible_amber")} />
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                  <div style={{ width: '300px', alignSelf: 'center' }}>
                    {this.renderMission()}
                  </div>



                  <div style={{
                      marginTop: '30px', marginLeft: '15px', marginRight: '15px',
                      display: 'flex', flexDirection: 'row'
                  }}>
                    <Typography style={{ marginRight: '10px' }}>
                      Note:
                    </Typography>
                    <Typography
                      style={{ }}
                    >
                      You’ll find these and many more missions at our Spaceship button, top right of page
                    </Typography>
                    <img
                      style={{ marginLeft: '10px', width: '35px', height: '35px' }}
                      src={MissionIcon}
                      alt="Missions"
                    />
                  </div>

                </div>
              </div>
            }




          </div>

        </DialogContent>

        <DialogActions>
          <div style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '15px',
          }}>
            {currentStep === 0 &&
              <div />
            }
            {currentStep > 0 &&
              <Button
                onClick={() => this.setState({ currentStep: (currentStep - 1) })}
              >
                Back
              </Button>
            }

            <Typography color='primary' variant='body2'>
              {`${currentStep + 1} of 3`}
            </Typography>
            {false && currentStep === 2 &&
              <Button
                onClick={() => saveCompletedMission("intro-1")}
              >
                Skip
              </Button>
            }
          </div>
        </DialogActions>

      </Dialog>
    );
  }
}

Intro.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const help = state.help;
  const { user, organizations } = state.accountState;
  const profile = user ? user.profile : {};
  const organization = organizations[0] ? organizations[0] : {};
  const about = organization.about ? organization.about : {};
  const { campaign } = organization;
  const missions = state.help.missions.filter(mission => (mission.type === "intro" || mission.type ==="trial"));
  let showDialog = true;
  missions.forEach(mission => {
    if(mission.isCompleted) {
      showDialog = false;
    }
  });

  const subscription = state.subscription;
  const trialExpired = subscription.trialExpired && !subscription.isSubscribed;

  return ({
    name: profile.username,
    showDialog,
    help,
    trialExpired,
    campaign,
    plan: subscription.plan,
  });
}

const mapDispatchToProps = {
  startMission,
  startEditAdmin,
  startSetShowTour,
  displayAcceptMission
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Intro);
