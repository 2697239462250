import { db } from '../../firebase';


export const startEditNote = (noteId, updates) => {
  return (dispatch) => db.updateNote(noteId, updates);
}
export const startAddNote = (note) => {
  return (dispatch) => db.createNote(note);
}

export const setNotes = (notes) => ({
  type: 'SET_NOTES',
  notes
});

export const startSetNotes = () => {
  return (dispatch) => db.getNotes().on('value', (snapshot) => {
    const notes = [];

    snapshot.forEach((childSnapshot) => {
      const note = {id: childSnapshot.key, ...childSnapshot.val()};
      notes.push(note);
    })

    dispatch(setNotes(notes));
  });
};
