import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import amber from '@material-ui/core/colors/amber'
import yellow from '@material-ui/core/colors/yellow'

import tooltips from './Types'
import {
  helpDispatch
} from 'components/admin/Help'

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.tooltipAction,
    width: '100%',
    backgroundColor: yellow[100],
  },
  fixed: {
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing.unit,
  },
  nextButton: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    '&:hover': {
     backgroundColor: amber[700],
    },
  },
  subtitle: {
    fontSize: '12px',
  }
})

const TooltipBar = ({ classes, fixed, isMobile, help }) => {
  //console.log("TOOLTIP BAR HELP:", help)
  const currentMission = help.currentMission
  const currentStepIndex = currentMission.currentStep
  const currentStep = currentMission.steps[currentStepIndex]
  //console.log("CURRENT STEP:", currentStep)

  if (isMobile && currentStep && !currentStep.success) {
    const tooltip = tooltips.get(currentStep.primaryType)
    return(
      <div id="tootip-bar" className={classnames(classes.root, fixed && classes.fixed)}>
        <div className={classes.content}>
          <Typography className={classes.subtitle}>
            {tooltip.subtitle}
          </Typography>
          {tooltip.nextDispatch &&
            <div>
              <Button
                className={classes.nextButton}
                onClick={() => helpDispatch(tooltip.nextDispatch)}
              >
                Next
              </Button>
            </div>
          }
        </div>
      </div>
    )
  } else {
    return <div id="tootip-bar" />
  }
}

const mapStateToProps = state => ({
  isMobile: state.adminState.isMobile,
  help: state.help,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(TooltipBar)
