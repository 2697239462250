import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const MenuTooltip = ({ children, show, title }) => {
  if(show) {
    return (
      <Tooltip title={title} placement="right">
        {children}
      </Tooltip>
    );
  } else {
    return children;
  }
}

export default MenuTooltip;
