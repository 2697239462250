import { db } from '../../firebase';

export const setTaxRules = (taxRules) => ({
  type: 'SET_TAX_RULES',
  taxRules
});

export const startSetTaxRules = () => {
  return (dispatch) => {
    return db.getTaxRules().on('value', (snapshot) => {
      const taxRules = [];
      snapshot.forEach((childSnapshot) => {
        taxRules.push(Object.assign({id: childSnapshot.key}, childSnapshot.val()));
      });

      dispatch(setTaxRules(taxRules));
    });
  };
};
