const defaultState = [];


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_FILES':
      //console.log("setting files:", action.files);
      return action.files;
    default:
      return state;
  }
};
