import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classNames from 'classnames'

import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import grey from '@material-ui/core/colors/grey'
import avatar from '@material-ui/core/colors/grey'

import { analytics } from 'analytics'

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
  },
  avatar: {
    marginRight: theme.spacing.unit*2,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    color: '#fff',
    backgroundColor: avatar[500],
  },
  column: {
    minWidth: '150px',
    marginRight: theme.spacing.unit*2,
  },
  phone: {
    minWidth: '100px',
    marginRight: theme.spacing.unit*2,
  },
  client: {
    width: "160px",
    marginRight: theme.spacing.unit*2,
  },
  divider: {
    height: '30px',
    maxWidth: '2px',
    minWidth: '2px',
    margin: '0px 0px',
    background: grey[300],
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: grey[100],
    }
  },
  total: {
    marginLeft: theme.spacing.unit*2,
    marginRight: theme.spacing.unit*2,
  },
});

const getErrors = (validation) => {
  return (
    <div>
      {Object.entries(validation.errors).map((entry, i) => <Typography key={i}>{`${entry[0]}: ${entry[1]}`}</Typography>)}
    </div>
  );
};

const ClientCard = ({ classes, client, validation, handleEdit }) => {
  return(
    <Card className={classes.root}>
      <div
        className={classes.content}
        onClick={() => {
          analytics.itemClick("SETUP", "Client Card")
          handleEdit(client)
        }}
      >
        <Avatar className={classes.avatar}>
          C
        </Avatar>
        <div className={classes.client}>
          <Typography noWrap variant="subheading">
            {client.fullName}
          </Typography>
          {client.email &&
            <Typography noWrap variant="caption">
              {client.email}
            </Typography>
          }
        </div>
        {client.homePhone &&
          <div className={classes.phone}>
            <Typography variant="subheading">
              Home Phone
            </Typography>
            <Typography variant="caption">
              {client.homePhone}
            </Typography>
          </div>
        }
        {client.mobilePhone &&
          <div className={classes.phone}>
            <Typography variant="subheading">
              Mobile Phone
            </Typography>
            <Typography variant="caption">
              {client.mobilePhone}
            </Typography>
          </div>
        }
        <div>
          <Typography variant="subheading">
            {client.city}
          </Typography>
          <Typography variant="caption">
            {client.address}
          </Typography>
        </div>
        {validation.isInvalid &&
          <div>
            {getErrors(validation)}
          </div>
        }
      </div>
    </Card>
  );
};

const mapStateToProps = (state, props) => {
  const { client } = props;
  let validation = {};
  if (client.id) {
    const result = state.validation.clients.find(clientValidation => client.id === clientValidation.id);
    if (result) {
      validation = result;
    }
  }

  return {
    validation,
  }
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(ClientCard);
