const defaultState = [];


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_SERVICE_ICONS':
      return action.serviceIcons;
    default:
      return state;
  }
};
