import React from 'react';
import { connect } from 'react-redux';

import {
  Dialog
} from '../../core';

import {
  VendorForm,
} from '../Vendors';

import {
  setVendorForm,
  startRemoveVendor,
  startOnDoneVendor,
  resetVendorForm,
} from '../../../store/actions';
import { showVendorFormValidation } from 'store/actions/validation';

const VendorDialog = (props) => {
  const {
    vendor,
    show,
    handleCreated,
    handleClose,
    resetVendorForm,
    startOnDoneVendor,
    startRemoveVendor,
    showVendorFormValidation,
  } = props;

  return (
    <Dialog
      confirmDelete
      deleteTitle="Delete Vendor"
      deleteText="This action will delete the vendor. Proceed?"
      show={show}
      handleCreated={handleCreated}
      title="Vendor"
      type={"edit"}
      onDone={ () => startOnDoneVendor() }
      onDelete={ () => startRemoveVendor() }
      onClose={() => {
        resetVendorForm();
        handleClose();
      }}
      validate={() => {
        const { validation } = vendor;
        if (validation && validation.isInvalid) {
          showVendorFormValidation();
          return false;
        } else {
          return true;
        }

      }}
    >
      <VendorForm />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.formData.vendor,
});

const mapDispatchToProps = {
  setVendorForm,
  showVendorFormValidation,
  startRemoveVendor,
  startOnDoneVendor,
  resetVendorForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDialog);
