import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import { editVendorForm } from '../../../store/actions';

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit*3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const VendorForm = ({ classes, vendor, editVendorForm }) => {
  const { validation } = vendor;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <TextField
          error={!!validation.validateField("name")}
          helperText={validation.validateField("name")}
          fullWidth
          label="Name"
          margin="normal"
          value={vendor && vendor.name}
          onChange={(event) => editVendorForm({name: event.target.value})}
        />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.formData.vendor,
});

const mapDispatchToProps = {
  editVendorForm,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(VendorForm);
