import { db } from '../../../firebase';

export const editQuoteForm = (updates) => ({
  type: "EDIT_QUOTE_FORM",
  updates
});

export const setQuoteForm = (quote) => ({
  type: "SET_QUOTE_FORM",
  quote
});

export const resetQuoteForm = () => ({
  type: "RESET_QUOTE_FORM"
});

export const addQuoteItem = () => ({
  type: "ADD_QUOTE_ITEM"
});

export const editQuoteItem = () => ({
  type: "EDIT_QUOTE_ITEM"
});

export const removeQuoteTaskItem = (id, itemType) => ({
  type: "REMOVE_QUOTE_TASK_ITEM",
  id,
  itemType,
});

export const startOnDoneQuote = () => {
  return (dispatch, getState) => {
    const quote = getState().formData.quote;
    const {
      id,
      title,
      description,
      projectId,
      labor,
      materials,
      type = "quote",
    } = quote;

    if (id) {
      const payload = {
        title,
        description,
        projectId,
        type,
      };
      //remove undefined properties
      Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);

      return db.updateProjectTask(id, payload)
        .then(() => {
          const promises = [];
          labor.forEach(item => {
            if (item.isDelete) {
              promises.push(db.deleteProjectTaskLaborItem(id, item.id));
            } else {
              const { id: laborId, ...updates } = item;
              if (laborId) {
                promises.push(db.updateProjectTaskLaborItem(id, laborId, updates));
              } else {
                promises.push(db.createProjectTaskLaborItem(id, updates));
              }
            }
          });
          materials.forEach(item => {
            if (item.isDelete) {
              promises.push(db.deleteProjectTaskMaterialsItem(id, item.id));
            } else {
              const { id: materialsId, ...updates } = item;
              if (materialsId) {
                promises.push(db.updateProjectTaskMaterialsItem(id, materialsId, updates));
              } else {
                promises.push(db.createProjectTaskMaterialsItem(id, updates));
              }
            }
          });

          return Promise.all(promises);
        });
    } else {
      const payload = {
        title,
        description,
        projectId,
        labor,
        materials,
        type,
      };
      //remove undefined properties
      Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);

      return db.createProjectTask(payload);
    }
  }
};

export const startRemoveQuote = () => {
  return (dispatch, getState) => {
    const quote = getState().formData.quote;
    return Promise.resolve();
    //const crew = getState().formData.crew;
    //return db.deleteCrew(crew.id);
  }
};
