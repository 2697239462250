const defaultState = {
  root: {
    heroImage: {

    },
  },
  services: [],
  testimonials: [],
  portfolio: [],
  team: [],
  faqs: [],
  documents: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case "SET_WEBSITE_ROOT":
      return { ...state, root: action.root }
    case "SET_WEBSITE_SERVICES":
      return { ...state, services: action.services }
    case "SET_WEBSITE_TESTIMONIALS":
      return { ...state, testimonials: action.testimonials }
    case "SET_WEBSITE_PORTFOLIO":
      return { ...state, portfolio: action.portfolio }
    case "SET_WEBSITE_TEAM":
      return { ...state, team: action.team }
    case "SET_WEBSITE_FAQS":
      return { ...state, faqs: action.faqs }
    case "SET_WEBSITE_DOCUMENTS":
      return { ...state, documents: action.documents }
    default:
      return state;
  }
};
