import { db } from '../../firebase';
import { analytics } from 'analytics'

export const startRemoveTaskLaborItem = (taskId, itemId) => {
  return (dispatch) => db.deleteTaskLaborItem(taskId, itemId);
}

export const startRemoveTaskMaterialsItem = (taskId, itemId) => {
  return (dispatch) => db.deleteTaskMaterialsItem(taskId, itemId);
}


export const startEditBidTemplate = ({bidId, type, updates}) => {
  return (dispatch) => {
    switch (type) {
      case "sales":
        return db.updateBidTemplateSales(bidId, updates);
      case "credibility":
        return db.updateBidTemplateCredibility(bidId, updates);
      default:
    }
  };
};

export const startEditBidState2 = (bidId, state) => {
  return (dispatch) => (
    db.updateBidState2(bidId, state)
      .catch((error) => {
        console.log('startEditBidState2 ERROR:', error)
        analytics.exception(`bids::startEditBidState2 [message:${error.message}]`)
      })
  );
}

export const startRemoveBid = (bidId) => {
  return (dispatch) => (
    db.deleteBid(bidId)
  );
};

export const startAddBid = (bidData) => {
  return (dispatch) => (
    db.createBid(bidData)
      .catch((error) => {
        console.log('startAddBid ERROR:', error);
        analytics.exception(`bids::startAddBid [message:${error.message}]`)
      })
  );
};

export const startCopyBid = (destinationBidId, sourceBidId) => {
  return (dispatch) => (
    db.copyBid(destinationBidId, sourceBidId)
  );
}

export const startRemoveBidTask = (bidTaskId) => {
  return (dispatch) => (
    db.deleteBidTask(bidTaskId)
  );
};

export const startAddBidTask = (bidTaskData) => {
    return (dispatch) => (
      db.createBidTask(bidTaskData)
        .catch((error) => {
          console.log('startAddBidTask ERROR:', error)
          analytics.exception(`bids::startAddBidTask [message:${error.message}]`)
        })
    );
};

export const startAddBidLabor = (bidTaskId, laborData) => {
    return (dispatch) => (
      db.createBidLabor(bidTaskId, laborData)
        .catch((error) => {
          console.log('startAddBidLabor ERROR:', error)
          analytics.exception(`bids::startAddBidLabor [message:${error.message}]`)
        })
    );
};

export const startEditBidLabor = (bidTaskId, laborId, laborData) => {
  return (dispatch) => (
    db.updateBidLabor(bidTaskId, laborId, laborData)
      .catch((error) => {
        console.log('startEditBidLabor ERROR:', error)
        analytics.exception(`bids::startEditBidLabor [message:${error.message}]`)
      })
  );
};

export const startAddBidMaterials = (bidTaskId, materialsData) => {
    return (dispatch) => (
      db.createBidMaterials(bidTaskId, materialsData)
        .catch((error) => {
          console.log('startAddBidMaterials ERROR:', error)
          analytics.exception(`bids::startAddBidMaterials [message:${error.message}]`)
        })
    );
};

export const startEditBidMaterials = (bidTaskId, materialsId, materialsData) => {
  return (dispatch) => (
    db.updateBidMaterials(bidTaskId, materialsId, materialsData)
      .catch((error) => {
        console.log('startEditBidMaterials ERROR:', error)
        analytics.exception(`bids::startEditBidMaterials [message:${error.message}]`)
      })
  );
};

export const startEditBid= (bidId, bidData) => {
  return (dispatch) => (
    db.updateBid(bidId, bidData)
      .catch((error) => {
        console.log('startEditBid ERROR:', error)
        analytics.exception(`bids::startEditBid [message:${error.message}]`)
      })
  )
}

export const startEditBidTask = (bidTaskId, bidTaskData) => {
    return (dispatch) => (
      db.updateBidTask(bidTaskId, bidTaskData)
        .catch((error) => {
          console.log('startEditBidTask ERROR:', error)
          analytics.exception(`bids::startEditBidTask [message:${error.message}]`)
        })
    );
};

export const setBidTasks = (bidTasks) => ({
    type: 'SET_BID_TASKS',
    bidTasks
});

export const startSetBidTasks = () => {
  return (dispatch) => {
    return db.getBidTasks().on('value', (snapshot) => {
      const bidTasks = [];

      snapshot.forEach((childSnapshot) => {
        const {
          id,
          bidId,
          type,
          title,
          description,
          estimate,
          monthlyAmount,
          optional,
          scheduleStart,
          scheduleFrequency,
          scheduleOccurences,
          scheduleHours,
          dateCreated
        } = Object.assign({id: childSnapshot.key}, childSnapshot.val());

        //TODO: move to a function
        const labor = childSnapshot.val().labor !== undefined
          ? Object.entries(childSnapshot.val().labor).map(item => {
              return { id: item[0] , ...item[1]}
            })
          : []

        const materials = childSnapshot.val().materials !== undefined
          ? Object.entries(childSnapshot.val().materials).map(item => {
              return { id: item[0] , ...item[1]}
            })
          : []

        const bidTask = {
          id,
          bidId,
          type,
          title,
          description,
          estimate,
          monthlyAmount,
          optional,
          scheduleStart,
          scheduleFrequency,
          scheduleOccurences,
          scheduleHours,
          labor,
          materials,
          dateCreated
        };

        bidTask.labor.forEach((item) => {
          Object.defineProperty(item, 'total', {
            get() {
              return (this.quantity*this.rate);
            },
            configurable: true,
            enumerable: true,
          });
        });

        bidTask.materials.forEach((item) => {
          Object.defineProperty(item, 'total', {
            get() {
              return (this.quantity*this.rate);
            },
            configurable: true,
            enumerable: true,
          });
        });

        Object.defineProperty(bidTask, 'laborTotal', {
          get() {
            let total = 0;
            this.labor.forEach(function(item) {
              total += item.total;
            })

            return total;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(bidTask, 'materialsTotal', {
          get() {
            let total = 0;
            this.materials.forEach(function(item) {
              total += item.total;
            })

            return total;
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(bidTask, 'subtotal', {
          get() {
            if (this.type === "estimate" || this.type === "simple") {
              return Number(this.estimate === undefined ? 0 : this.estimate);
            } else if (this.type === "maintenance") {
              return  Number(this.monthlyAmount === undefined ? 0 : this.monthlyAmount);;
            } else {
              return this.laborTotal + this.materialsTotal;
            }

          },
          configurable: true,
          enumerable: true,
        });

        //TODO: implement tax based on db setting
        Object.defineProperty(bidTask, 'gst', {
          get() {
            return this.subtotal*(0.05);
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(bidTask, 'pst', {
          get() {
            return this.subtotal*(0.00);
          },
          configurable: true,
          enumerable: true,
        });

        Object.defineProperty(bidTask, 'grandTotal', {
          get() {
            return this.subtotal + this.gst + this.pst;
          },
          configurable: true,
          enumerable: true,
        });

        bidTasks.push(bidTask);
      });

      dispatch(setBidTasks(bidTasks));
      dispatch({ type: 'VALIDATE_BIDS' });
    });
  }
};

export const setBids = (bids) => ({
  type: 'SET_BIDS',
  bids
});

export const startSetBids = () => {
  return (dispatch) => {
    return db.getBids().on('value', (snapshot) => {
      const bids = [];

      snapshot.forEach((childSnapshot) => {
        //TODO:
        //1. properly implement destructuring
        const {
          id,
          bidNumber,
          code,
          contact,
          date,
          dueDate,
          createdDate,
          situation,
          solution,
          notes,
          terms,
          taxNumber,
          companyNumber,
          template,
          isTemplate,
          status,
          clientId,
          introduction,
          state,
          type,
        } = Object.assign({id: childSnapshot.key}, childSnapshot.val());

        const bid = {
          id,
          bidNumber,
          code,
          contact,
          date,
          dueDate,
          createdDate,
          situation,
          solution,
          notes,
          terms,
          taxNumber,
          companyNumber,
          template,
          isTemplate,
          status,
          clientId,
          introduction,
          state,
          type
        };

        bids.push(bid);
      });

      dispatch(setBids(bids));
      dispatch({ type: 'VALIDATE_BIDS' });
    });
  };
};
