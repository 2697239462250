
export const showClientFormValidation = () => ({
  type: 'SHOW_CLIENT_FORM_VALIDATION'
});

export const showCrewFormValidation = () => ({
  type: 'SHOW_CREW_FORM_VALIDATION'
});

export const showVendorFormValidation = () => ({
  type: 'SHOW_VENDOR_FORM_VALIDATION'
});

export const showWorkTypeFormValidation = () => ({
  type: 'SHOW_WORK_TYPE_FORM_VALIDATION'
});

export const showTaxCategoryFormValidation = () => ({
  type: 'SHOW_TAX_CATEGORY_FORM_VALIDATION'
});
