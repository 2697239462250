import { db, oid } from '../firebase';

export const getUserOrganizations = (uid) =>
  db.ref(`users/${uid}/organizations`).once('value');

export const createOrganization = (organization) =>
  db.ref('organizations').push(organization);

export const getOrgDomains = () =>
  db.ref('orgDomains').once('value');

export const createOrgDomain = (orgDomain) =>
  db.ref('orgDomains').push(orgDomain);

export const setWebsiteRoot = (oid, root) =>
  db.ref(`appData/${oid}/website/root`).set(root);
  // export const updateWebsiteRoot = (updates) =>
  //   db.ref(`appData/${oid}/website/root`)
  //     .update(updates);
  //

export const setTaxRules = (oid, taxRules) =>
  db.ref(`appData/${oid}/taxRules`).set(taxRules);

export const createTaxCategoryWithOID = (oid, taxCategory) =>
  db.ref(`appData/${oid}/taxCategories`).push(taxCategory);

export const createTaxCategory = (taxCategory) =>
  db.ref(`appData/${oid}/taxCategories`).push(taxCategory);

export const createUser = (id, user) =>
  db.ref(`users/${id}`).set(user);

export const onceGetUsers = () =>
  db.ref('users').once('value');

export const getUser = (uid) =>
  db.ref(`users/${uid}`);

export const getOrganization = (oid) =>
  db.ref(`organizations/${oid}`);

export const updateUserProfile = (uid, updates) =>
  db.ref(`users/${uid}/profile`).update(updates);

export const updateOrganizationAbout = (in_oid = oid, updates) =>
  db.ref(`organizations/${in_oid}/about`).update(updates);

export const createSubscription = (oid, subscription) =>
  db.ref(`organizations/${oid}/subscription`).set(subscription);

export const getSubscription = () =>
  db.ref(`organizations/${oid}/subscription`);

export const updateSubscription = (updates) =>
  db.ref(`organizations/${oid}/subscription`).update(updates);
