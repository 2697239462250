const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_COMPANY_INFO':
      return action.companyInfo;
    default:
      return state;
  }
};
