import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AnalyticsProvider extends Component {

  getChildContext() {
    return { analytics: this.props.analytics }
  }

  render = () => this.props.children
}

AnalyticsProvider.childContextTypes = {
  analytics: PropTypes.object
}

export default AnalyticsProvider
