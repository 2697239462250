import { db } from '../../firebase';

export const setVendors = (crew) => ({
  type: 'SET_CREW',
  crew
});

//1. Fetch all clients
//2. Parse the data into an array
//3. Dispatch SET_CLIENTS
export const startSetCrew = () => {
  return (dispatch) => {
    return db.getCrew().on('value', (snapshot) => {
      const crew = [];
      snapshot.forEach((childSnapshot) => {
        crew.push(Object.assign({id: childSnapshot.key}, childSnapshot.val()));
      });

      dispatch(setVendors(crew));
    });

  };
};
