import { storage } from '../../firebase'
import { analytics } from 'analytics'
export const setLightLogo = (URL) => ({
  type: 'SET_LIGHT_LOGO',
  URL
});

export const setDarkLogo = (URL) => ({
  type: 'SET_DARK_LOGO',
  URL
});

export const deleteLightLogo = () => ({
  type: 'DELETE_LIGHT_LOGO'
});

export const deleteDarkLogo = () => ({
  type: 'DELETE_DARK_LOGO'
});

export const startSetLightLogo = () => {
  return (dispatch) => {
    storage.getLogoDownloadURL("light")
      .then(URL => {
        return dispatch(setLightLogo(URL));
      })
      .catch(error => {
        console.log("startSetLightLogo error:", error)
        analytics.exception(`logos::startSetLightLogo [message:${error.message}]`)
      })
  }
}

export const startSetDarkLogo = () => {
  return (dispatch) => {
    storage.getLogoDownloadURL("dark")
      .then(URL => dispatch(setDarkLogo(URL)))
      .catch(error => {
        console.log("startSetDarkLogo error:", error)
        analytics.exception(`logos::startSetDarkLogo [message:${error.message}]`)
      })
  }
}

export const startUploadLogo = (fileName, fileData) => {
  return (dispatch) => {
    console.log("UPLOADING", fileData);
    return storage.uploadLogoFile(fileName, fileData);
  }
}

export const startDeleteLogo = (fileName) => {
  return (dispatch) => {
    return (
      storage.deleteLogoFile(fileName)
        .then(() => {
          if(fileName==="light") {
            return dispatch(deleteLightLogo(fileName))
          } else {
            return dispatch(deleteDarkLogo(fileName))
          }
        })
    );
  }
}
