const defaultState = {
  bidList: {
    type: "all",
    orderBy: "date",
    direction: "asc",
    showArchived: false,
  },
  invoiceList: {
    type: "all",
    orderBy: "date",
    direction: "asc",
    showArchived: false,
  },
  projectList: {
    type: "all",
    orderBy: "date",
    direction: "asc",
    showArchived: false,
  },
  receiptList: {
    type: "all",
    orderBy: "date",
    direction: "desc",
    showArchived: false,
  },
  timecardList: {
    type: "all",
    orderBy: "date",
    direction: "desc",
    showArchived: false,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case "EDIT_INVOICE_LIST_FILTER":
      return {...state, invoiceList: {...state.invoiceList, ...action.updates}};
    case "EDIT_PROJECT_LIST_FILTER":
      return {...state, projectList: {...state.projectList, ...action.updates}};
    case "SET_BID_LIST_FILTER":
      return {...state, bidList: action.bidListFilter};
    case "EDIT_BID_LIST_FILTER":
      return {...state, bidList: {...state.bidList, ...action.updates}};
    case "SET_RECEIPT_LIST_FILTER":
      return {...state, receiptList: action.receiptListFilter};
    case "EDIT_RECEIPT_LIST_FILTER":
      return {...state, receiptList: {...state.receiptList, ...action.updates}};
    case "SET_TIMECARD_LIST_FILTER":
      return {...state, timecardList: action.timecardListFilter};
    case "EDIT_TIMECARD_LIST_FILTER":
      return {...state, timecardList: {...state.timecardList, ...action.updates}};
    default:
      return state;
  }
}
