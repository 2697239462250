import types from '../TooltipTypes';

const receiptTooltips = [
  {
    id: types.receipt.ADD,
    title: "Add Receipt",
    subtitle: "Click the yellow plus button or FAB to add a receipt",
    type: "primary",
  },
  {
    id: types.receipt.SAVE_DIALOG,
    title: "Enter Info",
    subtitle: "Enter this receipt info and close",
    type: "primary",
  }
];

export default receiptTooltips;
