
export const editPreview = (updates) => ({
  type: 'EDIT_PREVIEW',
  updates
});

export const startEditPreview = (updates) => {
  return (dispatch) => {
    return dispatch(editPreview(updates));
  }
};

export const setPreview = (previewData) => ({
  type: 'SET_PREVIEW',
  previewData
});

export const startSetPreview = () => {
  return (dispatch) => {
    const previewData = {
      show: false,
      data: {

      }
    };

    dispatch(setPreview(previewData));
  }
};
