const defaultState = {
  authUser: null,
  organization: null,
  isLoading: true,
  hasAuthorized: false,
  hasRedirected: false,
};

const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser,
  organization: action.organization,
});

function sessionReducer(state = defaultState, action) {
  switch(action.type) {
    case 'RESET':
      return defaultState
    case 'AUTH_USER_SET' : {
      return applySetAuthUser(state, action);
    }
    case 'IS_LOADING':
      return { ...state, isLoading: action.value }
    case 'HAS_AUTHORIZED':
      return { ...state, hasAuthorized: action.value }
    case 'HAS_REDIRECTED':
      return { ...state, hasRedirected: action.value }
    default : return state;
  }
}

export default sessionReducer;
