import { db, storage } from '../../firebase';
import { analytics } from 'analytics'

export const startEditReceipt = (id, updates) => {
    return (dispatch) => db.updateReceipt(id, updates)
};

export const startAddReceipt = (receiptData) => {
  return (dispatch) => (
    db.createReceipt(receiptData)
  );
};

export const startRemoveReceipt = (id) => {
  return (dispatch) => db.deleteReceipt(id);
};

export const setReceipts = (receipts) => ({
  type: 'SET_RECEIPTS',
  receipts
});

export const startSetReceipts = () => {
  return (dispatch) => {
    return db.getReceipts().on('value', (snapshot) => {
        const promises = [];
        const receipts = [];

        snapshot.forEach((childSnapshot) => {
          receipts.push({id: childSnapshot.key, ...childSnapshot.val()})
        });

        receipts.forEach(receipt => {
          if(receipt.storageFilename) {
            promises.push(
              storage.getReceiptDownloadURL(receipt.storageFilename)
                .then(result => {
                  receipt.downloadURL = result;
                })
                .catch(error => {
                  console.log("LOAD STORAGE URL FAILED:", error)
                  analytics.exception(`receipts::startSetReceipts [message:${error.message}]`)
                })
            );
          }
        });


        return Promise.all(promises).then(() => dispatch(setReceipts(receipts)));
    });
  };
};
