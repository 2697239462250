import React from 'react';
import { connect } from 'react-redux';

import {
  Dialog
} from '../../core';

import {
  CrewForm,
} from '../Crew';
import {
  helpDispatch,
  Tooltip,
  TooltipTypes,
} from 'components/admin/Help';
import {
  setCrewForm,
  startRemoveCrew,
  startOnDoneCrew,
  resetCrewForm,
} from '../../../store/actions';
import { showCrewFormValidation } from 'store/actions/validation';

const CrewDialog = (props) => {
  const {
    show,
    crew,
    handleCreated,
    handleClose,
    resetCrewForm,
    startOnDoneCrew,
    startRemoveCrew,
    showCrewFormValidation,
  } = props;

  return (
    <Dialog
      confirmDelete
      deleteTitle="Delete Crew Member"
      deleteText="This action will delete the crew member. Proceed?"
      show={show}
      title="Crew"
      type={"edit"}
      handleCreated={handleCreated}
      onDone={ () => {
        return startOnDoneCrew().then(result => {
          helpDispatch();
          return result;
        });
      }}
      onDelete={ () => startRemoveCrew() }
      onClose={() => {
        resetCrewForm();
        handleClose();
      }}
      validate={() => {
        const { validation } = crew;
        if (validation && validation.isInvalid) {
          showCrewFormValidation();
          return false;
        } else {
          return true;
        }
      }}
    >
      <Tooltip type={TooltipTypes.timecard.POPUP_FORM} placement="top">
        <div />
      </Tooltip>
      <Tooltip type={TooltipTypes.CREATE_CREW_DIALOG} placement="right">
        <CrewForm />
      </Tooltip>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  crew: state.formData.crew,
});

const mapDispatchToProps = {
  setCrewForm,
  startRemoveCrew,
  startOnDoneCrew,
  resetCrewForm,
  showCrewFormValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewDialog);
