import React from 'react';

import CardListItem from './CardListItem';

const CardList = ({ children, handleSelect }) =>
  <div>
    {children}
  </div>

export default CardList;
