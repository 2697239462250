const defaultState = [];

//TODO: firebase handles most of the state management,
// refactor so that we only handle cases that are not
// controlled through firebase callbacks
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'ADD_INVOICE':
      return [
        ...state,
        action.invoice
      ];
    case 'EDIT_INVOICE':
      return state
    case 'ADD_LINE_ITEM':
      return state;
    case 'EDIT_LINE_ITEM':
      return state.invoicesState.map((invoice) => {
        if(invoice.id === action.invoiceID) {
          const lineItem = {
            ...invoice.lineItems[action.lineItemID],
            ...action.updates
          }

          invoice.lineItems[action.lineItemID] = lineItem
        }

        return invoice;
      });
    case 'SET_INVOICES':
      return action.invoices;
    default:
      return state;
  }
};
