import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { withStyles } from '@material-ui/core/styles'

import { editClientForm } from 'store/actions'
import {
  helpDispatch,
  Tooltip,
  TooltipTypes
} from 'components/admin/Help'

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit*3,
    maxWidth: '700px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  email: {
    flexGrow: 1,
  },

})

const ClientForm = ({ classes, client, editClientForm }) => {
  const { validation } = client

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={!!validation.validateField("firstName")}
              helperText={validation.validateField("firstName")}
              fullWidth
              label="First Name"
              margin="none"
              value={client && client.firstName}
              onChange={(event) => editClientForm({firstName: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={!!validation.validateField("lastName")}
              helperText={validation.validateField("lastName")}
              fullWidth
              label="Last Name"
              margin="none"
              value={client && client.lastName}
              onChange={(event) => editClientForm({lastName: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Home Phone"
              margin="none"
              value={client.homePhone}
              onChange={(event) => editClientForm({homePhone: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Mobile Phone"
              margin="none"
              value={client.mobilePhone}
              onChange={(event) => editClientForm({mobilePhone: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip type={TooltipTypes.clientDialog.OPTIONAL_EMAIL} placement="left">
              <TextField
                error={!!validation.validateField("email")}
                helperText={validation.validateField("email")}
                fullWidth
                label="Email"
                margin="none"
                value={client.email}
                onChange={(event) => editClientForm({email: event.target.value})}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              margin="none"
              value={client.city}
              onChange={(event) => editClientForm({city: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Province"
              margin="none"
              value={client.province}
              onChange={(event) => editClientForm({province: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              margin="none"
              value={client.country}
              onChange={(event) => editClientForm({country: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="ZIP/Postal Code"
              margin="none"
              value={client.postalCode}
              onChange={(event) => editClientForm({postalCode: event.target.value})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              margin="none"
              value={client.address}
              onChange={(event) => editClientForm({address: event.target.value})}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  client: state.formData.client,
})

const mapDispatchToProps = {
  editClientForm,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ClientForm)
