import { db } from '../../firebase';

export const setTaxCategories = (taxCategories) => ({
  type: 'SET_TAX_CATEGORIES',
  taxCategories
});

//1. Fetch all clients
//2. Parse the data into an array
//3. Dispatch SET_CLIENTS
export const startSetTaxCategories = () => {
  return (dispatch) => {
    return db.getTaxCategories().on('value', (snapshot) => {
      const taxCategories = [];
      snapshot.forEach((childSnapshot) => {
        taxCategories.push(Object.assign({id: childSnapshot.key}, childSnapshot.val()));
      });

      dispatch(setTaxCategories(taxCategories));
    });

  };
};
