import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import ClientCard from './ClientCard';
import Illustration from '../../Illustration';

const styles = theme => ({
  root: {
  },

});

const ClientCards = ({ classes, clients, handleEdit, startEditAdmin }) => {
  return(
    <div className={classes.root}>
      {clients.length > 0 &&
        clients.map((client, i) => {
          return <ClientCard key={i} client={client} handleEdit={handleEdit}/>
        })
      }
      {clients.length === 0 &&
        <Illustration
          title="Hmm, no Clients..."
          imgKey="credit_card_payment_amber"
          explainerText="Whoah, we’re missing your cients!!! Gonna need them to make this thing work."
          addItemText="Let’s get them entered by clicking the “Floating Action Button” at top right."
        />
      }
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.clientsState.sort((a, b) => {
      const namesA = [a.firstName, a.lastName];
      const namesB = [b.firstName, b.lastName];
      const fullNameA = namesA.join("");
      const fullNameB = namesB.join("");

      return fullNameA.localeCompare(fullNameB);
    }),
  };
};


export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ClientCards);
