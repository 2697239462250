import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import { editTaxCategoryForm } from '../../../store/actions';

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit*3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const TaxCategoryForm = ({ classes, taxCategory, editTaxCategoryForm }) => {
  const { validation } = taxCategory;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <TextField
          error={!!validation.validateField("name")}
          helperText={validation.validateField("name")}
          fullWidth
          label="Name"
          margin="normal"
          multiline
          rows="3"
          value={taxCategory && taxCategory.name}
          onChange={(event) => editTaxCategoryForm({name: event.target.value})}
        />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  taxCategory: state.formData.taxCategory,
});

const mapDispatchToProps = {
  editTaxCategoryForm,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaxCategoryForm);
