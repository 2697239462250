import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import MissionIcon from 'img/icons8-rocket-96.png';

import { displayAcceptMission } from 'store/actions/help';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  row: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing.unit,
  },
  completedStyle: {
    textDecoration: "line-through",
  }
});

const MissionChecklist = ({ classes, missions, plan, displayAcceptMission }) => {

  return(
    <div className={classes.root}>
      {missions.filter(mission => mission.type !== "intro" && mission.type !== "trial" && mission.plans.includes(plan)).map((mission, i) => {
        return(
          <ButtonBase
            key={i}
            focusRipple
            onClick={ () => displayAcceptMission(mission.id)}
          >
            <div className={classes.row}>
              <img
                style={{ marginRight: '15px', width: '35px', height: '35px' }}
                src={MissionIcon}
                alt="Missions"
              />
            <Typography className={mission.isCompleted ? classes.completedStyle : ""} variant="headline">{mission.title}</Typography>
            </div>
          </ButtonBase>
        );
      })}
    </div>
  );
}


const mapStateToProps = (state) => ({
  missions: state.help.missions,
  plan: state.subscription.plan
});

const mapDispatchToProps = {
  displayAcceptMission
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MissionChecklist);
