import * as taxCategories from './json/taxCategories.json';

const setupData = {
  appData: {
    taxRules: {
      default: {
        gstApplicable: true,
        gstRate: 0.05,
        pstApplicable: false,
        pstRate: 0.07,
      }
    },
    taxCategories: taxCategories.items,
  }
};

export default setupData;
