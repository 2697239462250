import { db } from '../../firebase';

export const startEditClient = (id, updates) => {
    return (dispatch) => db.updateClient(id, updates)
};

export const startAddClient = (client) => {
  return (dispatch) => (
    db.createClient(client)
  );
};

export const startRemoveClient = (id) => {
  return (dispatch) => db.deleteClient(id);
};

export const setClients = (clients) => ({
  type: 'SET_CLIENTS',
  clients
});

export const validateClients = (clients) => ({
  type: 'VALIDATE_CLIENTS',
  clients
});

//1. Fetch all clients
//2. Parse the data into an array
//3. Dispatch SET_CLIENTS
export const startSetClients = () => {
  return (dispatch) => {
    return db.getClients().on('value', (snapshot) => {
      const clients = [];
      snapshot.forEach((childSnapshot) => {
        const client = Object.assign({id: childSnapshot.key}, childSnapshot.val());
        Object.defineProperty(client, "fullName", {
          get() {
            const firstName = client.firstName;
            const lastName = client.lastName;

            if (firstName === undefined && lastName === undefined) {
              return ""
            } else if (firstName === undefined) {
              return lastName;
            } else if (lastName === undefined) {
              return firstName;
            } else {
              return `${firstName} ${lastName}`;
            }
          }
        });

        clients.push(client);
      });

      dispatch(setClients(clients));
      dispatch(validateClients(clients));
    });

  };
};
