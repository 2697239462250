import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';

const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const SpinnerButton = ({ classes, children, show, stretch }) => {
  return (
    <div className={classes.wrapper}>
      {children}
      {show && <CircularProgress size={34} className={classes.buttonProgress} />}
    </div>
  );
}

export default withStyles(styles)(SpinnerButton);
