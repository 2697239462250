import { createSelector } from 'reselect';
import { selectBidTemplates, selectBidList } from './bids';
import { selectReceiptsList, selectReceiptStatus } from './receipts';
import { selectTimecardsList, selectTimecardStatus } from './timecards';
import { selectClientJobs } from './clientJobs';
import { selectInvoiceList, selectProjectInvoices, selectInvoiceStatus } from './invoices';
import { selectProjectList, selectProjectTotals } from './projects';

export * from './account'
export * from './crew';
export * from './vendors';
export * from './workTypes';
export * from './taxCategories';

export {
  selectBidTemplates,
  selectBidList,
  selectReceiptsList,
  selectTimecardsList,
  selectTimecardStatus,
  selectClientJobs,
  selectInvoiceList,
  selectProjectInvoices,
  selectProjectList,
  selectProjectTotals,
  selectInvoiceStatus,
  selectReceiptStatus,
};

//TODO: 1. refactor the selectors so that they use schedule date
//to filter selections
//2. move this code to its own file
const projectTimes = (state, props) => {
  const invoice = props.invoice;

  return state.projectTimesState.filter(time => {
    const timeDate = new Date(time.dateWorked);

    if (!invoice) {
      return time.projectID === props.project.id;
    } else {
      const invoiceDate = new Date(invoice.invoiceDate);

      return(
        time.projectID === props.project.id
        &&
        timeDate.getYear() === invoiceDate.getYear()
        &&
        timeDate.getMonth() === invoiceDate.getMonth()
      );
    }

  });
};

const receipts = (state, props) => {
  const project = props.project;
  const invoice = props.invoice;


  return state.receiptsState.filter(receipt => {
    const receiptDate = new Date(receipt.dateOfReceipt);

    //If no invoice then return invoices for the project
    if (!invoice) {
      return (receipt.projectID === project.id);
    } else {
      const invoiceDate = new Date(invoice.invoiceDate);
      return (
        receipt.projectID === project.id
        &&
        receiptDate.getYear() === invoiceDate.getYear()
        &&
        receiptDate.getMonth() === invoiceDate.getMonth()
      );
    }
  });
};

//If no invoice passed then return invoices for the project
//TODO: If no project then return all invoices
const invoices = (state, props) => {
  const project = props.project;

  if (props.invoice === undefined) {
    return state.invoicesState.filter(invoice => invoice.projectId === project.id);
  } else {
    const id = props.invoice.id;
    return state.invoicesState.filter(invoice => invoice.id === id);
  }
}

//HACK: to get schedule into memoized selector
const schedule = (state, props) => props.project.schedule;

//TODO: refactor
// 1. link project times to invoice line items
// 2. calculate difference between line item total and project time total
// 3. link receipts to invoice line items
// 4. calculate difference between line item total and receipt total
export const makeGetCosts = () => {
  return createSelector(
    [ projectTimes, receipts, invoices, schedule ],
    (projectTimes, receipts, invoices, schedule) => {
      //TODO: move costs into a Class
      const costs = {
        times: [],
        materials: [],
        schedule: schedule,
        invoices: invoices
      };

      Object.defineProperty(costs, 'timeTotal', {
        get() {
          let total = 0;
          this.times.forEach(function(cost) {
            total += cost.amount;
          });

          return total;
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'invoicedTimeTotal', {
        get() {
          let total = 0;
          this.times.forEach(function(cost) {
            total += cost.invoicedAmount;
          });

          return total;
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'materialTotal', {
        get() {
          let total = 0;
          this.materials.forEach(function(cost) {
            total += cost.amount;
          });

          return total;
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'invoicedMaterialTotal', {
        get() {
          let total = 0;
          this.materials.forEach(function(cost) {
            total += cost.invoicedAmount;
          });

          return total;
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'grossMargin', {
        get() {
          const costs = this.timeTotal + this.materialTotal;
          const revenue = this.invoicesTotal;

          if (revenue === 0) {
            return 0;
          } else {
            const margin = ((revenue - costs)/revenue)*100;
            return Number(margin.toFixed(0));
          }
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'invoicesTotal', {
        get() {
          let invoicesTotal = 0;

          this.invoices.forEach((invoice) => {
            invoicesTotal += invoice.total;
          });

          return invoicesTotal;
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'pieCartData', {
        get() {
          const data = [];


          let invoiceTotals = 0;
          let taxTotals = 0;
          this.invoices.forEach((invoice) => {
            invoiceTotals += invoice.total;
            taxTotals += invoice.gst + invoice.pst;
          });

          data.push({
            name: 'Tax',
            value: taxTotals
          });

          data.push({
            name: 'Profit',
            value: invoiceTotals - (Number(this.timeTotal) + Number(this.materialTotal))
          });

          data.push({
            name: 'Materials',
            value: Number(this.materialTotal)
          });

          data.push({
            name: 'Time',
            value: Number(this.timeTotal)
          });

          return data;
        },
        configurable: true,
        enumerable: true,
      });

      Object.defineProperty(costs, 'barChartData', {
        get() {
          const data = [];

          this.schedule.forEach((scheduleItem) => {
            const result = {
              name: scheduleItem.shortDisplayName,
              revenue: 0,
              profit: 0,
              costs: 0
            }

            //get materials revenue and costs for this month
            this.materials.forEach((cost) => {
              const costYear = cost.date.getYear();
              const costMonth = cost.date.getMonth();
              const scheduleItemYear = scheduleItem.date.getYear();
              const scheduleItemMonth = scheduleItem.date.getMonth();
              //if the material cost matches a schedule item then calculate
              //revenue, costs
              if (costYear === scheduleItemYear && costMonth === scheduleItemMonth){
                result.revenue += cost.invoicedAmount;
                result.costs += cost.amount;
              }
            })

            //get time revenu and costs for this month
            this.times.forEach((cost) => {
              const costYear = cost.date.getYear();
              const costMonth = cost.date.getMonth();
              const scheduleItemYear = scheduleItem.date.getYear();
              const scheduleItemMonth = scheduleItem.date.getMonth();
              //if the material cost matches a schedule item then calculate
              //revenue, costs
              if (costYear === scheduleItemYear && costMonth === scheduleItemMonth){
                result.revenue += cost.invoicedAmount;
                result.costs += cost.amount;
              }
            })

            result.profit = result.revenue - result.costs;

            data.push(result);
          })

          return data;
        },
        configurable: true,
        enumerable: true
      });

      projectTimes.forEach((projectTime) => {
        const cost = {
          type: 'LABOUR',
          amount: (projectTime.minutesWorked/60)*projectTime.workTypeRate,
          invoicedAmount: 0,
          date: new Date(projectTime.dateWorked)
        };

        //TODO: refactor out the complexity and find a more effecient way
        // as this makes everything multilinear O(mn) where m = lineItems and
        // n = project times
        invoices.forEach((invoice) => {
          invoice.lineItems.forEach((lineItem) => {
            if (!!lineItem.projectTimeId && projectTime.id === lineItem.projectTimeId) {
              cost.invoicedAmount = lineItem.total;
            }
          });
        });


        costs.times.push(cost);
      });

      receipts.forEach((receipt) => {
        const cost = {
          type: 'MATERIALS',
          amount: (receipt.totalAmount),
          invoicedAmount: 0,
          date: new Date(receipt.dateOfReceipt)
        }

        //TODO: refactor out the complexity and find a more effecient way
        // as this makes everything multilinear O(mn) where m = lineItems and
        // n = project times
        invoices.forEach((invoice) => {
          invoice.lineItems.forEach((lineItem) => {
            if (!!lineItem.projectReceiptID && receipt.id === lineItem.projectReceiptID) {
              cost.invoicedAmount = lineItem.total;
            }
          });
        });

        costs.materials.push(cost);

      });
      return costs;
    }
  );
};
