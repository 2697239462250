import React from 'react'
import classnames from 'classnames'

import { DatePicker } from 'material-ui-pickers'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import indigo from '@material-ui/core/colors/indigo'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import StartIcon from '@material-ui/icons/Schedule'
import RepeatIcon from '@material-ui/icons/Autorenew'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {

  },
  avatar: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    height: '50px',
    width: '50px',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectedAvatar: {
    color: '#fff',
    backgroundColor: indigo[500],
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '180px'
  },
  frequency: {
    marginLeft: theme.spacing.unit,
  },
  icon: {
    marginRight: theme.spacing.unit,
    color: indigo[500]
  },
  marginLeft: {
    marginLeft: theme.spacing.unit,
  },
  occurrences: {
    width: '50px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.spacing.unit*2,
  },
  spacer: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginBottom: theme.spacing.unit,
  },
  toggleMargin: {
    marginLeft: theme.spacing.unit*4,
  }
});

const ReminderDialog = (props) => {
  const {
    classes,
    reminder,
    handleCancel,
    handleReminderChange,
    handleReminderSave,
    handleReminderDelete
  } = props


  return(
    <Dialog
      fullWidth
      open={!!reminder}
      onClose={handleCancel}
    >
      <DialogTitle>
        {`${!!reminder.id ? 'Edit': 'Add'} Reminder`}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          className={classes.textField}
          InputLabelProps={{ shrink: true }}
          label="Description"
          placeholder="Enter a description"
          margin="none"
          multiline
          rows={3}
          value={reminder.description}
          onChange={event => handleReminderChange("description", event.target.value)}
        />
        <div className={classes.spacer} />
        <div className={classes.row}>
          <StartIcon className={classes.icon} />
          <DatePicker
            className={classes.field}
            format="MMMM Do YYYY"
            value={reminder.startDate}
            leftArrowIcon={<ArrowBackIcon />}
            rightArrowIcon={<ArrowForwardIcon />}
            onChange={(date) => handleReminderChange("startDate", date.toDate().getTime())}
          />
        </div>
        <div className={classes.row}>
          <RepeatIcon className={classes.icon} />
          <Select
            className={classes.field}
            value={reminder.frequency ? reminder.frequency : ""}
            onChange={event => handleReminderChange("frequency", event.target.value)}
            inputProps={{
              name: 'frequency',
              id: 'frequency',
            }}
          >
            <MenuItem value="dnr">Does not repeat</MenuItem>
            <MenuItem value="day">Repeat every day</MenuItem>
            <MenuItem value="week">Repeat every week</MenuItem>
            <MenuItem value="month">Repeat every month</MenuItem>
            <MenuItem value="year">Repeat every year</MenuItem>
          </Select>
        </div>

        {reminder.frequency === "week" &&
          <div className={classnames(classes.row, classes.toggleMargin)}>
            {
              ["S", "M", "T", "W", "T", "F", "S"].map((dayOfWeek, i) => {
                const isSelected = reminder.weeklySchedule.includes(i)

                return(
                  <Avatar
                    className={classnames(classes.avatar, isSelected ? classes.selectedAvatar : "")}
                    onClick={() => {
                      handleReminderChange("weeklySchedule",
                        isSelected
                          ? reminder.weeklySchedule.filter(item => item !== i)
                          :reminder.weeklySchedule.concat(i)
                      )
                    }}
                  >
                    {dayOfWeek}
                  </Avatar>
                )
              })
            }

          </div>
        }
        {reminder.frequency === "month" &&
          <div className={classnames(classes.row, classes.toggleMargin)}>
            {
              [
                "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL",
                "AUG", "SEP", "OCT", "NOV", "DEC"
              ].map((dayOfWeek, i) => {
                const isSelected = reminder.monthlySchedule.includes(i)

                return(
                  <Avatar
                    className={classnames(classes.avatar, isSelected ? classes.selectedAvatar : "")}
                    onClick={() => {
                      handleReminderChange("monthlySchedule",
                        isSelected
                          ? reminder.monthlySchedule.filter(item => item !== i)
                          :reminder.monthlySchedule.concat(i)
                      )
                    }}
                  >
                    {dayOfWeek}
                  </Avatar>
                )
              })
            }
          </div>
        }
        {reminder.frequency !== "dnr" &&
          <div className={classnames(classes.column, classes.frequency)}>
            <Typography>Ends</Typography>
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  checked={reminder.terminationType === "never"}
                  onChange={event => handleReminderChange("terminationType", event.target.value)}
                  value="never"
                />
              }
              label="Never"
            />
            <div className={classes.row}>
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={reminder.terminationType === "on"}
                    onChange={event => handleReminderChange("terminationType", event.target.value)}
                    value="on"
                  />
                }
                label="On"
              />
              <DatePicker
                className={classnames(classes.field, classes.marginLeft)}
                format="MMMM Do YYYY"
                value={reminder.terminationDate}
                leftArrowIcon={<ArrowBackIcon />}
                rightArrowIcon={<ArrowForwardIcon />}
                onChange={(date) => handleReminderChange("terminationDate", date.toDate().getTime())}
              />
            </div>
            <div className={classes.row}>
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={reminder.terminationType === "after"}
                    onChange={event => handleReminderChange("terminationType", event.target.value)}
                    value="after"
                  />
                }
                label="After"
              />

              <FormControlLabel
                control={
                  <TextField
                    className={classes.occurrences}
                    type="number"
                    value={reminder.occurrences}
                    onChange={event => handleReminderChange("occurrences", event.target.value)}
                  />
                }
                label="occurrences"
              />

            </div>
          </div>
        }

      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!!reminder.id &&
          <Button
            onClick={() => {
              handleReminderDelete(reminder)
              handleCancel()
            }}
          >
            Delete
          </Button>
        }
        {!reminder.id &&
          <div />
        }
        <div>
          <Button color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleReminderSave}>
            {reminder && reminder.id &&
              <div>Edit</div>
            }
            {reminder && !reminder.id &&
              <div>Add</div>
            }
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}


export default withStyles(styles)(ReminderDialog)
