import React from 'react';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

const DeleteButton = ({ onClick, width, height }) =>
  <Button
    style={{
      backgroundColor: "#fff",
      width: width ? width : '40px',
      height: height ? height : '40px',
      boxShadow: "none",
    }}
    variant="fab"
    onClick={onClick}
  >
    <DeleteIcon color="primary" />
  </Button>

export default DeleteButton;
