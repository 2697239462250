const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_PREVIEW':
      return action.previewData;
    case 'EDIT_PREVIEW':
      return {...state, ...action.updates};
    default:
      return state;
  }
};
