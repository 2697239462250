import React from 'react';
import { connect } from 'react-redux';

import {
  Dialog
} from '../../core';

import {
  WorkTypeForm,
} from '../WorkTypes';

import {
  setWorkTypeForm,
  startRemoveWorkType,
  startOnDoneWorkType,
  resetWorkTypeForm,
} from '../../../store/actions';
import { showWorkTypeFormValidation } from 'store/actions/validation';

const WorkTypeDialog = (props) => {
  const {
    workType,
    show,
    handleCreated,
    handleClose,
    resetWorkTypeForm,
    startOnDoneWorkType,
    startRemoveWorkType,
    showWorkTypeFormValidation,
  } = props;

  return (
    <Dialog
      confirmDelete
      deleteTitle="Delete Work Type"
      deleteText="This action will delete the work type. Proceed?"
      show={show}
      title="Work Type"
      type={"edit"}
      handleCreated={handleCreated}
      onDone={ () => startOnDoneWorkType() }
      onDelete={ () => startRemoveWorkType() }
      onClose={() => {
        resetWorkTypeForm();
        handleClose();
      }}
      validate={() => {
        const { validation } = workType;
        if (validation && validation.isInvalid) {
          showWorkTypeFormValidation();
          return false;
        } else {
          return true;
        }
      }}
    >
      <WorkTypeForm />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  workType: state.formData.workType,
});

const mapDispatchToProps = {
  setWorkTypeForm,
  showWorkTypeFormValidation,
  startRemoveWorkType,
  startOnDoneWorkType,
  resetWorkTypeForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkTypeDialog);
