import { db } from '../../firebase';
import { generateSchedule } from '../generators/projectSchedule';
import { analytics } from 'analytics'

const injectProperty = (result, propertyName, propertyValue) => {

  if (propertyValue !== undefined) {
    result = {...result, [propertyName]: propertyValue};
  }

  return result;
};

const generateProjectTasks = (projectId, bidTasks) => {
    const tasks = [];
    bidTasks.forEach((bidTask) => {
      //TODO: Refactor, this is horrible
      let projectTask = {};

      if (bidTask.type === "maintenance") {
          const {
            type,
            title,
            scheduleStart,
            scheduleOccurences,
            scheduleHours,
            monthlyAmount,
          } = bidTask;
          let projectTask = {};
          projectTask.projectId = projectId;
          projectTask = injectProperty(projectTask, "type", type);
          projectTask = injectProperty(projectTask, "title", title);
          projectTask = injectProperty(projectTask, "scheduleStart", scheduleStart);
          projectTask = injectProperty(projectTask, "scheduleOccurences", scheduleOccurences);
          projectTask = injectProperty(projectTask, "scheduleHours", scheduleHours);
          projectTask = injectProperty(projectTask, "monthlyAmount", monthlyAmount);
          tasks.push(projectTask);
      } else if (bidTask.type === "simple") {
        const {
          type,
          estimate,
          title
        } = bidTask;
        projectTask.projectId = projectId;
        projectTask = injectProperty(projectTask, "type", type);
        projectTask = injectProperty(projectTask, "estimate", estimate);
        projectTask = injectProperty(projectTask, "title", title);
        tasks.push(projectTask);
      } else if (bidTask.type === "estimate") {
        const {
          type,
          description,
          estimate,
          title,
          optional,
        } = bidTask;
        projectTask.projectId = projectId;
        projectTask = injectProperty(projectTask, "type", type);
        projectTask = injectProperty(projectTask, "description", description);
        projectTask = injectProperty(projectTask, "estimate", estimate);
        projectTask = injectProperty(projectTask, "title", title);
        projectTask = injectProperty(projectTask, "optional", optional);
        tasks.push(projectTask);
      } else if (bidTask.type === "quote") {
        const {
          type,
          description,
          title,
          optional,
          labor,
          materials,
        } = bidTask;

        labor.forEach((item) => delete item.id);
        materials.forEach((item) => delete item.id);

        projectTask.projectId = projectId;
        projectTask = injectProperty(projectTask, "type", type);
        projectTask = injectProperty(projectTask, "description", description);
        projectTask = injectProperty(projectTask, "title", title);
        projectTask = injectProperty(projectTask, "optional", optional);
        projectTask = injectProperty(projectTask, "labor", labor);
        projectTask = injectProperty(projectTask, "materials", materials);

        tasks.push(projectTask);
      }
    });

    return tasks;
};

export const startAddProject = (projectData, bid, bidTasks) => {
  if (bid === undefined) {
    return dispatch => db.createProject(projectData);
  } else {
    const {
      code = "",
      id,
    } = bid;

    projectData = {
      ...projectData,
      jobCode: code,
      bidId: id,
    };

    if (bid.clientId !== undefined) {
      projectData.clientId = bid.clientId;
    }



    return (
      (dispatch) => db.createProject(projectData)
      .then((result) => {
        console.log("CREATE PROJECT DISPATCH RESULT:", result);
         if (bidTasks !== undefined) {
           const tasks = generateProjectTasks(result.id, bidTasks);
           return db.createProjectTasks(tasks).then(tasksResult => {
             console.log("DSDFSFDSDSFDFDF", tasksResult)
             return result;
           });
         } else {
           return result;
         }
      })
    );
  }
}

export const startEditProject= (projectId, updates) => {
  return (dispatch) => (
    db.updateProject(projectId, updates)
  )
}

export const startAddInterval = (projectId, interval) => {
    return (dispatch) => (
      db.createInterval(projectId, interval)
        .catch((error) => {
          console.log('startAddInterval ERROR: ', error)
          analytics.exception(`projects::startAddInterval [message:${error.message}]`)
        })
    );
};

export const startRemoveProjectField = (id, field) => {
  return (dispatch) => db.deleteProjectField(id, field);
}

export const startRemoveProject = (id) => {
  return (dispatch) => db.deleteProject(id);
}

export const setProjects = (projects) => ({
  type: 'SET_PROJECTS',
  projects
});


export const startSetProjects = () => {
  return (dispatch) => {
    return db.getProjects().on('value', (snapshot) => {
      const projects = [];
      snapshot.forEach((childSnapshot) => {
        const project = Object.assign({id: childSnapshot.key}, childSnapshot.val());
        project.schedule = generateSchedule(project);
        Object.defineProperty(project, 'years', {
          get() {
            //returns unique array of years
            return [...new Set(project.schedule.map(scheduleItem => scheduleItem.date.getFullYear()))];
          },
          configurable: true,
        });

        projects.push(project);
      });

      dispatch(setProjects(projects));
      dispatch({ type: 'VALIDATE_PROJECTS' });
    });
  };
};
