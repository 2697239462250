import { db, firebase } from '../../../firebase';
import { getHostingURL } from 'utils';
import invoices from './invoices';
import bids from './bids';
import store from 'store';
import { analytics } from 'analytics'

const setNotifications = (notifications) => ({
  type: 'SET_NOTIFICATIONS',
  notifications
});

const unreadReducer = (accumulator, currentValue) => {
  return currentValue.isRead ? accumulator : ++accumulator;
};

const generateNotifications = () => {
  fetch(`${getHostingURL()}/notifications/generateNotifications`, {
    mode: 'no-cors',
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      oid: firebase.oid,
      token: "LaST18dswfD37VCwVjSsgiselDIDqwwq18iXTvRv"
    })
  })
  .catch(error => {
    console.log("ERROR generateNotifications:", error)
    analytics.exception(`notifications::getateNotifications [message:${error.message}]`)
  });

  setTimeout(generateNotifications, 300000);
}

export const startMarkReadNotificationsAll = () => dispatch => {
  const promises = []
  const state = store.getState()
  const { notifications } = state

  notifications.items.filter(notification => !notification.isRead)
    .forEach(notification => promises.push(db.updateNotifications(notification.id, { isRead: true })))

  return Promise.all(promises)
}


export const startEditNotification = (id, updates) =>
  dispatch => db.updateNotifications(id, updates);

export const startSetNotifications = () => (dispatch) => {
  generateNotifications();

  return db.getNotifications().on('value', snapshot => {
    const notifications = { items: [], unread: 0 };
    snapshot.forEach(childSnapshot => {
      const notification = { id: childSnapshot.key, ...childSnapshot.val()};
      notifications.items.push(notification);
    });

    notifications.unread = notifications.items.reduce(unreadReducer, 0);

    dispatch(setNotifications(notifications))
  });
}
