import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'

import { ConfirmDialog } from 'components/admin/Dialogs'
import Intro from './Intro'
import Missions, { MissionDialog, MissionSuccessDialog } from './Missions'
import { closeHelp, closeMissions, startMission } from 'store/actions/help'
import { startEditAdmin } from 'store/actions/admin'
import { analytics } from 'analytics'

const styles = theme =>({
  root: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: theme.zIndex.overlay,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition:'.5s ease-in-out'
  },
  inactive: {
    width: "0px",
    height: "0px",
  },
  closeButton: {
    position: 'fixed',
    top: 70,
    right: 100,
    zIndex: theme.zIndex.overlay + 1,
  },
  closePosition1: {
    right: 100,
  },
  closePosition2: {
    right: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit*2,
    marginRight: theme.spacing.unit*4,
  },
  toolbar: {
    ...theme.mixins.toolbar,
  }
})

class Help extends Component {
  constructor(props) {
    super(props)
    this.state = { showCancel: false }
  }

  handleCancelMission() {
    const { help } = this.props
    analytics.missionAbort("Missions", help.currentMission.id)

    this.setState({ showCancel: false })
    this.props.closeHelp()
  }

  render() {
    const { showCancel } = this.state
    const {
      analytics,
      classes,
      isMobile,
      help,
      closeHelp,
      trialExpired
    } = this.props

    const currentMission = help.currentMission
    const showOverlay = !trialExpired && help.showOverlay()
    const currentStep = currentMission.steps[currentMission.currentStep]
    const success = currentStep && currentStep.success

    return(
      <div id="overlay" className={showOverlay ? classes.root : classes.inactive}>
        <div className={classes.toolbar} />
          <ConfirmDialog
            open={showCancel}
            title="Abort Mission"
            contentText="This action will abort the current mission. Proceed?"
            handleCancel={() => {
              this.setState({ showCancel: false })
            }}
            handleOk={this.handleCancelMission.bind(this)}
          />
        <Intro />
        <Missions />
        <MissionDialog acceptMission={help.acceptMission} />
        <MissionSuccessDialog show={success} />
        {showOverlay && !isMobile &&
          <Button
            className={classNames(classes.closeButton, help.showTour ? classes.closePosition2 : classes.closePosition1)}
            variant="fab"
            aria-label="close"
            onClick={() => this.setState({ showCancel: true })}
          >
            <CloseIcon />
          </Button>
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  const subscription = state.subscription
  const trialExpired = subscription.trialExpired && !subscription.isSubscribed

  return ({
    isMobile: state.adminState.isMobile,
    help: state.help,
    trialExpired
  })
}

const mapDispatchToProps = {
  closeHelp,
  closeMissions,
  startMission,
  startEditAdmin,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Help)
