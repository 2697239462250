import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import primary from '@material-ui/core/colors/yellow';
import secondary from '@material-ui/core/colors/pink';
import amber from '@material-ui/core/colors/amber';

import { illustrations } from 'components/Illustration';
import {
  helpDispatch
} from 'components/admin/Help';
import tooltips from './Types';

const styles = theme => ({
  root: {

  },
  bgPrimary: {
    backgroundColor: primary[100],
  },
  bgSecondary: {
    backgroundColor: secondary[100],
  },
  img: {
    width: '100px',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    height: '40px',
    paddingTop: theme.spacing.unit,
    backgroundColor: '#fff',
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing.unit,
  },
  subtitle: {

  },
  nextButton: {
    marginBottom: theme.spacing.unit,
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    '&:hover': {
     backgroundColor: amber[700],
    },
  },
});

const getBackgroundColorStyle = (classes, tooltip) => {
  if (tooltip.type === "primary") {
    return classes.bgPrimary;
  } else if (tooltip.type === "secondary") {
    return classes.bgSecondary;
  }
}

const getIllustration = (tooltip) => {
  if (tooltip.type === "primary") {
    return illustrations.get("click_here_amber");
  } else if (tooltip.type === "secondary") {
    return illustrations.get("questions_amber");
  }
}

const TooltipContent = (props) => {
  const {
    classes,
    help,
    title,
    type,
    tooltip
  } = props;
  const mission = help.currentMission;
  const currentStep = mission.currentStep + 1;
  const totalSteps = mission.steps.length;

  return(
      <Card className={getBackgroundColorStyle(classes, tooltip)}>
        <div className={classes.row}>
          <div style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '10px' }}>
            <Typography className={classes.title} variant="title">
              {tooltip.title}
            </Typography>
            <Typography className={classes.subtitle}>
              {tooltip.subtitle}
            </Typography>
          </div>
          <div>
            <img className={classes.img} alt="" src={getIllustration(tooltip)} />
          </div>
        </div>

        {tooltip.type === "primary" &&
          <div className={classes.footer}>
            {tooltip.type === "primary" &&
              <Typography variant="caption">
                {`${currentStep} of ${totalSteps}`}
              </Typography>
            }
            {tooltip.nextDispatch &&
              <Button
                className={classes.nextButton}
                onClick={() => helpDispatch(tooltip.nextDispatch)}
              >
                Next
              </Button>
            }
          </div>
        }
      </Card>
  );
}

const mapStateToProps = (state) => ({
  help: state.help,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(TooltipContent);
