import React from 'react';
import { connect } from 'react-redux';

import {
  Dialog
} from '../../core';

import {
  TaxCategoryForm,
} from '../TaxCategories';

import {
  setTaxCategoryForm,
  startRemoveTaxCategory,
  startOnDoneTaxCategory,
  resetTaxCategoryForm,
} from '../../../store/actions';
import { showTaxCategoryFormValidation } from 'store/actions/validation';

const TaxCategoryDialog = (props) => {
  const {
    taxCategory,
    show,
    handleCreated,
    handleClose,
    resetTaxCategoryForm,
    showTaxCategoryFormValidation,
    startOnDoneTaxCategory,
    startRemoveTaxCategory,
  } = props;

  return (
    <Dialog
      confirmDelete
      deleteTitle="Delete Tax Category"
      deleteText="This action will delete the tax category. Proceed?"
      show={show}
      title="Tax Category"
      type={"edit"}
      handleCreated={handleCreated}
      onDone={ () => startOnDoneTaxCategory() }
      onDelete={ () => startRemoveTaxCategory() }
      onClose={() => {
        resetTaxCategoryForm();
        handleClose();
      }}
      validate={() => {
        const { validation } = taxCategory;
        if (validation && validation.isInvalid) {
          showTaxCategoryFormValidation();
          return false;
        } else {
          return true;
        }
      }}
    >
      <TaxCategoryForm />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  taxCategory: state.formData.taxCategory,
});

const mapDispatchToProps = {
  setTaxCategoryForm,
  showTaxCategoryFormValidation,
  startRemoveTaxCategory,
  startOnDoneTaxCategory,
  resetTaxCategoryForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxCategoryDialog);
