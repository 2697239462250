

export const editAdmin = (updates) => ({
  type: 'EDIT_ADMIN',
  updates
});

export const startEditAdmin = (updates) => {
  return (dispatch) => {
    return dispatch(editAdmin(updates));
  };
};

export const setAdmin = (admin) => ({
  type: 'SET_ADMIN',
  admin
});

export const startSetAdmin = () => {
  return (dispatch) => {
    const admin = {
      invoice: null,
      client: null,
      title: 'Getting Started',
      actions: {
        showWebsitePreview: false,
      },
      dialog: null,
      bid: null,
    };

    return dispatch(setAdmin(admin));
  };
};
