import types from '../TooltipTypes';

const invoiceTooltips = [
  {
    id: "add-invoice",
    title: "Add Invoice",
    subtitle: "Click the yellow plus button or FAB to add things",
    type: "primary",
  },
  {
    id: "invoice-cards-side",
    title: "Information Panel",
    subtitle: "The 'Info' panel, well, has lots of info for each page",
    type: "secondary",
  },
  {
    id: "invoice-add-client",
    title: "Add Client",
    subtitle: "Click the blue plus button to create and select a new client",
    type: "primary",
  },
  {
    id: "invoice-add-item",
    title: "Add Item",
    subtitle: "Click the Add Item button to create line items for the invoice",
    type: "primary",
  },
  {
    id: "invoice-save-item",
    title: "Save Item",
    subtitle: "Enter description, quantity, and unit price for the item and click save",
    type: "primary",
  },
  {
    id: "invoice-preview-sent",
    title: "Preview and Send",
    subtitle: "Click this button to Preview and then Send the Invoice",
    type: "primary",
  },
  {
    id: "invoice-send",
    title: "Send Invoice",
    subtitle: "Check out the details ... then hit Send",
    type: "primary",
  },
  {
    id: "invoice-send-email",
    title: "Email Test",
    subtitle: "We have put in your email...",
    type: "secondary",
  },
  {
    id: types.INVOICE_MENU_ITEM,
    title: "Go to Invoices",
    subtitle: "Click the Inovices text on our main menu.",
    type: "primary",
  },
  {
    id: types.INVOICE_SELECT_CLIENT,
    title: "Select Client",
    subtitle: "Select the Client used for the Time Card earlier.",
    type: "primary",
  },
  {
    id: types.INVOICE_CLIENT_SMART_TEXT,
    title: "Smart Text!",
    subtitle: "This highlights when selected client has Time or Receipts.",
    type: "secondary",
  },
  {
    id: types.INVOICE_ADD_TIME_MATERIALS,
    title: "Select T and M",
    subtitle: "Click on the Add Time & Materials text.",
    type: "primary",
  },
  {
    id: types.INVOICE_JOB_OPTIONAL,
    title: "Jobs (Optional)",
    subtitle: "We'll dive into Jobs later, but you don't need one for now.",
    type: "secondary",
  },
  {
    id: types.IMPORT_TIME_INCLUDE,
    title: "'Include' this item",
    subtitle: "Not every item may be required, only include the ones you need.",
    type: "primary",
  },
  {
    id: types.IMPORT_TIME_MATERIALS_PROGRESS,
    title: "Track Progress",
    subtitle: "These 'wizard' type forms track your progress along this bar.",
    type: "secondary",
  },
  {
    id: types.IMPORT_MATERIALS_SKIP,
    title: "Skip this Step",
    subtitle: "Let's say for this invoice, there won't be Material charges",
    type: "primary",
  },
  {
    id: types.IMPORT_TIME_MATERIALS_FINISH,
    title: "Review and Finish",
    subtitle: "Give it a quick review and then hit Finish",
    type: "primary",
  },
  {
    id: types.INVOICE_IMPORTED_TIME,
    title: "It's Here!!",
    subtitle: "Your time record is now in the invoice, cool huh!",
    type: "secondary",
  },
];

export default invoiceTooltips;
