import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import 'typeface-roboto';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';

import illustrations from "./img";

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit*2,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: "1000px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "no-wrap",
  },
  imgSection: {
    flexGrow: 4,
  },
  textSection: {
    flexGrow: 3,
  },
  img: {
    width: "100%",
  },
  imgMobile: {
    width: '60%',
    alignSelf: 'center',
  },
  explainerText: {
    marginLeft: theme.spacing.unit*4,
    marginBottom: theme.spacing.unit*4,
    color: pink[600],
    fontSize: 16,
  },
  addItemText: {
    marginLeft: theme.spacing.unit*4,
    color: grey[600],
    fontSize: 16,
  },
  explainerTextMobile: {
    color: pink[600],
    fontSize: 16,
  },
  addItemTextMobile: {
    color: grey[600],
    fontSize: 16,
  },
});

const Illustration = (props) => {
  const {
    classes,
    title,
    imgKey,
    explainerText,
    addItemText,
    isMobile
  } = props;

  return(
    <div className={classes.root}>
      <div className={classes.content}>
        {title &&
          <Typography
            className={classes.title}
            variant="display2"
          >
            {title}
          </Typography>
        }
        {!isMobile &&
          <div className={classes.row}>
            <div className={classes.imgSection}>
              <img className={classes.img} alt="" src={illustrations.get(imgKey)} />
            </div>
            <div className={classes.textSection}>
              <Typography className={classes.explainerText} variant="body2" gutterBottom>{explainerText}</Typography>
              <Typography className={classes.addItemText}>{addItemText}</Typography>
            </div>
          </div>
        }
        {isMobile &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ margin: '16px' }}>
              <Typography className={classes.explainerTextMobile} variant="body2" gutterBottom>{explainerText}</Typography>
              <Typography className={classes.addItemTextMobile} gutterBottom>{addItemText}</Typography>
            </div>
            <img className={classes.imgMobile} alt="" src={illustrations.get(imgKey)} />
          </div>
        }
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  isMobile: state.adminState.isMobile
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(Illustration);
