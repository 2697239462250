import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import avatar from '@material-ui/core/colors/grey';

import classNames from 'classnames';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.unit,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: grey[100],
    }
  },
});

const CardListItem = ({ classes, children, item, handleSelect }) => {
  return (
    <Card className={classes.root}>
      <div
        className={classes.content}
        onClick={() => handleSelect(item)}
      >
        {children}
      </div>
    </Card>
  );
};

export default withStyles(styles)(CardListItem);
