import { db } from '../../../firebase'

export const startAddWebsiteService = (service) => {
  return (dispatch) => db.createWebsiteService(service)
}

export const startEditWebsiteService = (id, updates) => {
  return (dispatch) => db.updateWebsiteService(id, updates)
}

export const startRemoveWebsiteService = (id) => {
  return (dispatch) => db.deleteWebsiteService(id)
};

export const setWebsiteServices = (services) => ({
  type: 'SET_WEBSITE_SERVICES',
  services
})

export const startSetWebsiteServices = () => {
  return (dispatch) => {
    return db.getWebsiteServices().on('value', snapshot => {
      const services = []

      snapshot.forEach(childSnapshot => {
        services.push({ id: childSnapshot.key, ...childSnapshot.val() })
      })

      dispatch(setWebsiteServices(services))
      dispatch({ type: 'VALIDATE_WEBSITE' })
    })
  }
}
