import { db, oid } from '../firebase';

export const getOrganizationDomains = () =>
  db.ref(`orgDomains`);

export const updateOrgDomain = (id, orgDomain) =>
  db.ref(`orgDomains/${id}`).update(orgDomain);

//
// export const createClient = (client) =>
//   db.ref(`appData/${oid}/clients`).push(client)
//
// export const updateClient = (id, updates) =>
//   db.ref(`appData/${oid}/clients/${id}`).update(updates);
