import { storage, db } from '../../../firebase'
import uuid from 'uuid'
import { analytics } from 'analytics'

export const startAddWebsiteDocument = (docData, fileData) => dispatch => {
  docData.storageId = uuid.v4()
  return storage.uploadWebsiteDocument(docData.storageId, fileData)
    .then(() => db.createWebsiteDocument(docData))
    .catch(error => {
      console.log("ERROR startAddWebsiteDocument:", error)
      analytics.exception(`documents::startAddWebsiteDocument [message:${error.message}]`)
    })
}

export const startDeleteWebsiteDocument = (doc) => dispatch => {
  return storage.deleteWebsiteDocument(doc.storageId)
    .then(() => db.deleteWebsiteDocument(doc.id))
    .catch(error => {
      console.log("ERROR startDeleteWebsiteDocument", error)
      analytics.exception(`documents::startDeleteWebsiteDocument [message:${error.message}]`)
    })
}

export const startEditWebsiteDocument = (id, updates, fileData) => dispatch => {
  console.log("ID:", id)
  console.log("updates:", updates)
  console.log("fileData:", fileData)
  if (fileData) {
    return storage.uploadWebsiteDocument(updates.storageId, fileData)
      .then(() => db.updateWebsiteDocument(id, updates))
      .catch(error => {
        console.log("ERROR startEditWebsiteDocument", error)
        analytics.exception(`documents::startEditWebsiteDocument [message:${error.message}]`)
      })
  } else {
    return db.updateWebsiteDocument(id, updates)
      .catch(error => {
        console.log("ERROR startEditWebsiteDocument", error)
        analytics.exception(`documents::startEditWebsiteDocument [message:${error.message}]`)
      })
  }
}

export const setWebsiteDocuments = (documents) => ({
  type: 'SET_WEBSITE_DOCUMENTS',
  documents,
})

export const startSetWebsiteDocuments = () => {
  return (dispatch) => db.getWebsiteDocuments().on('value', (snapshot) => {
    const promises = []
    const documents = []
    snapshot.forEach((childSnapshot) => {
      const doc = { id:childSnapshot.key, ...childSnapshot.val()}
      const promise = storage.getWebsiteDocumentURL(doc.storageId)
        .then(result => {
          doc.downloadURL = result
          documents.push(doc)
          return result
        })
        .catch(error => {
          console.log("error startSetWebsiteDocuments:", error)
          analytics.exception(`documents::setWebsiteDocuments [message:${error.message}]`)
        })

      promises.push(promise)
    })

    return Promise.all(promises).then(() => dispatch(setWebsiteDocuments(documents)))
  })
}
