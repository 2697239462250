import { db } from '../../firebase';

export const setVendors = (vendors) => ({
  type: 'SET_VENDORS',
  vendors
});

//1. Fetch all clients
//2. Parse the data into an array
//3. Dispatch SET_CLIENTS
export const startSetVendors = () => {
  return (dispatch) => {
    return db.getVendors().on('value', (snapshot) => {
      const vendors = [];
      snapshot.forEach((childSnapshot) => {
        vendors.push(Object.assign({id: childSnapshot.key}, childSnapshot.val()));
      });

      dispatch(setVendors(vendors));
    });

  };
};
