import { db, oid } from '../firebase';

export const getClients = () =>
  db.ref(`appData/${oid}/clients`);

export const createClient = (client) =>
  db.ref(`appData/${oid}/clients`).push(client)

export const updateClient = (id, updates) =>
  db.ref(`appData/${oid}/clients/${id}`).update(updates);

export const deleteClient = (id) =>
  db.ref(`appData/${oid}/clients/${id}`).remove();
