import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  addQuoteItem,
  editQuoteItem,
  editQuoteForm,
  removeQuoteTaskItem,
} from '../../../../store/actions';

const styles = theme => ({
  root: {

  },
  button: {
    margin: theme.spacing.unit,
  },
  white: {
    color: '#fff',
  },
  description: {
    minWidth: '300px',
  },
  table: {
    minWidth: "700px",
  },
  dialogDescription: {
    flexGrow: 1,
    marginBottom: theme.spacing.unit*2,
  },
  dialogRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogQuanitity: {
    marginRight: theme.spacing.unit*2,
  },
  dialogPrice: {
    marginLeft: theme.spacing.unit*2,
  },
});

const TaskTable = (props) => {
  const {
    classes,
    type,
    quote,
    addQuoteItem,
    editQuoteItem,
    editQuoteForm,
    removeQuoteTaskItem
  } = props;

  let items = [];

  if (type.toLowerCase() === "labor") {
    items = quote.labor;
  }else if (type.toLowerCase() === "materials"){
    items = quote.materials;
  }

  const showDialog = (item) => {
    editQuoteForm({
      showAddItemDialog: true,
      dialogId: item.id,
      dialogDescription: item.description,
      dialogQuantity: item.quantity,
      dialogRate: item.rate,
      dialogType: type,
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={quote.showAddItemDialog}
        onClose={() => editQuoteForm({ showAddItemDialog: false })}
      >
        <DialogTitle>Add Item</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.dialogDescription}
            autoFocus
            multiline={true}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            value={quote.dialogDescription}
            onChange={ event => editQuoteForm({ dialogDescription: event.target.value })}
            fullWidth
          />
          <div className={classes.dialogRow}>
            <TextField
              className={classes.dialogQuantity}
              margin="dense"
              id="quantity"
              type="number"
              label="Quantity"
              type="number"
              value={quote.dialogQuantity}
              onChange={ event => editQuoteForm({ dialogQuantity: event.target.value })}
              fullWidth
            />

            <TextField
              className={classes.dialogPrice}
              margin="dense"
              id="unitPrice"
              label="Unit Price"
              type="number"
              value={quote.dialogRate}
              onChange={ event => editQuoteForm({ dialogRate: event.target.value })}
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => editQuoteForm({ showAddItemDialog: false })} >
            Cancel
          </Button>
          <Button color="primary" onClick={() => quote.dialogId ? editQuoteItem() : addQuoteItem()} >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.white} padding="dense" variant="head">Description</TableCell>
            <TableCell className={classes.white} padding="dense" variant="head" numeric>Qty</TableCell>
            <TableCell className={classes.white} padding="dense" variant="head" numeric>Rate</TableCell>
            <TableCell className={classes.white} padding="dense" variant="head" numeric>Markup</TableCell>
            <TableCell className={classes.white} padding="dense" variant="head" numeric>Total</TableCell>
            <TableCell className={classes.white} padding="none" variant="head" />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.filter(item => !item.isDelete).map((item) => {
            return(
              <TableRow
                hover
                key={item.id}
                style={{cursor: 'pointer'}}
              >
                <TableCell
                  className={classes.description}
                  padding="dense"
                  variant="body"
                  onClick={() => showDialog(item)}
                  >
                  <Typography className={classes.white}>
                    {item.description}
                  </Typography>
                </TableCell>
                <TableCell
                  padding="dense"
                  variant="body"
                  numeric
                  onClick={() => showDialog(item)}
                  >
                  <Typography className={classes.white}>{item.quantity}</Typography>
                </TableCell>
                <TableCell
                  padding="dense"
                  variant="body"
                  numeric
                  onClick={() => showDialog(item)}
                  >
                  <Typography className={classes.white}>${item.rate.toFixed(2)}</Typography>
                </TableCell>
                <TableCell
                  padding="dense"
                  variant="body"
                  numeric
                  onClick={() => showDialog(item)}
                  >
                  <Typography className={classes.white}>0</Typography>
                </TableCell>
                <TableCell
                  padding="dense"
                  variant="body"
                  numeric
                  onClick={() => showDialog(item)}
                  >
                  <Typography className={classes.white}>${item.total.toFixed(2)}</Typography>
                </TableCell>
                <TableCell padding="none" variant="body" numeric>
                  <Button
                    aria-label="delete"
                    size="small"
                    className={classes.deleteButton}
                    onClick={() => {
                      removeQuoteTaskItem(item.id, type.toLowerCase());
                    }}
                    style={{color: '#fff'}}
                    >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Button
        color="default"
        className={classes.button}
        onClick={() => editQuoteForm({ showAddItemDialog: true, dialogType: type })}
      >
        Add Item
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  quote: state.formData.quote,
});

const mapDispatchToProps = {
  addQuoteItem,
  editQuoteItem,
  editQuoteForm,
  removeQuoteTaskItem,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaskTable);
