import types from '../TooltipTypes';

const tourTooltips = [
  {
    id: types.tour.TOUR_MENU,
    title: "Menu",
    subtitle: "subtitle for menu tooltip",
    type: "secondary",
  },
  {
    id: types.tour.TOUR_MISSION,
    title: "Missions",
    subtitle: "subtitle for missions tooltip",
    type: "secondary",
  },
  {
    id: types.tour.TOUR_INFO_PANEL,
    title: "Info panel",
    subtitle: "subtitle for info panel tooltip",
    type: "secondary",
  },
];

export default tourTooltips;
