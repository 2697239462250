import { db, oid } from '../firebase';

export const getVendors = () =>
  db.ref(`appData/${oid}/vendors`);

export const createVendor = (vendor) =>
  db.ref(`appData/${oid}/vendors`).push(vendor)

export const updateVendor = (id, updates) =>
  db.ref(`appData/${oid}/vendors/${id}`).update(updates);

export const deleteVendor = (id) =>
  db.ref(`appData/${oid}/vendors/${id}`).remove();
