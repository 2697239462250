const defaultState = {
  items: [],
  unread: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case "SET_NOTIFICATIONS":
      return action.notifications ;
    default:
      return state;
  }
};
