import { db, storage } from '../../firebase';

export const setCompanyInfo = (companyInfo) => ({
  type: 'SET_COMPANY_INFO',
  companyInfo
});

export const startSetCompanyInfo = () => {
  return (dispatch) => db.getCompanyInfo()
    .on("value", snapshot => {
      const companyInfo = snapshot.val();
      dispatch(setCompanyInfo(companyInfo));
    });
};
