import { db, oid } from '../firebase';

export const getNotes = () =>
  db.ref(`appData/${oid}/notes`);

export const createNote = (note) =>
  db.ref(`appData/${oid}/notes`).push(note)
  .then((ref) => ({id: ref.key, ...note}));

export const updateNote = (noteId, updates) =>
  db.ref(`appData/${oid}/notes/${noteId}`).update(updates);
