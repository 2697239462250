import React, { Component } from 'react';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import indigo from '@material-ui/core/colors/indigo';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    width: '110px',
  },
  unselected: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
    '&:hover': {
      color: theme.palette.getContrastText(indigo[200]),
      backgroundColor: indigo[200],
    },
  },
  selected: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
    '&:hover': {
      backgroundColor: indigo[700],
    },
  },
});

class Toggle extends Component {
  constructor(props){
    super(props);
    this.state = { selected: false }
  }

  render() {
    const { classes, children, onToggle } = this.props;
    const { selected } = this.state;
    return(
      <Button
        variant="outlined"
        className={classnames(
          classes.root,
          selected ? classes.selected : classes.unselected
        )}
        onClick={() => {
          this.setState((prevState) => {
            onToggle({ key: children, selected: !prevState.selected });
            return ({ selected: !prevState.selected});
          });
        }}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(styles)(Toggle);
