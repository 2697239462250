import React from 'react'
import { connect } from 'react-redux'

import {
  Dialog
} from '../../core'

import {
  ClientForm,
} from '../Clients'

import {
  setClientForm,
  startRemoveClient,
  startOnDoneClient,
  resetClientForm,
} from 'store/actions'
import { showClientFormValidation } from 'store/actions/validation'
import { helpDispatch, Tooltip, TooltipTypes } from 'components/admin/Help'

const ClientDialog = (props) => {
  const {
    show,
    client,
    handleCreated,
    handleClose,
    resetClientForm,
    showClientFormValidation,
    startOnDoneClient,
    startRemoveClient,
  } = props

  return (
    <Dialog
      confirmDelete
      deleteTitle="Delete Client"
      deleteText="This action will delete the client. Proceed?"
      show={show}
      title="Client"
      type={client.id ? "edit" : "add"}
      handleCreated={handleCreated}
      onDone={ () => {
        return startOnDoneClient().then(result => {
          helpDispatch(TooltipTypes.ADD_INVOICE);
          return result;
        }) ;
      }}
      onDelete={ () => startRemoveClient() }
      onClose={() => {
        resetClientForm();
        handleClose();
      }}
      validate={() => {
        const { validation } = client;

        if (validation) {
          if(validation.isInvalid) {
            showClientFormValidation();
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }}
    >
      <Tooltip type={TooltipTypes.timecard.CLIENT_POPUP_FORM} placement="left">
        <div />
      </Tooltip>
      <Tooltip type={TooltipTypes.CREATE_CLIENT_DIALOG} placement="top">
        <ClientForm />
      </Tooltip>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  client: state.formData.client,
})

const mapDispatchToProps = {
  setClientForm,
  showClientFormValidation,
  startRemoveClient,
  startOnDoneClient,
  resetClientForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDialog)
