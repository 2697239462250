const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_RECEIPTS':
      return action.receipts;
    default:
      return state;
  }
};
