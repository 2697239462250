import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import indigo from '@material-ui/core/colors/indigo';

import MissionChecklist from './MissionChecklist';
import MissionPanels from './MissionPanels';
import { illustrations } from 'components/Illustration';
import { closeHelp, closeMissions, startMission } from 'store/actions/help';
import { startEditAdmin } from 'store/actions/admin';
import MissionIcon from 'img/icons8-rocket-96.png';

const styles = theme => ({
  root: {

  },
  closeButton: {
    color: '#fff',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing.unit*3,
  },
  img: {
    width: '150px',
    height: '150px',
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text1: {
    marginTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit*3,
  },
  title: {
    backgroundColor: indigo[500],
  },
  titleContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleText: {
    color: "#fff"
  },
});

const Missions = (props) => {
  const {
    classes,
    companyName,
    help,
    closeMissions,
  } = props;

  return (
    <Dialog
      fullScreen
      open={help.showMissions}
    >
      <DialogTitle className={classes.title}>
        <div className={classes.titleContent}>
          <div className={classes.titleLeft}>
            <IconButton
              className={classes.closeButton}
              onClick={closeMissions}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subheading" className={classes.titleText}>
              {companyName}
            </Typography>
          </div>

          <Button
            className={classes.closeButton}
            onClick={closeMissions}
          >
            Close
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <div className={classes.center}>
            <Typography
              variant="display1"
              className={classes.headerTitle}
            >
              Business Setup and Training
            </Typography>
            <div className={classes.row1}>
              <Typography style={{ maxWidth: '550px' }}>
                Here you'll setup your business in Couraggo, maybe do stuff outside Couraggo and quickly learn how the system works.
              </Typography>
              <div>
                <img className={classes.img} alt="" src={illustrations.get("maker_launch_amber")} />
              </div>
            </div>
            <div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography>
                You'll learn by doing, look for the
              </Typography>
              <img
                style={{ marginLeft: '8px', marginRight: '8px', width: '35px', height: '35px' }}
                src={MissionIcon}
                alt="Missions"
              />
              <Typography>
                below to do a mission... these teach you stuff and customize the system for you
              </Typography>
            </div>

            {false && <MissionPanels />}
            <MissionChecklist />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const account = state.accountState ? state.accountState : {};
  const organization = account.organizations[0] ? account.organizations[0] : {};
  const about = organization.about ? organization.about : {};

  return ({
    help: state.help,
    companyName: about.name,
  });
}

const mapDispatchToProps = {
  closeHelp,
  closeMissions,
  startMission,
  startEditAdmin,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Missions);
