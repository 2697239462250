import { db } from '../../../firebase';
import { validateWebsite } from 'store/actions/website';
export const startEditWebsiteRoot = (updates) => {
  return (dispatch) => {
    return db.updateWebsiteRoot(updates);
  };
};

export const setWebsiteRoot = (root) => ({
  type: 'SET_WEBSITE_ROOT',
  root
});

export const startSetWebsiteRoot = () => {
  return (dispatch) => {
    return db.getWebsiteRoot().on('value', snapshot => {
      const websiteRoot = snapshot.val() ? snapshot.val() : {};
      if (!websiteRoot.heroImage){
        websiteRoot.heroImage = {};
      }
      dispatch(setWebsiteRoot(websiteRoot));
      dispatch(validateWebsite());
      //dispatch({ type: 'VALIDATE_WEBSITE' });
    });
  };
};
