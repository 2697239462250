import React, { Component } from 'react'
import ReactDOM from "react-dom"
import { connect } from 'react-redux'
import { compose } from 'recompose'
import $ from 'jquery'

import MaterialTooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import primary from '@material-ui/core/colors/yellow'
import secondary from '@material-ui/core/colors/pink'

import { analytics } from 'analytics'
import tooltips from './Types'
import TooltipContent from './TooltipContent'

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}


const styles = theme => ({
  tooltip: {
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 18,
    padding: 0,
    opacity: 1,
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.light,
  },
  secondaryColor: {
    backgroundColor: theme.palette.primary.dark,
  },
  arrowPopperPrimary: arrowGenerator(primary[100]),
  arrowPopperSecondary: arrowGenerator(secondary[100]),
  arrow: {
    position: 'absolute',
    fontSize: 17,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
})

const getTooltipContent = (classes, type, tooltip, ref) => {
  return (
    <React.Fragment>
      <TooltipContent title={type} type={type} tooltip={tooltip} />
      <span className={classes.arrow} ref={ref} />
    </React.Fragment>
  )
}

const getArrowPopperStyles = (classes, tooltip) => {
  if (tooltip.type === "primary") {
    return classes.arrowPopperPrimary
  } else if (tooltip.type === "secondary") {
    return classes.arrowPopperSecondary
  }
}

class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arrowRef: null,
      hasAnimated: false,
    }
  }


  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    })
  }

  componentDidMount = () => this.handleScroll()

  componentDidUpdate = () => this.handleScroll()

  handleAnimated = () => this.setState({ hasAnimated: true })

  handleScroll = () => {
    if(!this.state.hasAnimated) {
      const { help, type } = this.props
      const currentMission = help.currentMission
      const currentStepIndex = currentMission.currentStep
      const currentStep = currentMission.steps[currentStepIndex]

      if(currentStep && currentStep.primaryType === type) {
        try {
          const viewHeight = $(window).height()
          const center = viewHeight/2
          const top = ReactDOM.findDOMNode(this).getBoundingClientRect().top
          const position = top - center

          $("html, body").animate(
            { scrollTop: position },
            1000,
            this.handleAnimated.bind(this)
          )

        } catch (error) {
          console.log("EXCEPTION tooltip handleScroll:", error)
          analytics.exception(`Tooltip::handleScroll [message:${error.message}]`)
        }
      }
    }
  }

  render() {
    const {
      classes,
      children,
      isMobile,
      placement,
      tour,
      type,
      help
    } = this.props

    const { arrowRef } = this.state
    const currentMission = help.currentMission
    const currentStepIndex = currentMission.currentStep
    const currentStep = currentMission.steps[currentStepIndex]
    const tooltip = tooltips.get(type)
    let showTooltip

    if(isMobile) {
      showTooltip = false
    } else if (help.showTour && tour) {
      showTooltip = true
    } else if (help.showHelp) {
      showTooltip = true
    } else if (!currentStep) {
      showTooltip = false
    } else if((currentStep.primaryType === type) || (currentStep.secondaryType === type)) {
      showTooltip = true
    } else {
      showTooltip = false
    }

    if (showTooltip && type) {
      return(
        <MaterialTooltip
          open={showTooltip}
          placement={placement}
          title={getTooltipContent(classes, type, tooltip, this.handleArrowRef)}
          classes={{ tooltip: classes.tooltip, popper: getArrowPopperStyles(classes, tooltip) }}
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(arrowRef),
                  element: arrowRef,
                },
              },
            },
          }}
        >
          {children}
        </MaterialTooltip>
      )
    } else {
      return children
    }
  }
}


const mapStateToProps = (state) => ({
  isMobile: state.adminState.isMobile,
  help: state.help,
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Tooltip)
