import React from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import SelectMaterial from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
  }
})

const Select = ({ classes, label, inputProps, items = [], value, onChange }) =>
  <FormControl className={classes.root}>
    <InputLabel>{label}</InputLabel>
    <SelectMaterial
      value={value}
      onChange={onChange}
      inputProps={inputProps}
    >
      {items.map(item => <MenuItem value={item.value}>{item.label}</MenuItem>)}
    </SelectMaterial>
  </FormControl>

export default withStyles(styles)(Select)
