const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_WORK_TYPES':
      return action.workTypes;
    default:
      return state;
  }
};
