const defaultState = {
  invoice: null,
  client: null,
  title: null,
  selectedDrawerItem: null,
  isMobile: false,
  trialSignUp: false,
  trialSignUpData: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'EDIT_ADMIN':
      return { ...state, ...action.updates }
    case 'SET_ADMIN':
      return { ...state, ...action.admin }
    default:
      return state
  }
}
