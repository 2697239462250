const defaultState = {
  organizations: [
    {
      about: {},
      subscription: {}
    }
  ],
  user: {
    profile: {}
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_ACCOUNT_USER':
      return { ...state, user: action.user };
    case 'SET_ACCOUNT_ORGANIZATION':
      return { ...state, organizations: action.organizations };
    default:
      return state;
  }
};
