import store from 'store'
import { startSetClients } from 'store/actions/clients'
import { startSetInvoices } from 'store/actions/invoices'
import { startSetProjects } from 'store/actions/projects'
import { startSetProjectTimes } from 'store/actions/projectTimes'
import { startSetTaxRules } from 'store/actions/taxRules'
import { startSetAdmin } from 'store/actions/admin'
import { startSetBusiness } from 'store/actions/business'
import { startSetReceipts } from 'store/actions/receipts'
import { startSetBids, startSetBidTasks } from 'store/actions/bids'
import { startSetBidEditor } from 'store/actions/bidEditor'
import { startSetPreview } from 'store/actions/preview'
import { startSetVendors } from 'store/actions/vendors'
import { startSetTaxCategories } from 'store/actions/taxCategories'
import { startSetCrew } from 'store/actions/crew'
import { startSetTimecards } from 'store/actions/timecards'
import { startSetProjectTasks } from 'store/actions/projectTasks'
import { startSetNotes } from 'store/actions/notes'
import { startSetFiles } from 'store/actions/files'
import { startSetHeroImages } from 'store/actions/heroImages'
import { startSetContactImages } from 'store/actions/contactImages'
import { startSetWorkTypes } from 'store/actions/workTypes'
import { startSetCompanyInfo } from 'store/actions/companyInfo'
import {
  startSetAccountUser,
  startSetAccountOrganizations,
} from 'store/actions/account'

import {
  startSetWebsitePortfolio,
  startSetWebsiteRoot,
  startSetWebsiteServices,
  startSetWebsiteTestimonials,
  startSetWebsiteFAQS,
  startSetWebsiteTeam,
  startSetWebsiteDocuments,
} from 'store/actions/website'
import { startSetMissions } from 'store/actions/help'
import { startSetOrgDomains } from 'store/actions/orgDomains'
import { startSetSubscription } from 'store/actions'
import { startSetLightLogo, startSetDarkLogo } from 'store/actions/logos'
import { startSetNotifications } from 'store/actions/notifications'
import { startSetReminders } from 'store/actions/reminders'
import { startSetTaxTypes } from 'store/actions/taxTypes'

const loadStore = () => {
  store.dispatch(startSetClients())
  store.dispatch(startSetInvoices())
  store.dispatch(startSetProjects())
  store.dispatch(startSetProjectTimes())
  store.dispatch(startSetTaxRules())
  store.dispatch(startSetAdmin())
  //store.dispatch(startSetBusiness());
  store.dispatch(startSetReceipts())
  store.dispatch(startSetBids())
  store.dispatch(startSetBidTasks())
  store.dispatch(startSetBidEditor())
  store.dispatch(startSetPreview())
  store.dispatch(startSetVendors())
  store.dispatch(startSetTaxCategories())
  store.dispatch(startSetCrew())
  store.dispatch(startSetTimecards())
  store.dispatch(startSetProjectTasks())
  store.dispatch(startSetNotes())
  store.dispatch(startSetFiles())
  store.dispatch(startSetHeroImages())
  store.dispatch(startSetContactImages())
  store.dispatch(startSetWorkTypes())
  store.dispatch(startSetCompanyInfo())
  store.dispatch(startSetWebsitePortfolio())
  store.dispatch(startSetWebsiteRoot())
  store.dispatch(startSetWebsiteServices())
  store.dispatch(startSetWebsiteTestimonials())
  store.dispatch(startSetWebsiteFAQS())
  store.dispatch(startSetWebsiteTeam())
  store.dispatch(startSetWebsiteDocuments())
  store.dispatch(startSetAccountUser())
  store.dispatch(startSetAccountOrganizations())
  store.dispatch(startSetMissions())
  store.dispatch(startSetOrgDomains())
  store.dispatch(startSetSubscription())
  store.dispatch(startSetLightLogo())
  store.dispatch(startSetDarkLogo())
  store.dispatch(startSetNotifications())
  store.dispatch(startSetReminders())
  store.dispatch(startSetTaxTypes())
}

export {
  loadStore,
}
