import types from '../TooltipTypes';

const setupTooltips = [
  {
    id: types.setup.ADD_CLIENT,
    title: "Add Client",
    subtitle: "Click the yellow plus button or FAB to add clients",
    type: "primary",
  },
  {
    id: types.setup.TABS,
    title: "Setup Tabs",
    subtitle: "When you come back, click on these Tabs to view all the data.",
    type: "secondary",
  },
  {
    id: types.setup.ACTIONS,
    title: "Actions",
    subtitle: "On these quick entry forms, all the Action buttons are at the bottom.",
    type: "secondary",
  }
];

export default setupTooltips;
