import { db } from '../../firebase';

export const setProjectTimes = (projectTimes) => ({
  type: 'SET_PROJECT_TIMES',
  projectTimes
});

export const startSetProjectTimes = () => {
  return (dispatch) => {
    return db.getProjectTimes().on('value', (snapshot) => {
      const projectTimes = [];
      snapshot.forEach((childSnapshot) => {

        childSnapshot.forEach((projectTimeSnapshot) => {
          projectTimes.push({
            projectID: childSnapshot.key,
            id: projectTimeSnapshot.key,
            ...projectTimeSnapshot.val()
          });
        });
      });

      dispatch(setProjectTimes(projectTimes));
    });
  };
};
