import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import amber from '@material-ui/core/colors/amber';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MissionsActive from '@material-ui/icons/Visibility';
import MailIcon from '@material-ui/icons/Mail';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import MissionIcon from '@material-ui/icons/FlightTakeoff';

import { displayAcceptMission, startMission } from 'store/actions/help';
import { startEditAdmin } from 'store/actions/admin';

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 46,
    '&$expanded': {
      minHeight: 46,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

const styles = theme => ({
  root: {

  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  row: {
    marginTop: theme.spacing.unit*2,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  avatar: {
    marginRight: theme.spacing.unit*2,
    color: '#fff',
    backgroundColor: indigo[100],
  },
  mission: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  missionsIcon: {
    color: indigo[300],
  },
  icon: {
    marginRight: theme.spacing.unit*2,
    color: amber[300],
  },
  iconCompleted: {

  },
  checkboxRow: {
     display: 'flex',
     flexDirection: 'row',
     alignItems: 'center'
  },
  rootCheckbox: {
    height: '30px',
    color: grey[600],
    '&$checked': {
      color: grey[500],
    },
  },
  checked: {},
});

const getIcon = (type) => {
  switch (type.toLowerCase()) {
    case "invoicing":
      return <AttachMoneyIcon />;
    case "bids":
      return <AssignmentIcon />;
    case "website":
      return <MailIcon />;
    case "time & receipts":
      return <QueryBuilderIcon />;
    default:

  }
}
const MissionPanel = (props) => {
  const {
    classes,
    title,
    missions,
    displayAcceptMission,
  } = props;

  return(
    <ExpansionPanel expanded={true}>
      <ExpansionPanelSummary expandIcon={<MissionsActive className={classes.missionsIcon} />} >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Avatar className={classes.avatar}>
            {getIcon(title)}
          </Avatar>
          <Typography variant="title" className={classes.heading}>{title}</Typography>
        </div>

      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.row}>
          <div className={classes.column}>
            {missions.map(mission => {
              return (
                <div
                  className={classes.mission}
                  onClick={() => {
                    switch (title.toLowerCase()) {
                      case "invoicing":
                        displayAcceptMission("invoice-1");
                        // startEditAdmin({ invoice: null });
                        // history.push(routes.INVOICES);
                        // startMission("invoice-1");
                        break;
                      case "time & receipts":
                        displayAcceptMission("timecard-1");
                        // history.push(routes.TIMECARDS);
                        // startMission("timecard-1");
                        break;
                      default:

                    }

                  }}
                >
                  <MissionIcon className={classes.icon} />
                  <Typography>
                    {mission.title}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div className={classes.column}>
            <div className={classes.checkboxRow}>
              <Checkbox
                checked={true}
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checked,
                }}
              />
              <Typography>test checklist item 1</Typography>
            </div>
            <div className={classes.checkboxRow}>
              <Checkbox
                checked={true}
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checked,
                }}
              />
              <Typography>test checklist item 1</Typography>
            </div>
            <div className={classes.checkboxRow}>
              <Checkbox
                checked={true}
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checked,
                }}
              />
              <Typography>test checklist item 1</Typography>
            </div>
            <div className={classes.checkboxRow}>
              <Checkbox
                checked={false}
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checked,
                }}
              />
              <Typography>test checklist item 1</Typography>
            </div>
            <div className={classes.checkboxRow}>
              <Checkbox
                checked={false}
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checked,
                }}
              />
              <Typography>test checklist item 1</Typography>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

const mapDispatchToProps = {
  displayAcceptMission,
  startMission,
  startEditAdmin,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(MissionPanel);
