import React, { Component } from 'react'
import PropTypes from 'prop-types'


const withAnalytics = (WrappedComponent) => {
  class Analytics extends Component {

    render() {
      return <WrappedComponent analytics={this.context.analytics} {...this.props} />;
    }
  }

  Analytics.contextTypes = {
    analytics: PropTypes.object
  }

  return Analytics
}



export default withAnalytics
