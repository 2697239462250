import uuid from 'uuid';

//TODO: schedule needs to be completely redesigned, in it's current state
//it's broken beyond belief
export const generateSchedule = (project) => {

  const schedule = [];
  if(project.startDate === undefined) return schedule;

  const startDate = new Date(project.startDate);
  const endDate = new Date(project.endDate);
  startDate.setDate(1);
  endDate.setDate(0);

  let index = new Date(startDate);
  if (project.isRecurring) {
    while (index <= endDate) {
      let date = new Date(index);
      schedule.push({
          id: uuid.v4(),
          date: date,
          show: true,
          invoice: undefined,
          scheduleDate: date,
          get displayName() {
            const monthNames = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return monthNames[this.date.getMonth()];
          },
          get shortDisplayName() {
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return monthNames[this.date.getMonth()];
          }
      });

      index = new Date(index.setMonth(index.getMonth() + 1));
    }
  } else {
    //TODO: intervals should be moved to a projectIntervals level
    //in firebase. Temporary hack to just get project schedules working
    //for project type jobs until schedules can be completely rewritten.
    const intervals = project.intervals;
    if (intervals !== undefined) {
      Object.entries(intervals).forEach((interval) => {
        const result = {id: interval[0], ...interval[1]};
        schedule.push({
          id: uuid.v4(),
          date: new Date(),
          intervalId: result.id,
          invoice: undefined,
          get displayName() {
            return result.name;
          }
        });
      });
    }
  }

  return schedule;
}
