import moment from 'moment'
let debug = false
const setDebug = (isDebug) => debug = isDebug
const lastEventMoments = new Map()
const loadMoment = moment()
//let lastEventMoment = loadMoment

const dispatch = (eventName, params) => {
  const currentMoment = moment()
  const secondsToEvent = currentMoment.diff(loadMoment, 'seconds')
  const lastEventMoment = lastEventMoments.has(params.event_category)
    ? lastEventMoments.get(params.event_category)
    : currentMoment
  const secondsBetweenEvents = currentMoment.diff(lastEventMoment, 'seconds')
  lastEventMoments.set(params.event_category, currentMoment)

  params.seconds_to_event = secondsToEvent
  params.seconds_between_events = secondsBetweenEvents

  if(!debug && window.gtag) {
    window.gtag('event', eventName, params)
    console.log("Google analytics dispatched: {eventName, params}", eventName, params)
  } else {
    console.log("Google analytics dispatch: {eventName, params}", eventName, params)
  }
}

export {
  dispatch,
  setDebug
}
