import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import $ from 'jquery'

import indigo from '@material-ui/core/colors/indigo'
import { withStyles } from '@material-ui/core/styles'

import { withAnalytics } from 'analytics'
import withAuthentication from './auth/withAuthentication'

import Fade from '@material-ui/core/Fade'
import Preloader from './Preloader'

import { startEditAdmin } from 'store/actions/admin'

import * as routes from '../constants/routes'

const Admin = lazy(() => import('./admin/Admin'))
const PasswordForgetPage = lazy(() => import('./auth/PasswordForget'))
const SignInPage = lazy(() => import('./auth/SignIn'))
const SignUpPage = lazy(() => import('./auth/SignUp'))
const Trial = lazy(() => import('components/admin/Trial'))

const smWidth = 768

const styles = theme => ({
  root: {
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
  },
  rootOLD: {
    backgroundColor: "#f9f9f9",
    backgroundColor: 'orange',
    minHeight: "100vh",
    display: "flex",
  },
  admin: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  '@media print': {
    root: {
      display: 'none',
    }
  },
})


class App extends Component {
  constructor(props) {
    super(props)
    const windowWidth = $(window).width()
    const isMobile = windowWidth < smWidth ? true : false
    const { startEditAdmin } = props
    startEditAdmin({ isMobile })

    this.state = {
      currentWidth: $(window).width(),
      showLoading: true,
      loadingFinished: false,
    }
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ showLoading: false }) }, 3000)
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    if($(window).width() !== this.state.currentWidth) {
      this.setState((prevState, props) => {
        const { startEditAdmin } = props
        const { currentWidth } = prevState
        const resizeWidth = $(window).width()
        const newState = {}

        if (resizeWidth !== currentWidth) {
          newState.currentWidth = resizeWidth
          if(resizeWidth < smWidth) {
            startEditAdmin({ isMobile: true })
          } else {
            startEditAdmin({ isMobile: false })
          }
        }

        return newState
      })
    }
  }

  handleLoadingFinished() {
    const { analytics } = this.props
    analytics.appLoaded()
    this.setState({ loadingFinished: true })
  }

  //TODO: replace current fade transition hack with react-motion instead
  render() {
    const { authUser, classes, isMobile, session, preview } = this.props
    const { minWidth, currentWidth, showLoading, loadingFinished } = this.state
    const pathname = window.location.pathname

    if (!loadingFinished) {
      return (<Preloader onFinished={this.handleLoadingFinished.bind(this)} />)
    } else {
      if (authUser) {
        return(
            <Router>
              <Suspense fallback={<Preloader />}>
                <Switch>
                  <Route exact path="/" render={() => (
                    authUser ? (
                      <Redirect to={routes.WEBSITE} />
                    ) : (
                      <div />
                    )
                  )}/>
                  <Route exact path={routes.TRIAL} component={Trial} />
                  <Route path="/admin" component={Admin}/>
                  <Route exact path={routes.SIGN_IN} component={SignInPage} />
                  <Route exact path={routes.SIGN_UP} component={SignUpPage} />
                  <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
                </Switch>
              </Suspense>
            </Router>
        );
      } else {
        return (
          <Router>
            <Suspense fallback={<Preloader />}>
              <Switch>
                <Route exact path={routes.TRIAL} component={Trial} />
                <Route path={routes.SIGN_IN} component={SignInPage} />
                <Route path={routes.SIGN_UP} component={SignUpPage} />
                <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
                <Route component={SignInPage} />
              </Switch>
            </Suspense>
          </Router>
        );
      }
    }
  }
}


const mapStateToProps = (state) => ({
  isMobile: state.adminState.isMobile,
  authUser: state.sessionState.authUser,
  preview: state.previewState,
})

const mapDispatchToProps = {
  startEditAdmin
}

export default compose(
  withAuthentication,
  withAnalytics,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(App)
