import { db, oid } from '../firebase';

export const getReminders = () =>
  db.ref(`appData/${oid}/reminders`)

export const createReminder = (reminder) =>
  db.ref(`appData/${oid}/reminders`).push(reminder)

export const deleteReminder = (id) =>
  db.ref(`appData/${oid}/reminders/${id}`).remove()

export const updateReminder = (id, updates) =>
  db.ref(`appData/${oid}/reminders/${id}`).update(updates)
