import TooltipTypes from './Tooltips/TooltipTypes';

export const invoiceMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Invoice',
  steps: [
    {
      primaryType: TooltipTypes.ADD_INVOICE,
      secondaryType: TooltipTypes.INVOICE_CARDS_SIDE,
    },
    {
      primaryType: TooltipTypes.INVOICE_ADD_CLIENT,
    },
    {
      primaryType: TooltipTypes.CREATE_CLIENT_DIALOG
    },
    {
      primaryType: TooltipTypes.INVOICE_ADD_ITEM
    },
    {
      primaryType: TooltipTypes.INVOICE_SAVE_ITEM
    },
    {
      primaryType: TooltipTypes.INVOICE_PREVIEW_SEND
    },
    {
      primaryType: TooltipTypes.INVOICE_SEND,
      secondaryType: TooltipTypes.INVOICE_SEND_EMAIL
    },
    {
      success: true,
    }
  ]
});

export const websiteMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Website',
  steps: [
    {
      primaryType: TooltipTypes.website.SELECT_THEME,
      secondaryType: TooltipTypes.website.ACTIONS,
    },
    {
      primaryType: TooltipTypes.website.THEME_DIALOG,
    },
    {
      primaryType: TooltipTypes.website.BASIC_INFO,
      secondaryType: TooltipTypes.website.HEADER_INFO_AREA,
    },
    {
      primaryType: TooltipTypes.website.PREVIEW,
      secondaryType: TooltipTypes.website.INFO_PUBLISH,
    },
    {
      primaryType: TooltipTypes.website.CLOSE,
    },
    {
      success: true,
    }
  ]
});

export const setupMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Setup',
  steps: [
    {
      primaryType: TooltipTypes.setup.ADD_CLIENT,
      secondaryType: TooltipTypes.setup.TABS,
    },
    {
      primaryType: TooltipTypes.CREATE_CLIENT_DIALOG,
      secondaryType: TooltipTypes.setup.ACTIONS,
    },
    {
      success: true,
    }
  ]
});

export const bidMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Bid',
  steps: [
    {
      primaryType: TooltipTypes.bid.ADD,
      secondaryType: TooltipTypes.bid.BID_INFO_PANEL,
    },
    {
      primaryType: TooltipTypes.bid.ADD_CLIENT,
    },
    {
      primaryType: TooltipTypes.CREATE_CLIENT_DIALOG,
      secondaryType: TooltipTypes.clientDialog.OPTIONAL_EMAIL,
    },
    {
      primaryType: TooltipTypes.bid.ADD_SALES_BASIC,
      secondaryType: TooltipTypes.bid.SECTIONS,
    },
    {
      primaryType: TooltipTypes.bid.ENTER_INFO,
      secondaryType: TooltipTypes.bid.SALES_SECTION,
    },
    {
      primaryType: TooltipTypes.bid.ADD_TASK_SIMPLE,
      secondaryType: TooltipTypes.bid.ACTIONS_INFO,
    },
    {
      primaryType: TooltipTypes.bid.ENTER_INFO_TASK,
      secondaryType: TooltipTypes.bid.HELP_INFO_PANEL,
    },
    {
      primaryType: TooltipTypes.bid.CLOSE,
    },
    {
      success: true,
    }
  ]
});


export const receiptMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Receipt',
  steps: [
    {
      primaryType: TooltipTypes.receipt.ADD,
    },
    {
      primaryType: TooltipTypes.receipt.SAVE_DIALOG,
    },
    {
      success: true,
    }
  ]
});

export const jobMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Job',
  steps: [
    {
      primaryType: TooltipTypes.job.ADD,
      secondaryType: TooltipTypes.job.FILTERS,
    },
    {
      primaryType: TooltipTypes.job.BASIC_INFO,
      secondaryType: TooltipTypes.job.TABS,
    },
    {
      success: true,
    }
  ]
});

export const timecardMission = (id) => ({
  currentStep: 0,
  inProgress: true,
  id: id,
  type: 'Timecard',
  steps: [
    {
      primaryType: TooltipTypes.ADD_TIMECARD,
      secondaryType: TooltipTypes.timecard.INFO_PANEL,
    },
    {
      primaryType: TooltipTypes.TIMECARD_CHANGE_TIME,
      secondaryType: TooltipTypes.TIMECARD_NOTES,
    },
    {
      primaryType: TooltipTypes.TIMECARD_CREATE_CREW,
    },
    {
      primaryType: TooltipTypes.CREATE_CREW_DIALOG,
      secondaryType: TooltipTypes.timecard.POPUP_FORM,
    },
    {
      primaryType: TooltipTypes.TIMECARD_CREATE_CLIENT,
    },
    {
      primaryType: TooltipTypes.CREATE_CLIENT_DIALOG,
      secondaryType: TooltipTypes.timecard.CLIENT_POPUP_FORM,
    },
    {
      primaryType: TooltipTypes.TIMECARD_SELECT_WORKTYPE,
      secondaryType: TooltipTypes.timecard.WORKTYPES,
    },
    {
      primaryType: TooltipTypes.TIMECARD_BILL_TO,
      secondaryType: TooltipTypes.TIMECARD_CHARGES,
    },
    {
      primaryType: TooltipTypes.TIMECARD_SAVE,
      secondaryType: TooltipTypes.TIMECARD_REVIEW,
    },
    {
      success: true,
    }
  ]
});
