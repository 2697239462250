const defaultState = {
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_SUBSCRIPTION':
      return { ...action.subscription };
    default:
      return state;
  }
};
