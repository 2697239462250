import { db, storage } from '../../firebase';

export const setContactImages = (contactImages) => ({
  type: 'SET_CONTACT_IMAGES',
  contactImages,
});

export const startSetContactImages = () => {
  return (dispatch) => db.getContactImages().on('value', (snapshot) => {
    const contactImages = [];

    snapshot.forEach((childSnapshot) => {
      const contactImage = {id: childSnapshot.key, ...childSnapshot.val()};

      storage.getContactImageURL(contactImage.filename)
        .then(result => {
          contactImage.downloadURL = result;
          contactImages.push(contactImage);
        });
    });

    dispatch(setContactImages(contactImages));
  });
};
