import { db } from '../../../firebase';

import { selectTimecardsList, selectReceiptsList } from '../../selectors';

export const startUpdateTimecards = () => {
  return (dispatch, getState) => {
    const promises = [];
    const { importTimeMaterials } = getState().formData;

    importTimeMaterials.timecards.forEach(timecard => {
      if(timecard.exclude && timecard.updated) {
        promises.push(db.updateTimecard(timecard.id, { exclude: true }))
      }
    });

    importTimeMaterials.receipts.forEach(receipt => {
      if(receipt.exclude && receipt.updated) {
        promises.push(db.updateReceipt(receipt.id, { exclude: true }));
      }
    });

    return Promise.all(promises);
  }
}

export const startAddLineItems = (invoiceId) => {
  return (dispatch, getState) => {
    const promises = [];
    const { importTimeMaterials } = getState().formData;
    const lineItems = importTimeMaterials.lineItems;
    lineItems.forEach(lineItem => {
      promises.push(db.createLineItem(invoiceId, lineItem));
    })

    return Promise.all(promises);
  }
}

export const editImportMaterial = (id, updates) => ({
  type: "EDIT_IMPORT_MATERIAL",
  id,
  updates
});

export const editImportTime = (id, updates) => ({
  type: "EDIT_IMPORT_TIME",
  id,
  updates
});

export const setImportTimeMaterials = (importTimeMaterials) => ({
  type: "SET_IMPORT_TIME_MATERIALS",
  importTimeMaterials
});

// const includeTimecard = (timecard, invoice) => {
//   const isBillable = timecard.billToClient;
//   if (isBillable && timecard)
// }
export const startSetImportTimeMaterials = (invoice) => {
  return (dispatch, getState) => {
    const {
      timecardsState,
      receiptsState,
      workTypesState,
      invoicesState,
    } = getState();

    const timecards = [];
    const receipts = [];
    const timecardLineItems = [];
    const receiptLineItems = [];
    console.log("initial TC state:", timecardsState);

    invoicesState.forEach(invoice => {
      invoice.lineItems.forEach((lineItem, id) => {
        if (lineItem.timecardId) {
          timecardLineItems.push(lineItem);
        } else if (lineItem.receiptId) {
          receiptLineItems.push(lineItem);
        }
      });
    });

    timecardsState.forEach(timecard => {
      //First check to see if timecard has a related project, if so then checK
      //to see if the invoices project matches.
      const projectMatch = !timecard.projectId || timecard.projectId === invoice.projectId;

      //Second check to see if the timecard has a related client, if so then check
      //to see if the invoice client matches.
      const clientMatch = !timecard.clientId || timecard.clientId === invoice.clientId;

      if (timecard.billToClient && projectMatch && clientMatch) {
        const lineItem = timecardLineItems.find(lineItem => lineItem.timecardId === timecard.id);
        if (!lineItem) {
          const workType = workTypesState.find(workType => workType.id === timecard.workTypeId);

          timecards.push({
            ...timecard,
            workType,
            include: false,
          });
        }
      }
    });

    receiptsState.forEach(receipt => {
      const projectMatch = !receipt.projectId || receipt.projectId === invoice.projectId;
      const clientMatch = !receipt.clientId || receipt.clientId === invoice.clientId;

      if (receipt.type === "rebill" && projectMatch && clientMatch) {
        const lineItem = receiptLineItems.find(lineItem => lineItem.receiptId === receipt.id);
        if (!lineItem) {
          receipts.push({
            ...receipt,
            include: false,
          });
        }
      }
    });

    const importTimeMaterials = {
      timecards,
      receipts,
      lineItems: [],
    };

    dispatch(setImportTimeMaterials(importTimeMaterials));
    return Promise.resolve();
  }
}

export const resetImportTimeMaterials = () => ({
  type: "RESET_IMPORT_TIME_MATERIALS"
});
