import { db } from '../../../firebase';

export const startAddWebsiteFAQ = (faq) => {
  return (dispatch) => db.createWebsiteFAQ(faq);
}

export const startEditWebsiteFAQ = (id, updates) => {
  return (dispatch) => db.updateWebsiteFAQ(id, updates);
}

export const startRemoveWebsiteFAQ = (id) => {
  return (dispatch) => db.deleteWebsiteFAQ(id);
};

export const setWebsiteFAQS = (faqs) => ({
  type: 'SET_WEBSITE_FAQS',
  faqs
});

export const startSetWebsiteFAQS = () => {
  return (dispatch) => {
    return db.getWebsiteFAQS().on('value', snapshot => {
      const faqs = [];

      snapshot.forEach(childSnapshot => {
        faqs.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      dispatch(setWebsiteFAQS(faqs));
      dispatch({ type: 'VALIDATE_WEBSITE' });
    });
  };
};
