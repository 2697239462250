import moment from 'moment';

export const selectProjectStatus = (store, project) => {
  return project.status ? project.status : "new";
}

export const filterProjectList = (store, project) => {
  const filter = store.filters.projectList;
  const projectStatus = selectProjectStatus(store, project).toLowerCase();

  switch (filter.type) {
    case "all":
      if (filter.showArchived) {
        return true;
      } else if (projectStatus === "closed") {
        return false;
      } else {
        return true;
      }
    case projectStatus:
      return true;
    default:
      return false;
  }
}

export const sortProjectList = (a, b , store) => {
  const filter = store.filters.projectList;
  switch (filter.orderBy) {
    case "client":
      const clientA = store.clientsState.find(client => client.id === a.clientId);
      const clientB = store.clientsState.find(client => client.id === b.clientId);

      if (!clientA) {
        return filter.direction === "asc" ? -1 : 1;
      } else if (!clientB) {
        return filter.direction === "asc" ? 1 : -1;
      } else {
        return filter.direction === "asc"
          ? clientA.fullName.localeCompare(clientB.fullName)
          : clientB.fullName.localeCompare(clientA.fullName);
      }
    case "job":
      const aJobCode = a.jobCode ? a.jobCode : "";
      const bJobCode = b.jobCode ? b.jobCode : "";

      return filter.direction === "asc"
        ? aJobCode.localeCompare(bJobCode)
        : bJobCode.localeCompare(aJobCode);
    default:
      const aProject = store.projectsState.find(project => project.id === a.id);
      const bProject = store.projectsState.find(project => project.id === b.id);
      let aDate = aProject.startDate && moment(aProject.startDate);
      let bDate = bProject.startDate && moment(bProject.startDate);

      if (aDate && bDate) {
        return filter.direction === "asc" ? aDate.diff(bDate) : bDate.diff(aDate);
      }
  }
}

export const selectProjectList = (store) => {
  return (
    store.projectsState.filter(project => filterProjectList(store, project))
      .sort((a,b) => sortProjectList(a, b, store))
  );
}

export const selectProjectTotals = (store, project) => {
  const projectTasks = store.projectTasksState.filter(task => task.projectId === project.id);
  let monthlyAmt = 0;
  let fixedAmt = 0;
  projectTasks.forEach(task => {
    monthlyAmt += task.monthlyAmount ? Number(task.monthlyAmount) : 0;
    fixedAmt += task.estimate ? Number(task.estimate) : 0;
    if (task.labor) {
      task.labor.forEach(item => fixedAmt += Number(item.total));
    }
    if (task.materials) {
      task.materials.forEach(item => fixedAmt += Number(item.total));
    }
  });

  return [
    {
      amount: monthlyAmt,
      type: "monthly",
    },
    {
      amount: fixedAmt,
      type: "fixed",
    },
  ]
}
