import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import amber from '@material-ui/core/colors/amber'
import MissionIcon from 'img/icons8-rocket-96.png'

import { illustrations } from 'components/Illustration'
import { startEditAdmin } from 'store/actions/admin'
import {
  closeMissions,
  displayAcceptMission,
  showMissions
} from 'store/actions/help'

const styles = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing.unit,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  buttonRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  actionsCaption: {

  },
  missionIcon: {
    color: amber[300],
    width: '30px',
    height: '30px',
    marginRight: theme.spacing.unit*2,
  },
  missionsIcon: {
    color: amber[300],
    width: '40px',
    height: '40px',
    marginRight: theme.spacing.unit,
  },
  missionsIcon2: {
    color: amber[300],
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  img: {
    flexShrink: 0,
    width: '150px',
    height: '150px',
    marginLeft: theme.spacing.unit*4,
  },
  imgMobile: {
    width: '150px',
    height: '150px',
  },
  imgCompleted: {
    flexShrink: 0,
    width: '300px',
    height: '300px',
  },
  tryAnother: {
    marginBottom: theme.spacing.unit*2,
  },
  checkProgress: {
    marginTop: theme.spacing.unit*4,
  },
})

const renderHeader = (props, title, illustration) => {
  const { classes, isMobile } = props

  if (isMobile) {
    return(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>
          {title}
        </Typography>
        <img className={classes.imgMobile} alt="" src={illustrations.get(illustration)} />
      </div>
    )

  } else {
    return (
      <div className={classes.row}>
        <Typography style={{ width: '200px' }}>
          {title}
        </Typography>
        <img className={classes.img} alt="" src={illustrations.get(illustration)} />
      </div>
    )
  }
}

const renderCompleted = (props) => {
  const {
    classes,
    isMobile,
    missions,
    plan,
    show,
    closeMissions,
    displayAcceptMission,
    showMissions
  } = props

  return(
    <div>
      {renderHeader(props, "You've completed all the missions Couraggo has to offer!", "winners_amber")}
      <ButtonBase
        focusRipple
        onClick={() => showMissions()}
        className={classes.checkProgress}
      >
        <Typography>
          Click here
        </Typography>
        <img
          style={{ marginLeft: '8px', marginRight: '8px', width: '35px', height: '35px' }}
          src={MissionIcon}
          alt="Missions"
        />
        <Typography>
          to redo a completed mission.
        </Typography>
      </ButtonBase>
    </div>
  )

}
const renderContent = (props) => {
  const {
    classes,
    isMobile,
    missions,
    plan,
    show,
    closeMissions,
    displayAcceptMission,
    showMissions
  } = props


  return(
    <div>
      {renderHeader(props, "You've learned a little bit more of Couraggo and set some things up!", "finish_line_amber")}
      <Typography className={classes.tryAnother}>
        Care to try out another mission?
      </Typography>
      <div className={classes.column}>
        {missions.filter(mission => (mission.type !== "intro" && !mission.isCompleted && mission.plans.includes(plan))).slice(0, 3).map((mission, i) => {
          return(
            <ButtonBase
              key={i}
              style={{ marginLeft: '5px', marginBottom: '10px' }}
              focusRipple
              onClick={ () => displayAcceptMission(mission.id)}
            >
              <div className={classes.buttonRow}>
                <img
                  style={{ marginRight: '10px', width: '25px', height: '25px' }}
                  src={MissionIcon}
                  alt="Missions"
                />
              <Typography variant="subheading">{isMobile ? mission.titleShort : mission.title}</Typography>
              </div>
            </ButtonBase>
          );
        })}
      </div>
      {!isMobile &&
        <ButtonBase
          focusRipple
          onClick={() => showMissions()}
          className={classes.checkProgress}
        >
          <Typography>
            Or, click here
          </Typography>
          <img
            style={{ marginLeft: '8px', marginRight: '8px', width: '35px', height: '35px' }}
            src={MissionIcon}
            alt="Missions"
          />
          <Typography>
            to see all the missions and check your progress.
          </Typography>
        </ButtonBase>
      }
    </div>
  )
}

const MissionSuccessDialog = (props) => {
  const {
    classes,
    isMobile,
    missions,
    subscription,
    plan,
    show,
    closeMissions,
    displayAcceptMission,
    showMissions,
    startEditAdmin
  } = props

  const missionsCompleted = !missions.filter(mission => !mission.isCompleted & mission.plans.includes(plan)).length;

  return (
    <Dialog
      open={Boolean(show)}
      fullScreen={isMobile}
      maxWidth="sm"
    >
      <DialogTitle>
        <div className={classes.titleRow}>
          <img
            style={{ marginRight: '15px', width: '35px', height: '35px' }}
            src={MissionIcon}
            alt="Missions"
          />
          <Typography variant="title">{missionsCompleted ? "Congratulations!!" : "Nice work!!"}</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {missionsCompleted ? renderCompleted(props) : renderContent(props)}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
            if (!subscription.isNotTmpAccount) {
              startEditAdmin({ trialSignUp: true })
            }
            closeMissions()
        }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  isMobile: state.adminState.isMobile,
  missions: state.help.missions,
  plan: state.subscription.plan,
  subscription: state.subscription,
})

const mapDispatchToProps = {
  closeMissions,
  displayAcceptMission,
  showMissions,
  startEditAdmin
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MissionSuccessDialog)
