//import { auth, db } from 'firebase';
import { auth, firebase, db } from '../../../firebase';
import { validateWebsite } from 'store/actions/website';

export * from './generateAccount'

const setAccountUser = (user) => ({
  type: 'SET_ACCOUNT_USER',
  user,
});

const setAccountOrganizations = (organizations) => ({
  type: 'SET_ACCOUNT_ORGANIZATION',
  organizations
});


export const startEditAccount = (updates) => {
  return (dispatch) => {
    console.log("updates:", updates);
  }
};

export const startSetAccountUser = () => {
  return (dispatch) => {
    const uid = firebase.auth.currentUser.uid;

    db.getUser(uid).on('value', snapshot => {
      const { organizations, ...user } = snapshot.val();
      dispatch(setAccountUser({ id: uid, ...user }));
    });
  }
}


export const startSetAccountOrganizations = () => {
  return (dispatch) => {
    const uid = firebase.auth.currentUser.uid;
    db.getUser(uid).once('value')
      .then(snapshot => {
        const user = snapshot.val();
        const oids = Object.entries(user.organizations).map(org => org[0]);
        const oid = oids[0];

        //For now just handle case where a user is related to a single organization.
        db.getOrganization(oid).on('value', snapshot => {
          const organizations = [];
          organizations.push({ id: oid, ...snapshot.val() });
          dispatch(setAccountOrganizations(organizations));
          dispatch(validateWebsite());
        });
      });
  }
};

export const startEditUserProfile = (uid, updates) => {
  return (dispatch) => {
    return db.updateUserProfile(uid, updates);
  }
};

export const startEditOrgAbout = (oid, updates) => {
  return (dispatch) => {
    return db.updateOrganizationAbout(oid, updates);
  }
};

export const startChangeEmail = (newEmail, uid) => {
  console.log("starting change email:", newEmail, uid)
  return (dispatch) => {
    return auth.updateEmail(newEmail)
      .then(() => db.updateUserProfile(uid, { email: newEmail }));
  }
}

export const startSignInWithPassword = (email, password) => {
  return (dispatch) => {
    return auth.doSignInWithEmailAndPassword(email, password);
  }
}
