import { storage, oid } from './firebase'

export const getCompanyLogo = () =>
  storage.ref().child(`appData/${oid}/logo/logo.jpg`)

export const getDownloadURL = (id) =>
  storage.ref().child(`appData/${oid}/files/${id}`)
  .getDownloadURL().then(function(url) {
    return url
  })

export const getReceiptDownloadURL = (id) =>
  storage.ref().child(`appData/${oid}/receipts/${id}`)
  .getDownloadURL().then(function(url) {
    return url
  })

export const getPortfolioDownloadURL = (id) =>
  storage.ref().child(`appData/${oid}/portfolio/${id}`)
  .getDownloadURL().then(function(url) {
    return url
  })

export const getTeamDownloadURL = (id) =>
  storage.ref().child(`appData/${oid}/team/${id}`)
  .getDownloadURL().then(function(url) {
    return url
  })


export const deleteWebsiteDocument = (id) =>
  storage.ref().child(`appData/${oid}/websiteDocuments/${id}`).delete()

export const uploadWebsiteDocument = (id, file) =>
  storage.ref().child(`appData/${oid}/websiteDocuments/${id}`).put(file)

export const getWebsiteDocumentURL = (id) =>
  storage.ref().child(`appData/${oid}/websiteDocuments/${id}`)
  .getDownloadURL().then(function(url) {
    return url
  })


export const deleteHeroImage = (id) =>
  storage.ref().child(`appData/${oid}/heroImages/${id}`).delete()

export const uploadHeroImage = (id, file) =>
  storage.ref().child(`appData/${oid}/heroImages/${id}`).put(file)

export const getHeroImageURL = (id) =>
  storage.ref().child(`appData/${oid}/heroImages/${id}`)
  .getDownloadURL().then(function(url) {
    return url
  })

export const getContactImageURL = (filename) =>
  storage.ref().child(`website/contact/${filename}`)
  .getDownloadURL().then(function(url) {
    return url
  })

//BEGIN LOGOS
export const getLogoDownloadURL = (fileName) =>
  storage.ref().child(`appData/${oid}/logos/${fileName}`)
  .getDownloadURL().then(function(url) {
    return url
  })

export const uploadLogoFile = (fileName, file) =>
  storage.ref().child(`appData/${oid}/logos/${fileName}`).put(file)

export const deleteLogoFile = (fileName) =>
  storage.ref().child(`appData/${oid}/logos/${fileName}`).delete()
//END LOGOS

export const uploadInvoice = (id, invoice) =>
  storage.ref().child(`appData/${oid}/invoices/${id}`).put(invoice)

export const uploadBid = (id, bid) =>
  storage.ref().child(`appData/${oid}/bids/${id}`).put(bid)


export const uploadTeamFile = (id, file) =>
  storage.ref().child(`appData/${oid}/team/${id}`).put(file)


export const uploadPortfolioFile = (id, file) =>
  storage.ref().child(`appData/${oid}/portfolio/${id}`).put(file)

export const deletePortfolioFile = (id) =>
  storage.ref().child(`appData/${oid}/portfolio/${id}`).delete()

export const uploadFile = (id, file) =>
  storage.ref().child(`appData/${oid}/files/${id}`).put(file)

export const deleteFile = (id) =>
  storage.ref().child(`appData/${oid}/files/${id}`).delete()

export const deleteTeamFile = (id) =>
  storage.ref().child(`appData/${oid}/team/${id}`).delete()
