const defaultState = [];


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_NOTES':
      return action.notes;
    default:
      return state;
  }
};
