import { db } from '../../firebase';


export const startEditProjectLabor = (projectTaskId, laborId, laborData) => {
  return (dispatch) => (
    db.updateProjectLabor(projectTaskId, laborId, laborData)
  );
};

export const startEditProjectTask = (projectTaskId, updates) => {
  return (dispatch) => db.updateProjectTask(projectTaskId, updates);
}

export const startAddProjectTask = (projectTask) => {
  return (dispatch) => db.createProjectTask(projectTask);
}

export const setProjectTasks = (projectTasks) => ({
  type: 'SET_PROJECT_TASKS',
  projectTasks,
});

export const startSetProjectTasks = () => {
  return (dispatch) => {
    return db.getProjectTasks().on('value', (snapshot) => {
      const projectTasks = [];

      snapshot.forEach((childSnapshot) => {
        const labor = childSnapshot.val().labor !== undefined
          ? Object.entries(childSnapshot.val().labor).map(item => {
              return { id: item[0] , ...item[1]}
            })
          : []

        const materials = childSnapshot.val().materials !== undefined
          ? Object.entries(childSnapshot.val().materials).map(item => {
              return { id: item[0] , ...item[1]}
            })
          : []

        const projectTask = {id: childSnapshot.key, ...childSnapshot.val()};

        if (projectTask.type === "quote") {
          projectTask.labor = labor;
          projectTask.materials = materials;
        }


        //console.log("projectTask:", projectTask);
        projectTasks.push(projectTask);
      });

      dispatch(setProjectTasks(projectTasks));
    });
  }
};
