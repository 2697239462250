//Auth Routes
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const PASSWORD_FORGET = '/pw-forget'

//App routes
export const DEFAULT = '/'
export const HOME = '/home'
export const ACCOUNT = '/admin/account'
export const BIDS = '/admin/bids'
export const INVOICES = '/admin/invoices'
export const START = '/admin/start'
export const INVOICING = '/admin/invoicing'
export const WEBSITE = '/admin/website'
export const WEBSITE_VIEW = '/websiteview'
export const RECEIPTS = '/admin/receipts'
export const TIMECARDS = '/admin/timecards'
export const JOBS = '/admin/jobs'
export const CLIENTS = '/admin/clients'
export const SETUP = '/admin/setup'
export const TERMS = '/terms'
export const PRIVACY = '/privacy'
export const NOTES = '/admin/notes'
export const REMINDERS = '/admin/reminders'
export const TRIAL = '/trial'
export const REPORTS = '/admin/reports'
//Admin routes
export const ADMIN = '/admin'
