import CryptoJS from 'crypto-js'

import { auth, firebase, db } from '../../../firebase'
import { getHostingURL } from 'utils'
import setupData from 'setup'
import { analytics } from 'analytics'

export const generateAccountTrialMode = (email, password) => {
  const params = {
    email,
    password,
    // companyname: "test company name",
    // username: "test user name",
    isNotTmpAccount: false
  }

  return generateAccount(params, true)
}

export const generateAccount = (params, isTrialMode) => {
  const {
    email,
    password,
    companyname = "",
    username = "",
    campaign = "undefined",
    plan = "basic",
    isNotTmpAccount = true,
  } = params

  // if(process.env.REACT_APP_FIREBASE === "production"){
  //   console.log("create_account_event in generateAccount:", window.FB)
  //   window.FB.AppEvents.logEvent('create_account_event', 1)
  // }

  let uid;
  let oid;
  let uniqueDomain = null;
  //TODO: extract out business logic to keep component lightweight.
  //move to redux
  return auth.doCreateUserWithEmailAndPassword(email, password)
    .then(authUser => {
      //uid = authUser.uid;
      uid = authUser.user.uid;
      //console.log("AUTH USER ID SIGN UP:", authUser.user.uid);
      return db.createOrganization({
        about: { name: companyname},
        campaign: campaign
      });
    })
    .then(result => {
      oid = result.key;
      const user = {
        profile: {
          username,
          email
        },
        organizations: {
          [oid]: {
            role: "admin"
          }
        }
      };
      //console.log("create user:", user);
      return db.createUser(uid, user);

    })
    .then(() => {
      const subscription = {
        plan: plan ? plan : 'basic',
        isNotTmpAccount: Boolean(isNotTmpAccount),
        isSubscribed: false,
        trialDuration: 14,
        trialStart: (new Date()).getTime(),
      }
      try {
        if (isTrialMode) {
          const cipher = CryptoJS.AES.encrypt(password, oid)
          subscription.tmpEmail = email
          subscription.cipher = cipher.toString()
        }
      } catch (error) {
        console.log("exception in cipher", error)
        analytics.exception(`generateAccount::exception_in_cipher [message:${error.message}]`)
      }
      //console.log("start trial")
      return db.createSubscription(oid, subscription);
    })
    .then(() => db.getOrgDomains())
    .then((snapshot) => {

      const baseDomain = companyname
        ? companyname.replace(/\s/g, '').toLowerCase()
        : oid

      const orgDomains = [];
      snapshot.forEach(childSnapshot => {
        const orgDomain = childSnapshot.val();
        //console.log("orgDomain:", orgDomain);
        orgDomains.push(orgDomain.subDomain);
      });

      //console.log("orgDomains array:", orgDomains);
      //stupid hack to make the domain name unique
      let i = 0;
      //console.log("TEST11232:", baseDomain + (i ? i : ''));
      while (orgDomains.includes(baseDomain + (i ? i : ''))) {
        i++;
      }
      uniqueDomain = baseDomain + (i ? i : '');
      //console.log("unique domain:", uniqueDomain);
      //console.log("Create org domain:", uniqueDomain)
      return db.createOrgDomain({
        oid,
        subDomain: uniqueDomain,
        subDomainStatus: "pending"
      });
    })
    .then(() => {
      //console.log("setting website root")
      return db.setWebsiteRoot(oid, {
        companyName: companyname,
        contactName: username,
        subDomain: uniqueDomain,
        email: email
      });
    })
    .then(() => {
      //console.log("setting tax rules")
      return db.setTaxRules(oid, setupData.appData.taxRules);
    })
    .then(() => {
      const promises = [];
      const taxCategories = setupData.appData.taxCategories;
      taxCategories.forEach(taxCategory => {
        promises.push(db.createTaxCategoryWithOID(oid, taxCategory));
      });
      //console.log("creating tax categories")
      return Promise.all(promises);
    })
    .then(() => {
      //console.log("creating work types")
      return db.createWorkType({
        name: "Default",
        rate: "50"
      });
    })
    .then(() => {
      //console.log("creating crew")
      return db.createCrew({
        crewFirstName: "Default"
      });
    })
    .then(() => {
      return db.getWebsiteImages().once('value');
    })
    .then(snapshot => {
      const websiteImages = snapshot.val();
      //console.log("snapshot of images:", websiteImages);
      //console.log("website images:", websiteImages)
      if (websiteImages) {
        return websiteImages;
      } else {
        const contact = [
          { filename: "contact1.jpg" },
          { filename: "contact2.jpg" },
          { filename: "contact3.jpg" },
        ];
        const hero = [
          { filename: "hero1.jpg" },
          { filename: "hero2.jpg" },
          { filename: "hero3.jpg" },
        ];

        return db.createWebsiteImages({
          contact: contact,
          hero: hero
        });
      }
    })
    .then(() => {
      return (
        fetch(`${getHostingURL()}/email/sendWelcome`, {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            oid,
            uid,
            token: "LaST18dswfD37VCwVjSsgiselDIDqwwq18iXTvRv"
          })
        })
      )
    })
    .then(() => ({ uid, oid }))
}
