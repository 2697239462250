import { db, oid } from '../firebase';
import { replaceBidTasks } from './bidTemplates';
import moment from 'moment';
import { analytics } from 'analytics'

export const deleteTaskLaborItem = (taskId, itemId) =>
  db.ref(`appData/${oid}/bidTasks/${taskId}/labor/${itemId}`).remove();

export const deleteTaskMaterialsItem = (taskId, itemId) =>
  db.ref(`appData/${oid}/bidTasks/${taskId}/materials/${itemId}`).remove();

export const getBids = () =>
  db.ref(`appData/${oid}/bids`);

export const getBidTasks = () =>
  db.ref(`appData/${oid}/bidTasks`);

export const getBidTasksOnce = () =>
  db.ref(`appData/${oid}/bidTasks`).once('value');
//TODO: this should be renamed to setBidState to reflect the db action
export const updateBidState = (bidId, state) =>
  db.ref(`appData/${oid}/bids/${bidId}/state`).set(state);

//TODO: as above
export const updateBidState2 = (bidId, state) =>
  db.ref(`appData/${oid}/bids/${bidId}/state`).update(state);


export const getBid = (bidId) =>
  db.ref(`appData/${oid}/bids/${bidId}`).once('value');

export const deleteBid = (bidId) =>
  db.ref(`appData/${oid}/bids/${bidId}`).remove();

export const updateBidTemplateSales = (bidId, updates) =>
  db.ref(`appData/${oid}/bids/${bidId}/template/sales`).update(updates);

export const updateBidTemplateCredibility = (bidId, updates) =>
  db.ref(`appData/${oid}/bids/${bidId}/template/credibility`).update(updates);

export const getBidTasksById = (bidId) =>
  db.ref(`appData/${oid}/bidTasks`).orderByChild("bidId").equalTo(bidId).once("value");

export const deleteBidTask = (bidTaskId) =>
  db.ref(`appData/${oid}/bidTasks/${bidTaskId}`).remove();

export const createBidTask = (bidTaskData) =>
  db.ref(`appData/${oid}/bidTasks`).push(bidTaskData)

export const createBidLabor = (bidTaskId, laborData) =>
  db.ref(`appData/${oid}/bidTasks/${bidTaskId}/labor`).push(laborData)

export const updateBidLabor = (bidTaskId, laborId, updates) =>
  db.ref(`appData/${oid}/bidTasks/${bidTaskId}/labor/${laborId}`).update(updates)

export const createBidMaterials = (bidTaskId, materialsData) =>
  db.ref(`appData/${oid}/bidTasks/${bidTaskId}/materials`).push(materialsData)

export const updateBidMaterials= (bidTaskId, materialsId, updates) =>
  db.ref(`appData/${oid}/bidTasks/${bidTaskId}/materials/${materialsId}`).update(updates)

export const updateBid = (bidId, bidData) =>
  db.ref(`appData/${oid}/bids/${bidId}`).update(bidData)

export const updateBidTask = (bidTaskId, bidTaskData) =>
  db.ref(`appData/${oid}/bidTasks/${bidTaskId}`).update(bidTaskData)

export const copyBidTasks = (destinationBidId, sourceBidId) => {
  getBidTasksById(sourceBidId)
    .then((snapshot) => {
      const bidTasks = snapshot.val()
        ? Object.entries(snapshot.val()).map(e => ({...e[1], bidId: destinationBidId}))
        : [];

      return replaceBidTasks(destinationBidId, bidTasks);
    })
};

export const copyBid = (destinationBidId, sourceBidId) => {
  return getBid(sourceBidId)
    .then((snapshot) => {
      return snapshot.val();
    })
    .then((bid) => {
      getBid(destinationBidId)
      .then((snapshot) => {
        const destinationBid = snapshot.val();
        bid.bidNumber = destinationBid.bidNumber;
        bid.createdDate = (new Date()).getTime();
        bid.date = (new Date()).getTime();
        bid.state = { draftDate: bid.date };
        //status = "draft";

        return updateBid(destinationBidId, bid)
      })
    })
    .then(() => {
      return copyBidTasks(destinationBidId, sourceBidId);
    })
    .then(() => getBid(destinationBidId))
    .then((snapshot) => snapshot.val());
};

export const createBid = (bidData) => {
  const currentMoment = moment();

  const template = {
    showHeader: true,
    showSales: true,
    showTasks: true,
    showSummary: true,
    showCredibility: true,
    sales: {
      showBasic: false,
      showSituation: false,
      showIntroduction: false,
    },
    credibility: {
      showBasic: false,
      showLogos: false,
      showNumbers: false,
    },
  };
  const status = "draft";

  const state = {
    draftDate: currentMoment.valueOf(),
    // sentDate: undefined,
    // wonDate: undefined,
    // lostDate: undefined,
    // cancelledDate: undefined,
    // archivedDate: undefined,
  };

  let {
    bidNumber = '',
    date = currentMoment.valueOf(),
    createdDate = currentMoment.valueOf(),
    dueDate = currentMoment.add(1, 'months').subtract(1, 'days').valueOf(),
    clientId
  } = bidData;

  const bid = {
    bidNumber,
    template,
    status,
    date,
    dueDate,
    createdDate,
    state,
  };

  if (clientId) {
    bid.clientId = clientId;
  }

  return(
    db.ref(`appData/${oid}/nextBidNumber`).once('value')
      .then((snapshot) => typeof snapshot.val() === 'number' ? snapshot.val() + 1 : 1)
      .then((nextBidNumber) => (
          db.ref(`appData/${oid}`).update({nextBidNumber: nextBidNumber})
            .then(() => nextBidNumber)
      ))
      .then((nextBidNumber) => bid.bidNumber = nextBidNumber)
      .then(() => db.ref(`appData/${oid}/taxRules`).once('value'))
      .then((snapshot) => {
        const taxRule = snapshot.val().default;

        bid.tax = {
          gstRate: taxRule.gstRate,
          pstRate: taxRule.pstRate,
          gstApplicable: taxRule.gstApplicable,
          pstApplicable: taxRule.pstApplicable,
        }
      })
      .then(() => db.ref(`organizations/${oid}/about`).once('value'))
      .then(snapshot => {
        console.log("SNAPSHOT:", );
        const { companyNumber, taxNumber } = snapshot.val();

        if (companyNumber) {
          bid.companyNumber = companyNumber;
        }

        if (taxNumber) {
          bid.taxNumber = taxNumber;
        }
      })
      .then(() => {
        return (
          db.ref(`appData/${oid}/bids`).push(bid)
            .then((ref) => Object.assign(bid, {id: ref.key}, bid))
        );
      })
      .catch((error) => {
        analytics.exception(`Bids::createBid [message:${error.message}]`)
        console.log('createBid ERROR:', error);
      })
  );
}
