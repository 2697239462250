import { db } from '../../firebase'


export const startRemoveTaxType = (id) => dispatch =>
  db.deleteTaxType(id)

export const startEditTaxType = (id, updates) => dispatch => {
  return db.updateTaxType(id, updates)
}


export const startAddTaxType = (taxType) => dispatch =>
  db.createTaxType(taxType)
    .then(result => ({ id: result.key, ...taxType }))


export const setTaxTypes = (taxTypes) => ({
  type: 'SET_TAX_TYPES',
  taxTypes
})

export const startSetTaxTypes= () => dispatch =>
  db.getTaxTypes().on('value', snapshot => {
    const taxTypes = []
    snapshot.forEach(cs => {
      taxTypes.push({ id: cs.key, ...cs.val() })
    })

    return dispatch(setTaxTypes(taxTypes))
  })
