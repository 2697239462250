import { db } from '../../../firebase'
import * as bids from './bids'
import * as invoices from './invoices'
import * as jobs from './jobs'
import * as receipts from './receipts'
import * as setup from './setup'
import * as timecards from './timecards'
import * as website from './website'
import $ from 'jquery'
import { analytics } from 'analytics'

export const setShowTour = (showTour) => ({
  type: "SET_SHOW_TOUR",
  showTour
})

export const showHelp = () => ({
  type: "SHOW_HELP"
})

export const closeHelp = () => ({
  type: "CLOSE_HELP"
})

export const showMissions = () => ({
  type: "SHOW_MISSIONS"
})

export const closeMissions = () => ({
  type: "CLOSE_MISSIONS"
})

export const nextMissionStep = () => ({
  type: "NEXT_STEP_MISSION"
})


export const closeAcceptMission = () => ({
  type: "CLOSE_ACCEPT_MISSION"
})

export const displayAcceptMission = (missionId) => ({
  type: "DISPLAY_ACCEPT_MISSION",
  missionId
})

export const saveCompletedMission = (missionId) => {
  console.log("saving:", missionId)
  db.updateCompletedMissions(missionId)
}

export const startSetShowTour = (showTour) => {
  return (dispatch) => dispatch(setShowTour(showTour))
}

export const startMission = (id) => {
  return (dispatch) => {
    const addInvoice = $( "#test-id1" )
    window.scrollTo(0, 0)
    analytics.missionStart(`m_${id}_start`, id)

    //TODO: switch on type
    switch (id) {
      case "invoice-1":
        dispatch(invoices.startInvoiceMission(id))
        break
      case "website-1":
        dispatch(website.startWebsiteMission(id))
        break
      case "setup-1":
        dispatch(setup.startSetupMission(id))
        break
      case "bid-1":
        dispatch(bids.startBidMission(id))
        break
      case "timecard-1":
        dispatch(timecards.startTimecardMission(id))
        break
      case "receipt-1":
        dispatch(receipts.startReceiptMission(id))
        break
      case "job-1":
        dispatch(jobs.startJobMission(id))
        break
      default:

    }

  }
}

export const setMissions = (missions) => ({
  type: "SET_MISSIONS",
  missions
})

export const startSetMissions = () => {
  return (dispatch) => {
    return db.getMissions().on('value', snapshot => {
      const missions = [
        {
          id: "intro-1",
          type: "intro",
          isCompleted: false,
          title: '',
          titleShort: '',
          plans:['ws','basic','advanced'],
        },
        {
          id: "trial-1",
          type: "trial",
          isCompleted: false,
          title: '',
          titleShort: '',
          plans:['ws','basic','advanced'],
        },
        {
          id: "invoice-1",
          type: "invoice",
          isCompleted: false,
          title: "Quickie invoice to learn the basics.",
          titleShort: "Create quickie invoice",
          plans:['basic','advanced'],
        },
        {
          id: "website-1",
          type: "website",
          isCompleted: false,
          title: "Start building your cool, professional website.",
          titleShort: "Build your website",
          plans:['ws','basic','advanced'],
        },
        {
          id: "setup-1",
          type: "setup",
          isCompleted: false,
          title: "Adding data, not fun but gotta get done.",
          titleShort: "Setup your data",
          plans:['basic','advanced'],
        },
        {
          id: "bid-1",
          type: "bid",
          isCompleted: false,
          title: "Start creating professional quotes and estimates.",
          titleShort: "Create simple estimate",
          plans:['basic','advanced'],
        },
        {
          id: "timecard-1",
          type: "timecard",
          isCompleted: false,
          title: "Keep on top of your time.",
          titleShort: "Add a timecard",
          plans:['basic','advanced'],
        },
        {
          id: "receipt-1",
          type: "receipt",
          isCompleted: false,
          title: "Got receipt piles everywhere? Enter them here and free your space!",
          titleShort: "Add a receipt",
          plans:['basic','advanced'],
        },
        {
          id: "job-1",
          type: "job",
          isCompleted: false,
          title: "Learn about Jobs and how they manage your complex work.",
          titleShort: "Create simple job",
          plans:['advanced'],
        },
      ]

      snapshot.forEach(childSnapshot => {
        missions.forEach(mission => {
          if (mission.id === childSnapshot.key) {
            mission.isCompleted = true;
          }
        })
      })

      dispatch(setMissions(missions))
      dispatch({ type: 'IS_LOADING', value: false })
    })
  }
}
