import invoiceTooltips from './invoices';
import timecardTooltips from './timecards';
import dialogTooltips from './dialogs';
import websiteTooltips from './website';
import setupTooltips from './setup';
import receiptTooltips from './receipts';
import bidTooltips from './bids';
import jobTooltips from './jobs';
import tourTooltips from './tour';

class Tooltips {
  constructor(tooltips) {
    this._tooltips = tooltips;
  }

  get(type) {
    const tooltip = this._tooltips.get(type);
    return tooltip ? tooltip : {};
  }
}

const tooltips = new Map();

//TODO: replace with function that takes an import as a parameter
invoiceTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
timecardTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
dialogTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
websiteTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
setupTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
receiptTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
bidTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
jobTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));
tourTooltips.forEach(tooltip => tooltips.set(tooltip.id, tooltip));


export default new Tooltips(tooltips);
