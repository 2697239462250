import moment from 'moment';

import { db } from '../../firebase';

export const startEditTimecard= (id, updates) => {
    return (dispatch) => db.updateTimecard(id, updates)
};

export const startAddTimecard= (timecardData) => {
  return (dispatch) => (
    db.createTimecard(timecardData)
  );
};

export const startRemoveTimecardField = (id, field) => {
  return (dispatch) => db.deleteTimecardField(id, field);
}

export const startRemoveTimecard = (id) => {
  return (dispatch) => db.deleteTimecard(id);
};

export const setTimecards = (timecards) => ({
  type: 'SET_TIMECARDS',
  timecards
});

export const startSetTimecards = () => {
  return (dispatch) => {
    return db.getTimecards().on('value', (snapshot) => {
        const timecards = [];
        snapshot.forEach((childSnapshot) => {
          const timecard = {id: childSnapshot.key, ...childSnapshot.val()};
          Object.defineProperty(timecard, "minutesWorked", {
            get() {
              return moment(this.endDate).diff(moment(this.startDate), "minutes");
            },
            enumerable: true,
          });

          timecards.push(timecard)
        });

        dispatch(setTimecards(timecards));
    });
  };
};
