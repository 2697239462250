import { analytics } from 'analytics'

export const selectAccount = (state) => {
  const { organizations, user } = state.accountState
  const { subscription } = state
  let account = {}
  let organization = {}

  try {
    account.organization = organizations[0]
    account.subscription = subscription
    account.user = user
  } catch (error) {
    console.log('selectors::selectAccount ERROR:', error)
    analytics.exception(`selectors::selectAccount [message:${error.message}]`)
  }

  return account
}
