import { db, oid } from '../firebase';

export const getWorkTypes = () =>
  db.ref(`appData/${oid}/workTypes`);

export const createWorkType = (workType) =>
  db.ref(`appData/${oid}/workTypes`).push(workType)

export const updateWorkType = (id, updates) =>
  db.ref(`appData/${oid}/workTypes/${id}`).update(updates);

export const deleteWorkType = (id) =>
  db.ref(`appData/${oid}/workTypes/${id}`).remove();
