import businessman from "./businessman.svg";
import time_management from "./time_management.svg";
import time_management_amber from "./time_management_amber.svg";
import successful_purchase from "./successful_purchase.svg";
import successful_purchase_amber from "./successful_purchase_amber.svg";
import business_deal from "./business_deal.svg";
import customer_survey_amber from "./customer_survey_amber.svg";
import printing_invoices_amber from "./printing_invoices_amber.svg";
import credit_card_payment_amber from "./credit_card_payment_amber.svg";
import team_amber from "./team_amber.svg";
import experts_amber from "./experts_amber.svg";
import QA_engineers_amber from "./QA_engineers_amber.svg"
import passing_by_amber from "./passing_by_amber.svg"
import feeling_blue from "./feeling_blue.svg";
import hiring_amber from "./undraw_hiring_amber.svg";
import portfolio_amber from "./undraw_portfolio_amber.svg";
import superhero_amber from "./undraw_superhero_amber.svg";
import maker_launch_amber from "./maker_launch_amber.svg";
import mission_impossible_amber from "./mission_impossible_amber.svg"
import mission_impossible_landscape_amber from './mission_impossible_landscape_amber.png'
import moving_forward_amber from "./moving_forward_amber.svg";
import questions_amber from "./questions_amber.svg";
import finish_line_amber from "./finish_line_amber.svg";
import empty_amber from "./empty_amber.svg";
import domain_names_amber from './domain_names_amber.svg';
import winners_amber from './winners_amber.svg';
import online_wishes from './online_wishes.svg';
import joyride_amber from './joyride_amber.svg';
import checking_boxes_amber from './checking_boxes_amber.svg';
import searching_amber from './searching_amber.svg';
import not_found_amber from './not_found_amber.svg';
import treasure_amber from './treasure_amber.svg';
import server_down_amber from './server_down_amber.svg';
import helpful_amber from './helpful_amber.svg';
import logo_amber from './logo_amber.svg';
import denied_amber from './denied_amber.svg';
import stars_amber from './stars_amber.svg'
import click_here_amber from './click_here_amber.svg'
import image_upload_amber from './image_upload_amber.svg'
import fatherhood_amber from './fatherhood_amber.svg'
import choice_amber from './choice_amber.svg'
import forgot_password_amber from './forgot_password_amber.svg'
import powerful_amber from './powerful_amber.svg'
import startled_amber from './startled_amber.svg'
import transfer_money_amber from './transfer_money_amber.svg'


const illustrations = new Map();

illustrations.set("businessman", businessman);
illustrations.set("time_management", time_management);
illustrations.set("time_management_amber", time_management_amber);
illustrations.set("successful_purchase", successful_purchase);
illustrations.set("successful_purchase_amber", successful_purchase_amber);
illustrations.set("business_deal", business_deal);
illustrations.set("customer_survey_amber", customer_survey_amber);
illustrations.set("printing_invoices_amber", printing_invoices_amber);
illustrations.set("credit_card_payment_amber", credit_card_payment_amber);
illustrations.set("team_amber", team_amber);
illustrations.set("experts_amber", experts_amber);
illustrations.set("QA_engineers_amber", QA_engineers_amber);
illustrations.set("passing_by_amber", passing_by_amber);
illustrations.set("feeling_blue", feeling_blue);
illustrations.set("hiring_amber", hiring_amber);
illustrations.set("portfolio_amber", portfolio_amber);
illustrations.set("superhero_amber", superhero_amber);
illustrations.set("maker_launch_amber", maker_launch_amber);
illustrations.set("mission_impossible_amber", mission_impossible_amber)
illustrations.set("mission_impossible_landscape_amber", mission_impossible_landscape_amber)
illustrations.set("moving_forward_amber", moving_forward_amber);
illustrations.set("questions_amber", questions_amber);
illustrations.set("finish_line_amber", finish_line_amber);
illustrations.set("empty_amber", empty_amber);
illustrations.set("domain_names_amber", domain_names_amber);
illustrations.set("winners_amber", winners_amber);
illustrations.set("online_wishes", online_wishes);
illustrations.set("joyride_amber", joyride_amber);
illustrations.set("checking_boxes_amber", checking_boxes_amber);
illustrations.set("searching_amber", searching_amber);
illustrations.set("not_found_amber", not_found_amber);
illustrations.set("treasure_amber", treasure_amber);
illustrations.set("server_down_amber", server_down_amber);
illustrations.set("helpful_amber", helpful_amber);
illustrations.set("logo_amber", logo_amber);
illustrations.set("denied_amber", denied_amber);
illustrations.set("stars_amber", stars_amber)
illustrations.set("click_here_amber", click_here_amber)
illustrations.set("image_upload_amber", image_upload_amber)
illustrations.set("fatherhood_amber", fatherhood_amber)
illustrations.set("choice_amber", choice_amber)
illustrations.set("forgot_password_amber", forgot_password_amber )
illustrations.set("powerful_amber", powerful_amber)
illustrations.set("startled_amber", startled_amber)
illustrations.set("transfer_money_amber", transfer_money_amber)

export default illustrations;
