import * as firebase from 'firebase'
import store from 'store'


const prodConfig = {
  apiKey: 'AIzaSyDEjhd-cns89X2oM1E-3Ncb4BXBM5Pxg_c',
  authDomain: "couraggo.firebaseapp.com",
  databaseURL: "https://couraggo.firebaseio.com/",
  storageBucket: "couraggo.appspot.com"
}

const testConfig = {
  apiKey: "AIzaSyB2NFMIhUyh8Fne15N1ho4XAsKnp3_CWBU",
  authDomain: "couraggo-test.firebaseapp.com",
  databaseURL: "https://couraggo-test.firebaseio.com",
  projectId: "couraggo-test",
  storageBucket: "couraggo-test.appspot.com",
  messagingSenderId: "392403812565"
}

const devConfig = {
  apiKey: 'AIzaSyDEjhd-cns89X2oM1E-3Ncb4BXBM5Pxg_c',
  authDomain: "couraggo.firebaseapp.com",
  databaseURL: "https://couraggo.firebaseio.com/",
  storageBucket: "couraggo.appspot.com"
}

const utConfig = {
  apiKey: 'AIzaSyBWy6rHU5Hmel9xdMib9MYx5q2wMYC22FQ',
  authDomain: "couraggo-ut.firebaseapp.com",
  databaseURL: "https://couraggo-ut.firebaseio.com/",
  storageBucket: "couraggo-ut.appspot.com"
}

//console.log("ENV:", process.env.REACT_APP_FIREBASE);
// const config = process.env.NODE_ENV === 'production'
//   ? prodConfig
//   : devConfig;
let config
switch (process.env.REACT_APP_FIREBASE) {
  case "production":
    config = prodConfig;
    break;
  case "test":
    config = testConfig;
    break;
  case "ut":
    config = utConfig;
    break;
  default:
    config = devConfig;
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let oid
const auth = firebase.auth()
const _auth = firebase.auth
const db = firebase.database()
const functions = firebase.functions()
const storage = firebase.storage()
const setOID = (id) => oid = id

export {
  db,
  auth,
  _auth,
  storage,
  functions,
  oid,
  setOID,
}
