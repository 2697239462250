import { db, oid } from '../firebase';

export const getCrew = () =>
  db.ref(`appData/${oid}/crew`);

export const createCrew = (crew) =>
  db.ref(`appData/${oid}/crew`).push(crew)

export const updateCrew = (id, updates) =>
  db.ref(`appData/${oid}/crew/${id}`).update(updates);

export const deleteCrew = (id) =>
  db.ref(`appData/${oid}/crew/${id}`).remove();
