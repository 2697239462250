import types from '../TooltipTypes';

export const websiteTypes = {
  SELECT_THEME: "website-select-theme",
  THEME_DIALOG: "website-theme-dialog",
  COMPANY_NAME: "website-company-name",
  PHONE: "website-phone",
  TAGLINE: "website-tagline",
  BASIC_INFO: "website-basic-info",
  PREVIEW: "website-preview",
  ACTIONS: "website-actions",
  HEADER_INFO_AREA: "website-header-info-area",
  INFO_PUBLISH: "website-publish-info",
  CLOSE: "website-close",
}

const websiteTooltips = [
  {
    id: websiteTypes.SELECT_THEME,
    title: "Theme",
    subtitle: "Choose any theme, but make sure to come back and try others, you'll be amazed!!.",
    type: "primary",
  },
  {
    id: websiteTypes.THEME_DIALOG,
    title: "Select Theme",
    subtitle:"Select a theme",
    type: "primary",
  },
  {
    id: websiteTypes.ACTIONS,
    title: "Action Buttons",
    subtitle: "These are always up here! No Save button? Couraggo saves with every, single change you make.",
    type: "secondary",
  },
  {
    id: websiteTypes.BASIC_INFO,
    title: "Header Info",
    subtitle: "Enter Company Name, Phone and optionally a tagline. Then hit NEXT from this on this helper card.",
    type: "primary",
    nextDispatch: websiteTypes.BASIC_INFO,
  },
  {
    id: websiteTypes.HEADER_INFO_AREA,
    title: "Header Info Area",
    subtitle: "Depending on theme chosen, the # of fields here will change… did we mention to come back and play with this??",
    type: "secondary",
  },
  {
    id: websiteTypes.PREVIEW,
    title: "Select Preview",
    subtitle: "Click on Preview to see your website.",
    type: "primary",
  },
  {
    id: websiteTypes.INFO_PUBLISH,
    title: "Publish Button",
    subtitle: "Later on we can explore publishing this to your own domain so the world can see your work.",
    type: "secondary",
  },
  {
    id: websiteTypes.CLOSE,
    title: "Close Preview",
    subtitle: "Review your site and close",
    type: "primary",
  }
];

export default websiteTooltips;
