const defaultState = []

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_TAX_TYPES':
      return action.taxTypes;
    default:
      return state;
  }
};
