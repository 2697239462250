const defaultState = [];


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_HERO_IMAGES':
      return action.heroImages;
    default:
      return state;
  }
};
