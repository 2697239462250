const defaultState = {
  light: "",
  dark: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'SET_LIGHT_LOGO':
      return ({ ...state, light: action.URL });
    case 'SET_DARK_LOGO':
      return ({ ...state, dark: action.URL });
    case 'DELETE_LIGHT_LOGO':
      return ({ ...state, light: "" });
    case 'DELETE_DARK_LOGO':
      return ({ ...state, dark: "" });
    default:
      return state;
  }
}
