import React from 'react';
import { connect } from 'react-redux';

import {
  Dialog
} from '../../core';
import { QuoteForm } from '../Forms';
import {
  startOnDoneQuote,
  startRemoveQuote,
} from '../../../store/actions';

const QuoteDialog = (props) => {
  const {
    show,
    handleClose,
    startOnDoneQuote,
    startRemoveQuote,
  } = props;

  return (
    <Dialog
      show={show}
      title="Quote"
      type={"edit"}
      onDone={ () => startOnDoneQuote() }
      onDelete={ () => startRemoveQuote() }
      onClose={() => {
        handleClose();
      }}
    >
      <QuoteForm />
    </Dialog>
  );
};

const mapDispatchToProps = {
  startOnDoneQuote,
  startRemoveQuote,
};

export default connect(null, mapDispatchToProps)(QuoteDialog);
