import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import { editWorkTypeForm } from '../../../store/actions';

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit*3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },

});

const WorkTypeForm = ({ classes, workType, editWorkTypeForm }) => {
  const { validation } = workType;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <TextField
          error={!!validation.validateField("name")}
          helperText={validation.validateField("name")}
          fullWidth
          label="Name"
          margin="normal"
          value={workType && workType.name}
          onChange={(event) => editWorkTypeForm({name: event.target.value})}
        />
        <TextField
          error={!!validation.validateField("rate")}
          helperText={validation.validateField("rate")}
          fullWidth
          label="Rate"
          margin="normal"
          type="number"
          value={workType && workType.rate}
          onChange={(event) => editWorkTypeForm({rate: event.target.value})}
        />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  workType: state.formData.workType,
});

const mapDispatchToProps = {
  editWorkTypeForm,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(WorkTypeForm);
