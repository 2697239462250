import Illustration from './Illustration';
import Unsupported from './Unsupported';
import illustrations from './img';

export default Illustration;

export {
  Unsupported,
  illustrations
}
