import types from '../TooltipTypes';

const jobTooltips = [
  {
    id: types.job.ADD,
    title: "Add Job",
    subtitle: "Click the yellow plus button or FAB to add a job",
    type: "primary",
  },
  {
    id: types.job.FILTERS,
    title: "Job Filters",
    subtitle: "These filters make large amounts of data manageable.",
    type: "secondary",
  },
  {
    id: types.job.BASIC_INFO,
    title: "Enter Info",
    subtitle: "Enter Client, Job Code, and Start Date",
    type: "primary",
    nextDispatch: types.job.BASIC_INFO
  },
  {
    id: types.job.TABS,
    title: "Job Tabs",
    subtitle: "When you come back, click on these Tabs to view all the data.",
    type: "secondary",
  },
  {
    id: types.job.SELECT_TASKS,
    title: "Select Tasks",
    subtitle: "Select the Tasks tab",
    type: "primary",
  },
];

export default jobTooltips;
