import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MissionPanel from './MissionPanel';

const styles = theme => ({
  root: {

  }
});


const MissionPanels = ({ classes, missions }) => {
  return(
    <div className={classes.root}>
      <MissionPanel
        title="Invoicing"
        missions={missions.filter(mission => mission.type === "invoice")}
      />
      <MissionPanel
        title="Website"
        missions={missions.filter(mission => mission.type === "website")}
      />
      <MissionPanel
        title="Bids"
        missions={missions.filter(mission => mission.type === "bids")}
      />
      <MissionPanel
        title="Time & Receipts"
        missions={missions.filter(mission => mission.type === "timereceipts")}
      />
    </div>

  );
}

MissionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const missions = state.help.missions;

  return ({
    missions,
  });
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(MissionPanels);
