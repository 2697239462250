import types from '../TooltipTypes';

const timecardTooltips = [
  {
    id: types.ADD_TIMECARD,
    title: "Add Time Card",
    subtitle: "Click the yellow plus button or FAB to add a timecard.",
    type: "primary",
  },
  {
    id: types.timecard.INFO_PANEL,
    title: "Info Panel",
    subtitle: "The info panel is always there to give meaning to the page or explain something.",
    type: "secondary",
  },
  {
    id: types.TIMECARD_CHANGE_TIME,
    title: "Change Time",
    subtitle: "Change both the start and end time to get a feel for how they work.",
    type: "primary",
  },
  {
    id: types.TIMECARD_NOTES,
    title: "Notes",
    subtitle: "Adding notes ensures import details aren't lost.",
    type: "secondary",
  },
  {
    id: types.TIMECARD_CREATE_CREW,
    title: "Add Crew",
    subtitle: "Add a new crew member - you can delete it in Setup later.",
    type: "primary",
  },
  {
    id: types.timecard.POPUP_FORM,
    title: "Popup Form",
    subtitle: "A form that pops over the app, is different as it only saves once ADD is clicked.",
    type: "secondary",
  },
  {
    id: types.TIMECARD_CREATE_CLIENT,
    title: "Add Client",
    subtitle: "Add a client - just first and last name; edit this in setup later.",
    type: "primary",
  },
  {
    id: types.timecard.CLIENT_POPUP_FORM,
    title: "Client Popup Form",
    subtitle: "Yep, another popover form; once again it'll only save once ADD is clicked.",
    type: "secondary",
  },
  {
    id: types.TIMECARD_SELECT_WORKTYPE,
    title: "Select Worktype",
    subtitle: "Just pick the default one, you can add more later in setup.",
    type: "primary",
  },
  {
    id: types.timecard.WORKTYPES,
    title: "Worktypes",
    subtitle: "Use worktypes to predefine rates for different types of work.",
    type: "secondary",
  },
  {
    id: types.TIMECARD_BILL_TO,
    title: "Bill to Client",
    subtitle: "Make this record invoice ready! Click Bill to Client/Job to proceed.",
    type: "primary",
  },
  {
    id: types.TIMECARD_CHARGES,
    title: "Charges",
    subtitle: "Look here now to see # of Hours times Rate to get the Total.",
    type: "secondary",
  },
  {
    id: types.TIMECARD_SAVE,
    title: "Add",
    subtitle: "Add this Time Card and close the form.",
    type: "primary",
  },
  {
    id: types.TIMECARD_REVIEW,
    title: "Review",
    subtitle: "Make sure you review all the details before leaving!",
    type: "secondary",
  },

  {
    id: types.TIMECARD_MENU_ITEM,
    title: "Confirm Time Used",
    subtitle: "Let's confirm the time has been invoiced.",
    type: "primary",
  },

  {
    id: types.TIMECARD_FILTER_SHOW_ARCHIVED,
    title: "Flip the Switch",
    subtitle: "To see the invoiced Time Cards, flip this switch.",
    type: "primary",
  },

];

export default timecardTooltips;
