import { db, storage } from '../../firebase';
import { analytics } from 'analytics'

export const setFiles = (files) => ({
  type: 'SET_FILES',
  files
});

export const startSetFiles = () => {
  return (dispatch) => db.getFiles().on('value', (snapshot) => {
    const files = [];
    const promises = [];

    //console.log("1 startSetFiles:", snapshot.val());

    snapshot.forEach((childSnapshot) => {
      //console.log("2 for each file");
      const file = {id: childSnapshot.key, ...childSnapshot.val()};
      promises.push(
        storage.getDownloadURL(file.id)
          .then(result => {
            file.downloadURL = result;
            files.push(file);
            //console.log("3. file pushed:", file);
          })
          .catch(error => {
            console.log("SET FILES ERROR:", error)
            analytics.exception(`files::startSetFiles [message:${error.message}]`)
          })
      );
    });

    return Promise.all(promises).then(() => dispatch(setFiles(files)));
  });
};

export const startAddStorageInvoice = (id, invoice) => {
  return (dispatch) => storage.uploadInvoice(id, invoice);
};

export const startAddStorageBid = (id, bid) => {
  return (dispatch) => storage.uploadBid(id, bid);
};

export const startAddFile = (file) => {
  return (dispatch) => {
    const { fileData, ...payload } = file;
    let fileId;
    return db.createFile(payload)
      .then((result) => {
        fileId = result.id;
        return storage.uploadFile(result.id, fileData);
      })
      .then(() => {
        return fileId;
      });
  }
}

export const startRemoveFile = (fileId) => {
  return (dispatch) =>
    db.deleteFile(fileId)
      .then(() => storage.deleteFile(fileId));
}
