import React from 'react';


//TODO: not really a factory, but placeholder for refactoring to
//use a factory in the future
const Factory = {
  create(key) {
    switch (key) {
      case 'invoice-1':
        return ({
          number: '002',
          type: 'simple',
          title: "Do a Quickie Invoice",
          subtitle: "Before you start invoicing - let's do a quick tour.",
          stepsInstruction: "In a few steps you'll learn:",
          steps: [
            "To look for a Big Yellow Button to add stuff.",
            "About adding clients on the fly!",
            "How our Information Panel helps you.",
            "How to send an invoice.",
          ],
          estimate: "1 min"
        });
      case 'website-1':
        return ({
          number: '001',
          type: 'simple',

          title: "Look pro with a modern website!!",
          subtitle: "Easily create your own website, it'll take about one minute.",
          stepsInstruction: "In six easy steps you'll learn:",
          steps: [
            "How the website templates work!!",
            "Preview your website before publishing.",
            "How easy you control your own website.",
          ],
          estimate: "1 min"
        });
      case 'setup-1':
        return ({
          number: '003',
          title: "Gotta have Clients",
          subtitle: "Here's a simple, dull but oh so important mission… it's an introduction to altering the data behind the scenes (Clients, Crew etc.)",
          stepsInstruction: "In a few steps you'll learn:",
          steps: [
            "Where to add, edit or delete background data like Clients, Crew etc."
          ],
          estimate: "1 min"
        });
      case 'bid-1':
        return ({
          number: '004',
          title: "Get more clients with professional quotes",
          subtitle: "Our bid builder is very flexible to help with nearly any Estimate/Quote aka Bid. Let's learn how simple this can be.",
          stepsInstruction: "In a few steps you'll learn:",
          steps: [
            "The many ways to customize a Bid",
            "The ability to add Tasks of any kind",
            "Add client on the fly to ease the process",
            "How easy you can get a professional looking print out of the Bid.",
          ],
          estimate: "3 mins"
        });
      case 'timecard-1':
        return ({
          number: '005',
          title: "Your time is Money!",
          subtitle: "Here you'll add a simle time record and learn how it can be setup for invoicing later on.",
          stepsInstruction: "In a few steps you'll learn:",
          steps: [
            "How to add Clients on the fly",
            "Hop popup forms are different than full screen forms",
            "What our Information Panel does, spoiler alert, it contains information.",
            "How to ensure a Time record will be invoiced.",
          ],
          estimate: "2 mins"
        });
      case 'receipt-1':
        return ({
          number: '006',
          title: "Get you sshtuff together",
          subtitle: "We'll show you how to add a receipt and you'll realize it'll be much easier on our mobile app!",
          stepsInstruction: "In a few steps you'll learn:",
          steps: [
            "Adding a Receipt… can Edit it same way as well.",
            "The different types of receipts",
            "How we ensure you don't mess up the data on a form",
          ],
          estimate: "1 min"
        });
      case 'job-1':
        return ({
          number: '007',
          title: "Manage a large project",
          subtitle: "Here you'll start a basic job to manage a large project - track multiple tasks, invoices, time and receipts - very good for Maintenance projects.",
          stepsInstruction: "In a few steps you'll learn:",
          steps: [
            "Add a job",
            "Setup basic information"
          ],
          estimate: "1 min"
        });
      default:
        return ({
          number: '000',
          title: "",
          subtitle: "",
          stepsInstruction: "In a few steps you'll learn:",
          steps: []
        });
    }
  }
}

export default Factory;
