import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import clientsReducer from './clients';
import clientTemplatesReducer from './clientTemplates';
import invoicesReducer from './invoices';
import projectsReducer from './projects';
import projectTimesReducer from './projectTimes';
import taxRulesReducer from './taxRules';
import adminReducer from './admin';
import bussinessReducer from './business';
import receiptsReducer from './receipts';
import bidsReducer from './bids';
import bidTasksReducer from './bidTasks';
import bidEditorReducer from './bidEditor';
import filtersReducer from './filters';
import previewReducer from './preview';
import vendorsReducer from './vendors';
import taxCategoriesReducer from './taxCategories';
import crewReducer from './crew';
import timecardsReducer from './timecards';
import projectTasksReducer from './projectTasks';
import notesReducer from './notes';
import filesReducer from './files';
import serviceIconsReducer from './serviceIcons';
import heroImagesReducer from './heroImages';
import contactImagesReducer from './contactImages';
import workTypesReducer from './workTypes';
import formDataReducer from './formData';
import companyInfoReducer from './companyInfo';
import websiteReducer from './website';
import validationReducer from './validation';
import accountReducer from './account';
import helpReducer from './help';
import orgDomainsReducer from './orgDomains';
import subscription from './subscription';
import dialogs from './dialogs';
import logos from './logos';
import notifications from './notifications';
import reminders from './reminders';
import taxTypes from './taxTypes'

const rootReducer = combineReducers({
  dialogs,
  logos,
  help: helpReducer,
  validation: validationReducer,
  sessionState: sessionReducer,
  userState: userReducer,
  accountState: accountReducer,
  clientsState: clientsReducer,
  clientTemplatesState: clientTemplatesReducer,
  invoicesState: invoicesReducer,
  projectsState: projectsReducer,
  projectTimesState: projectTimesReducer,
  taxRulesState: taxRulesReducer,
  adminState: adminReducer,
  businessState: bussinessReducer,
  receiptsState: receiptsReducer,
  bidsState: bidsReducer,
  bidTasksState: bidTasksReducer,
  bidEditorState: bidEditorReducer,
  filters: filtersReducer,
  previewState: previewReducer,
  vendorsState: vendorsReducer,
  taxCategoriesState: taxCategoriesReducer,
  crewState: crewReducer,
  timecardsState: timecardsReducer,
  projectTasksState: projectTasksReducer,
  notesState: notesReducer,
  filesState: filesReducer,
  serviceIconsState: serviceIconsReducer,
  heroImagesState: heroImagesReducer,
  contactImagesState: contactImagesReducer,
  workTypesState: workTypesReducer,
  formData: formDataReducer,
  companyInfoState: companyInfoReducer,
  websiteState: websiteReducer,
  orgDomains: orgDomainsReducer,
  subscription,
  notifications,
  reminders,
  taxTypes,
});

export default rootReducer;
